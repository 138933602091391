import { useState } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
/** Services */
import { createProvider } from 'services/providers.js';
/** Constants */
import { NEW_PROVIDER_DATA, CREATE_PROVIDER_FORM } from 'constants/providers';
/** Styles */
import styles from './NewProviderForm.module.scss';

const NewProviderForm = ({ closeModalHandler, reloadProviders }) => {
  const [formData, setFormData] = useState(NEW_PROVIDER_DATA);
  const [submitting, setSubmitting] = useState(false);

  const changeHandler = ({ target: { name, value, id } }) => {
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setSubmitting(true);

    createProvider(formData)
      .then(() => {
        reloadProviders();
        closeModalHandler();
        createToast({
          text: 'Se ha creado el proveedor',
          type: 'success',
          duration: 2500,
        });
      })
      .catch(({ status }) => {
        setSubmitting(false);
        closeModalHandler();

        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({
          text: 'Algo ha ido mal, intentalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <div className={styles.FormContainer}>
      <form
        id="new-provider-form"
        className={styles.FormWrapper}
        onSubmit={submitHandler}
      >
        <div className={styles.FormField}>
          {CREATE_PROVIDER_FORM.map(({ id, label, placeholder, type }) => (
            <Input
              key={id}
              id={id}
              label={label}
              name={id}
              placeholder={placeholder}
              type={type}
              onChange={changeHandler}
            />
          ))}
        </div>
        <div className={styles.FormButtonWrapper}>
          <Button
            cancel
            text="Cancelar"
            type="button"
            click={closeModalHandler}
          />
          <Button submit loading={submitting} text="Crear" />
        </div>
      </form>
    </div>
  );
};

export default NewProviderForm;
