export const PLANNING_HEAD_CELLS = [
  {
    id: 'client',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'term',
    numeric: false,
    disablePadding: false,
    label: 'Plazo de retiro',
    isDate: true,
  },
  {
    id: 'clientContract',
    numeric: false,
    disablePadding: false,
    label: 'Contrato cliente',
  },
  {
    id: 'supplier',
    numeric: false,
    disablePadding: false,
    label: 'Proveedor',
  },
  {
    id: 'supplierContract',
    numeric: false,
    disablePadding: false,
    label: 'Contrato proveedor',
  },
  {
    id: 'qualityCode',
    numeric: false,
    disablePadding: false,
    label: 'Código Calidad',
  },
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Nombre Calidad',
  },
  {
    id: 'currentStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock actual',
  },
  {
    id: 'sellPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de venta',
    suffix: ' €',
  },
  {
    id: 'buyPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de compra',
    suffix: ' €',
  },
  {
    id: 'utilityPerKg',
    numeric: false,
    disablePadding: false,
    label: 'Utilidad por kg',
    suffix: ' €/Kg',
  },
  {
    id: 'packCapacity',
    numeric: false,
    disablePadding: false,
    label: 'Envase',
    suffix: ' Kg',
  },
  {
    id: 'initialStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock inicial',
    suffix: ' uds.',
  },
  {
    id: 'transferedStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock cedido',
  },
  {
    id: 'orderedStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock solicitado',
  },
  {
    id: 'boughtStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock comprado',
  },
  {
    id: 'soldStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock vendido',
  },
  {
    id: 'utilityPerTotalKg',
    numeric: false,
    disablePadding: false,
    label: 'Utilidad por kg totales',
    suffix: ' €',
  },
];
