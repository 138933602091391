import { useState, useEffect } from 'react';
/** Components */
import Table from 'components/Table';
import Loader from 'components/Loader';
/** Services */
import { getAllBillInvoices } from 'services/clients';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { INVOICES_HEAD_CELLS } from 'constants/clients';
/** Styles */
import './Abonos.scss';

const Abonos = ({ client }) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllBillInvoices()
      .then(({ data }) => {
        const filteredInvoices = data.filter(
          ({ clientId }) => clientId === client
        );
        setInvoices(filteredInvoices);
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen facturas de abonos de clientes',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  const mappedBillInvoices = invoices.map(
    ({
      invoiceNumber = '',
      date,
      supplierName,
      basePrice,
      totalPrice,
      vat,
      id,
    }) => {
      return {
        ...(invoiceNumber
          ? { invoiceNumber }
          : { invoiceNumber: 'Sin nº de factura' }),
        ...(date ? { date } : { date: 'Sin fecha' }),
        // ...(supplierName
        //   ? { supplierId: supplierName }
        //   : { supplierId: 'Sin proveedor' }),
        ...(basePrice ? { basePrice } : { basePrice: 'Sin precio base' }),
        ...(totalPrice ? { totalPrice } : { totalPrice: 'Sin precio total' }),
        ...(vat ? { vat } : { vat: 'Sin IVA' }),
        metadata: { fileId: id, key: 'clientBillInvoice' },
        ...(id && { id }),
      };
    }
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      {invoices.length !== 0 ? (
        <Table
          rowsData={mappedBillInvoices}
          headCells={INVOICES_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
        />
      ) : (
        <div className="EmptyState">No hay facturas de abonos.</div>
      )}
    </>
  );
};

export default Abonos;
