import axiosWrapper, { baseUrl } from './core';

const SUPPLIERS_URL = '/api/suppliers-contracts';
const CLIENTS_URL = '/api/clients-contracts';

export const getSuppliersContracts = () =>
  axiosWrapper.get(`${baseUrl}${SUPPLIERS_URL}/get-all-supplier-contracts`);

export const getSupplierContracts = (supplierId) =>
  axiosWrapper.get(
    `${baseUrl}${SUPPLIERS_URL}/get-all-supplier-contracts/${supplierId}`
  );

export const getSupplierContract = (contractId) =>
  axiosWrapper.get(
    `${baseUrl}${SUPPLIERS_URL}/get-supplier-contract-by-id/${contractId}`
  );

export const getSupplierContractsList = (supplierId, query = '') =>
  axiosWrapper.get(
    `${baseUrl}${SUPPLIERS_URL}/supplier-contract-list/${supplierId}${query}`
  );

export const createSupplierContract = (params) =>
  axiosWrapper.put(
    `${baseUrl}${SUPPLIERS_URL}/create-supplier-contract`,
    params
  );

export const updateSupplierContract = (params) =>
  axiosWrapper.patch(
    `${baseUrl}${SUPPLIERS_URL}/update-supplier-contract`,
    params
  );

export const removeSupplierContracts = (id) =>
  axiosWrapper.delete(`${baseUrl}${SUPPLIERS_URL}/delete-supplier-contract`, {
    id,
  });

export const getSupplierContractDefaultFooter = () =>
  axiosWrapper.get(
    `${baseUrl}${SUPPLIERS_URL}/get-supplier-contract-default-footer`
  );

export const getClientsContracts = () =>
  axiosWrapper.get(`${baseUrl}${CLIENTS_URL}/get-all-client-contracts`);

export const getClientContracts = (clientId) =>
  axiosWrapper.get(
    `${baseUrl}${CLIENTS_URL}/get-all-client-contracts/${clientId}`
  );

export const getClientContract = (contractId) =>
  axiosWrapper.get(
    `${baseUrl}${CLIENTS_URL}/get-client-contract-by-id/${contractId}`
  );

export const getClientContractsByClientId = (clientId, query = '') =>
  axiosWrapper.get(
    `${baseUrl}${CLIENTS_URL}/get-client-contracts-by-client-id/${clientId}${query}`
  );

export const v2GetClientContractById = (contractId, query = '') =>
  axiosWrapper.get(
    `${baseUrl}${CLIENTS_URL}/2/get-by-id/${contractId}${query}`
  );

export const createClientContract = (params) =>
  axiosWrapper.put(`${baseUrl}${CLIENTS_URL}/create-client-contract`, params);

export const updateClientContract = (params) =>
  axiosWrapper.patch(`${baseUrl}${CLIENTS_URL}/update-client-contract`, params);

export const removeClientContracts = (id) =>
  axiosWrapper.delete(`${baseUrl}${CLIENTS_URL}/delete-client-contract`, {
    id,
  });

export const getClientContractDefaultFooter = () =>
  axiosWrapper.get(
    `${baseUrl}${CLIENTS_URL}/get-client-contract-default-footer`
  );

export const createGeneralContract = (params) =>
  axiosWrapper.put(`${baseUrl}${SUPPLIERS_URL}/create-contracts`, params);

export const updateGeneralContract = (params) =>
  axiosWrapper.patch(`${baseUrl}${SUPPLIERS_URL}/update-contracts`, params);

export const getGeneralContract = (contractId) =>
  axiosWrapper.get(
    `${baseUrl}${SUPPLIERS_URL}/contracts-by-supplier-contract-id/${contractId}`
  );

export const getAllAssignments = () =>
  axiosWrapper.get(`${baseUrl}${CLIENTS_URL}/get-all-assignments`);

export const assignStock = (params) =>
  axiosWrapper.put(`${baseUrl}${CLIENTS_URL}/assign-stock`, params);

export const getClientAssignmentContracts = (contractId) =>
  axiosWrapper.get(
    `${baseUrl}${CLIENTS_URL}/get-assignment-by-client/${contractId}`
  );

export const createReplacement = (params) =>
  axiosWrapper.put(`${baseUrl}${SUPPLIERS_URL}/new-replacement`, params);

export const getReplacement = (replacementId) =>
  axiosWrapper.get(
    `${baseUrl}${CLIENTS_URL}/get-reposition-by-id/${replacementId}`
  );
