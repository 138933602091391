import { useState } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
/** Services */
import { createOrigin } from 'services/qualities.js';
/** Constants */
import { NEW_ORIGIN_DATA } from 'constants/qualities';
/** Styles */
import styles from './NewOriginForm.module.scss';

const NewOriginForm = ({ closeModalHandler, reloadOrigins }) => {
  const [formData, setFormData] = useState(NEW_ORIGIN_DATA);
  const [submitting, setSubmitting] = useState(false);

  const changeHandler = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setSubmitting(true);

    createOrigin(formData)
      .then(() => {
        createToast({
          text: 'Se ha creado el origen',
          type: 'success',
          duration: 2500,
        });
        closeModalHandler();
        reloadOrigins();
      })
      .catch(({ status }) => {
        setSubmitting(false);

        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <div className={styles.FormContainer}>
      <form
        id="new-client-form"
        className={styles.FormWrapper}
        onSubmit={submitHandler}
      >
        <div className={styles.FormGroup}>
          <Input
            id="name"
            label="Nombre del origen"
            name="name"
            placeholder="Sin nombre"
            type="text"
            autoComplete="off"
            className={styles.InputName}
            onChange={changeHandler}
          />
        </div>
        <div className={styles.FormButtonWrapper}>
          <Button
            cancel
            text="Cancelar"
            type="button"
            click={closeModalHandler}
          />
          <Button
            submit
            loading={submitting}
            text="Añadir"
            disabled={!formData.name}
          />
        </div>
      </form>
    </div>
  );
};

export default NewOriginForm;
