import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Services */
import { getAllUsers, deleteUser } from 'services/users';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { USER_HEAD_CELLS } from 'constants/users';
/** Styles */
import styles from './UserList.module.scss';

const UserList = ({ users = [], reloadUsers }) => {
  const navigate = useNavigate();

  const mappedUsers = users.map(
    ({ email = '', name = '', surnames = '', role = '', id }, index) => {
      return {
        ...(name ? { name } : { name: 'Sin nombre' }),
        ...(surnames ? { surnames } : { surnames: 'Sin apellidos' }),
        ...(email ? { email } : { email: 'Sin correo electrónico' }),
        ...(role ? { role } : { role: 'Sin rol' }),
        ...(id && { id }),
      };
    }
  );

  const handleSelectUser = (userId) => {
    navigate(`/usuarios/${userId}`);
  };

  const deleteHandler = (userIds) => {
    deleteUser(userIds)
      .then(({ status }) => {
        if (status === 204) {
          createToast({
            text: 'Usuarios eliminados con éxito',
            type: 'success',
            duration: 2500,
          });
          getAllUsers().then(({ status, data: { fetchedUsers } }) => {
            if (status === 200) {
              reloadUsers(fetchedUsers);
            }
          });
        }
      })
      .catch(() => {
        createToast({
          text: 'No se ha podido eliminar el usuario, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {users.length !== 0 ? (
        <Table
          rowsData={mappedUsers}
          headCells={USER_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectUser}
          deleteHandler={(value) => deleteHandler(value)}
        />
      ) : (
        <div className={styles.EmptyState}>No hay datos.</div>
      )}
    </>
  );
};

export default UserList;
