import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
import Loader from 'components/Loader';
/** Services */
import { getSuppliersInvoices } from 'services/invoices';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { INVOICES_HEAD_CELLS } from 'constants/providers';
/** Styles */
import './Invoices.scss';

const Invoices = ({ provider }) => {
  const navigate = useNavigate();
  const [invoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSuppliersInvoices()
      .then(({ status, data }) => {
        if (status === 200) {
          const filteredInvoices = data.filter(
            ({ supplierId }) => supplierId === provider.id
          );
          setInvoiceList(filteredInvoices);
        }
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen facturas de proveedores',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  const mappedInvoices = invoiceList.map(
    ({
      invoiceNumber = '',
      date,
      supplierId,
      basePrice,
      totalPrice,
      vat,
      id,
    }) => {
      return {
        ...(invoiceNumber
          ? { invoiceNumber }
          : { invoiceNumber: 'Sin nº de factura' }),
        ...(date ? { date } : { date: 'Sin fecha' }),
        ...(supplierId
          ? { supplierId: provider.name }
          : { supplierId: 'Sin proveedor' }),
        ...(basePrice ? { basePrice } : { basePrice: 'Sin precio base' }),
        ...(totalPrice ? { totalPrice } : { totalPrice: 'Sin precio total' }),
        ...(vat ? { vat } : { vat: 'Sin IVA' }),
        ...(id && { id }),
      };
    }
  );

  const handleSelectInvoice = (invoiceId) => {
    navigate(`/proveedores/facturas/${invoiceId}`);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {invoiceList.length !== 0 ? (
        <Table
          rowsData={mappedInvoices}
          headCells={INVOICES_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectInvoice}
        />
      ) : (
        <div className="EmptyState">No hay facturas.</div>
      )}
    </>
  );
};

export default Invoices;
