import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
/** Components */
import Loader from 'components/Loader';
import Back from 'components/Back';
import Button from 'components/Button';
import Input from 'components/Input';
/** Services */
import { getAllOrigins, updateOrigin, deleteOrigin } from 'services/qualities';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Styles */
import styles from './Origin.module.scss';

const Origin = () => {
  const [origins, setOrigins] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [safeDelete, setSafeDelete] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchOrigins = async () => {
      try {
        const { data } = (await getAllOrigins()) ?? {};
        const { fetchedOrigins } = data;
        fetchedOrigins && setOrigins(fetchedOrigins);
        setOrigin(() => {
          return fetchedOrigins.find((origin) => {
            return +origin.id === +id;
          });
        });
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchOrigins();
  }, [id]);

  const changeHandler = ({ target: { name, value, type } }) => {
    setOrigin({
      ...origin,
      [name]: value,
    });
  };

  const removeHandler = () => {
    if (safeDelete) {
      deleteOrigin(origin.id)
        .then(() => {
          createToast({
            text: 'Calidad eliminada con éxito',
            type: 'success',
            duration: 2500,
          });
          navigate('/calidades');
        })
        .catch(() => {
          createToast({
            text: 'No se ha podido eliminar la calidad, inténtalo de nuevo más tarde',
            type: 'error',
          });
        });
      return;
    }

    setSafeDelete(true);
    setTimeout(() => {
      setSafeDelete(false);
    }, 3000);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setSubmitting(true);

    updateOrigin(origin)
      .then(() => {
        createToast({
          text: 'Origen modificado',
          type: 'success',
          duration: 2500,
        });
        navigate('/origenes');
      })
      .catch(() => {
        setSubmitting(false);
        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  if (loading) return <Loader />;

  return (
    <>
      <Back text="Volver a Orígenes" path="/origenes" />
      <div className={styles.OriginsContainer}>
        {origin ? (
          <form onSubmit={submitHandler} className={styles.FormWrapper}>
            <div className={styles.FormGroup}>
              <Input
                id="name"
                label="Nombre del origen"
                name="name"
                placeholder="Sin nombre"
                type="text"
                autoComplete="off"
                value={origin.name}
                className={styles.InputName}
                onChange={changeHandler}
              />
            </div>
            <div className={styles.FormButtonWrapper}>
              <Button
                update
                loading={submitting}
                text="Actualizar"
                disabled={!origin.name}
              />
              <Button
                remove
                text={safeDelete ? 'Confirmar' : 'Eliminar'}
                type="button"
                click={removeHandler}
              />
            </div>
          </form>
        ) : (
          <div className={styles.OriginEmpty}>
            No se ha encontrado el origen.
          </div>
        )}
      </div>
    </>
  );
};

export default Origin;
