/**
 * Formats date as dd/mm/yyyy
 * @param {String} date
 * @returns {String} date with format dd/mm/yyyy
 */
export const getFormattedDate = (date) => {
  if (!date) return '-';
  const d = new Date(date);
  if (isNaN(d.getTime())) return date;
  const day = `${d.getDate()}`;
  const month = `${d.getMonth() + 1}`;
  const year = d.getFullYear();

  return [day, month, year].join('/');
};
