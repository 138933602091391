/**
 * WRAPPER FILE FOR AXIOS LIBRARY
 */

import axios from 'axios';

export const setToken = (token = '') => {
  if (!token) {
    axios.defaults.headers.common.Authorization = '';
    axios.defaults.headers.common['X-Requested-With'] = '';
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
};

export const getToken = () => axios.defaults.headers.common.Authorization;

export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_ENDPOINT
    : process.env.REACT_APP_PRODUCTION_ENDPOINT;

// Reference to axios's spread method to handle the responses from the 'all' method
export const handleSpread = axios.spread;

const axiosWrapper = {
  get: axios.get,
  post: axios.post,
  patch: axios.patch,
  put: axios.put,
  delete: axios.delete,
  all: axios.all,
};

export default axiosWrapper;
