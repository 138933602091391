import { useState } from 'react';
/** Utils */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFormattedDate } from 'utils/date';
/** Components */
import LineItemForm from '../LineItemForm';
/** Styles */
import './LineItem.scss';

const LineItem = ({
  index,
  id,
  buyPrice,
  supplierTerm,
  boughtUnits = 0,
  clients = [],
  onUpdate,
  onRemove,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const lineItemData = {
    id,
    buyPrice,
    supplierTerm,
    boughtUnits,
    clients,
  };

  const togglerEdit = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  const updateHandler = (lineItem) => {
    togglerEdit();
    onUpdate(lineItem);
  };

  return (
    <>
      {isEditing ? (
        <LineItemForm
          lineItemData={lineItemData}
          cancelEdit={togglerEdit}
          onSubmit={updateHandler}
        />
      ) : (
        <div className="line-item">
          <div className="line-item__wrapper">
            <h3 className="line-item__quality">Datos generales</h3>
            <ul className="line-item__metadata">
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">Unidades:</span>{' '}
                {boughtUnits}
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">
                  Fecha de retirada:
                </span>{' '}
                {getFormattedDate(supplierTerm)}
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">
                  Precio de compra:
                </span>{' '}
                {buyPrice}€
              </li>
            </ul>
            {!!clients.length && (
              <div className="line-item__clients">
                {clients.map(
                  (
                    { clientId, clientName, soldUnits, sellPrice, clientTerm },
                    clientIndex
                  ) => (
                    <div
                      key={`client_view_${id}_${clientId}_${index}_${clientIndex}`}
                      className="line-item__client"
                    >
                      <h3 className="line-item__quality">{clientName}</h3>
                      <ul className="line-item__metadata">
                        <li className="line-item__metadata-line">
                          <span className="line-item__metadata-title">
                            Unidades:
                          </span>{' '}
                          {soldUnits}
                        </li>
                        <li className="line-item__metadata-line">
                          <span className="line-item__metadata-title">
                            Fecha de retirada:
                          </span>{' '}
                          {getFormattedDate(clientTerm)}
                        </li>
                        <li className="line-item__metadata-line">
                          <span className="line-item__metadata-title">
                            Precio de venta:
                          </span>{' '}
                          {sellPrice}€
                        </li>
                      </ul>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <div className="line-item__actions">
            <FontAwesomeIcon
              icon="pencil-alt"
              className="line-item__actions-icon"
              onClick={togglerEdit}
            />
            <FontAwesomeIcon
              icon="trash"
              className="line-item__actions-icon"
              onClick={onRemove}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LineItem;
