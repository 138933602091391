import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Constants */
import { ORDERS_HEAD_CELLS } from 'constants/orders';
/** Styles */
import './OrdersList.scss';

const OrdersList = ({ orders = [] }) => {
  const navigate = useNavigate();

  const handleSelectContract = (orderId) => {
    navigate(`/pedidos/${orderId}`);
  };

  const mappedOrders = orders.map(
    ({
      clientName = '',
      date = '',
      paymentTypeName = '',
      paymentTypeId = 0,
      status,
      orderId: id,
    }) => {
      return {
        orderId: id,
        ...(clientName ? { clientName } : { clientName: 'Sin cliente' }),
        ...(date ? { date } : { date: 'Sin fecha' }),
        ...(paymentTypeName
          ? { paymentTypeName }
          : { paymentTypeName: 'Sin método de pago' }),
        metadata: { fileId: id, key: 'order' },
        status,
        id,
      };
    }
  );

  return (
    <>
      {orders.length !== 0 ? (
        <Table
          rowsData={mappedOrders}
          headCells={ORDERS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectContract}
          orderBy="id"
        />
      ) : (
        <div className="orders-list--empty">No hay datos.</div>
      )}
    </>
  );
};

export default OrdersList;
