/** Components */
import ResetPasswordForm from './ResetPasswordForm';
/** Styles */
import styles from 'styles/pages/StartPage.module.scss';

const ResetPassword = () => {
  return (
    <>
      <div className={styles.delicoffeStart}>
        <main className={styles.main}>
          <div className={styles.wrapper}>
            <ResetPasswordForm />
          </div>
        </main>
      </div>
    </>
  );
};

export default ResetPassword;
