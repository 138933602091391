import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Constants */
import { TRANSFERS_HEAD_CELLS } from 'constants/transfers';
/** Styles */
import './ReplacementList.scss';

const ReplacementList = ({ replacements = [] }) => {
  const navigate = useNavigate();

  const mappedTransfers = replacements.map(
    ({
      originContract = '',
      originClient = '',
      targetContract = '',
      targetClient = '',
      quality = '',
      transferStock = 0,
      buyPrice = 0,
      originSellPrice = 0,
      targetSellPrice = 0,
      id,
    }) => {
      return {
        ...(originContract
          ? { originContract }
          : { originContract: 'Sin contrato origen' }),
        ...(originClient
          ? { originClient }
          : { originClient: 'Sin cliente origen' }),
        ...(targetContract
          ? { targetContract }
          : { targetContract: 'Sin contrato destino' }),
        ...(targetClient
          ? { targetClient }
          : { targetClient: 'Sin cliente destino' }),
        ...(quality ? { quality } : { quality: 'Sin calidad' }),
        ...(transferStock
          ? { transferStock }
          : { transferStock: 'Sin stock cedido' }),
        ...(buyPrice ? { buyPrice } : { buyPrice: 'Sin precio de compra' }),
        ...(originSellPrice
          ? { originSellPrice }
          : { originSellPrice: 'Sin precio de venta origen' }),
        ...(targetSellPrice
          ? { targetSellPrice }
          : { targetSellPrice: 'Sin precio de venta destino' }),
        id,
      };
    }
  );

  const handleSelectReplacement = (replacementId) => {
    navigate(`/proveedores/reposicion/${replacementId}`);
  };

  return (
    <>
      {replacements.length !== 0 ? (
        <Table
          rowsData={mappedTransfers}
          headCells={TRANSFERS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectReplacement}
        />
      ) : (
        <div className="transfers-list--empty">No hay datos.</div>
      )}
    </>
  );
};

export default ReplacementList;
