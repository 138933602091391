// Regex to validate a string as a valid email
export const emailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Given an email, it validates it
 * @param {email} String
 * @returns boolean
 */
export const validateEmail = (email) =>
  emailValidator.test(email.toString().toLowerCase());
