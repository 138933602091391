import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Services */
import { deleteProvider, getAllProviders } from 'services/providers.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Constants */
import { PROVIDERS_HEAD_CELLS } from 'constants/providers.js';
/** Styles */
import styles from './ProvidersList.module.scss';

const ProvidersList = ({ providers = [], reloadProviders }) => {
  const navigate = useNavigate();

  const mappedProviders = providers.map(
    ({
      name = '',
      NIF = '',
      country = '',
      city = '',
      id,
      SupplierContacts = [],
    }) => {
      const contact = SupplierContacts.length ? SupplierContacts[0] : null;
      return {
        providerId: id,
        ...(name ? { name } : { name: 'Sin nombre' }),
        ...(NIF ? { NIF } : { NIF: 'Sin NIF' }),
        ...(country ? { country } : { country: 'Sin País' }),
        ...(city ? { city } : { city: 'Sin ciudad' }),
        ...(contact
          ? { mainContactName: contact.mainContactName || 'Sin nombre' }
          : { mainContactName: 'Sin contacto principal' }),
        ...(contact?.mainContactPhone
          ? { mainContactPhone: contact.mainContactPhone }
          : { mainContactPhone: 'Sin teléfono principal' }),
        ...(id && { id }),
      };
    }
  );

  const handleSelectProvider = (providerId) => {
    navigate(`/proveedores/${providerId}`);
  };

  const deleteHandler = (providersIds) => {
    deleteProvider(providersIds)
      .then(({ status }) => {
        if (status === 204) {
          createToast({
            text: 'Proveedor eliminado con éxito',
            type: 'success',
            duration: 2500,
          });
          getAllProviders().then(({ status, data: { fetchedSuppliers } }) => {
            if (status === 200) {
              reloadProviders(fetchedSuppliers);
            }
          });
        }
      })
      .catch(() => {
        createToast({
          text: 'No se ha podido eliminar el proveedor, intentalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {providers.length !== 0 ? (
        <Table
          rowsData={mappedProviders}
          headCells={PROVIDERS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectProvider}
          deleteHandler={(value) => deleteHandler(value)}
        />
      ) : (
        <div className={styles.EmptyState}>No hay datos.</div>
      )}
    </>
  );
};

export default ProvidersList;
