import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Input from 'components/Input';
import Switch from 'components/Switch';
import Textarea from 'components/Textarea';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Button from 'components/Button';
import Back from 'components/Back';
import LineItems from '../LineItems';
import LineClients from '../LineClients';
/** Services */
import { getAllProviders } from 'services/providers';
import { getAllQualities, getAllOrigins } from 'services/qualities';
import {
  createGeneralContract,
  getSupplierContractDefaultFooter,
} from 'services/contracts';
/** Context */
import { LineClientsContext } from 'context/useLineClients';
/** Styles */
import './NewProviderContract.scss';

const NewProviderContract = () => {
  const [providersList, setProvidersList] = useState([]);
  const [qualities, setQualities] = useState([]);
  const [varieties, setVarieties] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [lineClients, setLineClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorDetails, setErrorDetails] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: { contractDate: new Date(), palletized: false },
  });

  const supplier = watch('supplierId');
  const variety = watch('VarietyId');
  const origin = watch('OriginId');
  const isContractPalletized = watch('palletized');

  const filteredQualities = useMemo(
    () =>
      qualities.filter(
        ({ SupplierId, QualityVarietyId, OriginId }) =>
          SupplierId === supplier &&
          QualityVarietyId === variety &&
          OriginId === origin
      ),
    [qualities, supplier, variety, origin]
  );

  const inputDateClassNames = cx('new-contract__basic-date', {
    'new-contract__basic-date--error': !!errors.contractDate,
  });

  useEffect(() => {
    getAllProviders()
      .then(({ status, data: { fetchedSuppliers } }) => {
        if (status === 200) {
          setProvidersList(fetchedSuppliers);
        }
      })
      .catch(({ status }) => {
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({ text: 'No exiten usuarios', type: 'error' });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });

    getAllQualities()
      .then(({ status, data: { parsedVarieties = [] } = {} }) => {
        if (status === 200) {
          const normalizedQualities = parsedVarieties
            .map(({ qualities = [] }) => qualities)
            .flat();
          const normalizedVarieties = parsedVarieties.map(({ id, name }) => ({
            id,
            name,
          }));
          setQualities(normalizedQualities);
          setVarieties(normalizedVarieties);
        }
      })
      .catch(({ status }) => {
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({ text: 'No existen las calidades', type: 'error' });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });

    getAllOrigins()
      .then(({ status, data: { fetchedOrigins } }) => {
        if (status === 200) {
          setOrigins(fetchedOrigins);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    getSupplierContractDefaultFooter()
      .then(({ status, data: { fetchedSupplierContractDefaultFooter } }) => {
        if (status === 200) {
          reset({
            contractDate: new Date(),
            contractFooter: fetchedSupplierContractDefaultFooter?.content,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Handler to create a contract
  const createContract = ({ OriginId, VarietyId, ...data }) => {
    setSubmitting(true);

    const payload = {
      ...data,
      clients: lineClients.map(
        ({
          clientId,
          palletized,
          transportIsIncluded,
          paymentTypeId,
          warehouseAddress,
        }) => {
          if (!clientId || !warehouseAddress) {
            throw Error();
          }
          return {
            clientId,
            palletized: palletized,
            transportIsIncluded,
            paymentTypeId,
            warehouseAddress,
          };
        }
      ),
      contractDetails: lineItems.map(({ clients, ...lineItem }) => ({
        ...lineItem,
        clients: clients.map(({ clientName, ...client }) => ({ ...client })),
      })),
    };

    createGeneralContract(payload)
      .then(() => {
        createToast({
          text: 'Contratos creados con éxito',
          type: 'success',
        });
        navigate('/proveedores/contratos');
      })
      .catch((error) => {
        setSubmitting(false);
        createToast({
          text: error.message || 'Ha habido un error',
          type: 'error',
        });
      });
  };

  const resetLineItems = () => {
    setLineItems([]);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    if (!lineItems?.length) {
      setErrorDetails(true);
      return;
    }

    if (errors && Object.keys(errors).length) return;

    createContract(data);
  };

  const onChangeSupplier = () => {
    reset({
      ...getValues(),
      VarietyId: '',
      OriginId: '',
      qualityId: '',
    });
  };

  const onChangeOrigin = () => {
    reset({
      ...getValues(),
      qualityId: '',
    });
  };

  const onChangeVariety = () => {
    reset({
      ...getValues(),
      OriginId: '',
      qualityId: '',
    });
  };

  return loading ? (
    <Loader />
  ) : (
    <LineClientsContext.Provider value={{ lineClients, setLineClients }}>
      <>
        <Back
          text="Volver a Contratos de proveedores"
          path="/proveedores/contratos/"
        />

        <form className="new-contract" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="new-contract__header">Nuevo contrato</h2>
          <div className="new-contract__basic-data">
            <div className="new-contract__basic-data-input">
              <Controller
                control={control}
                name="contractNumber"
                rules={{ required: 'El campo es obligatorio' }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    id="contractNumber"
                    label="Número de contrato"
                    name="contractNumber"
                    placeholder="ES902022"
                    type="text"
                    autoComplete="off"
                    className="InputName"
                    error={errors.contractNumber}
                    defaultValue={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className={inputDateClassNames}>
              <label>Selecciona una fecha:</label>
              <Controller
                control={control}
                name="contractDate"
                rules={{ required: 'El campo es obligatorio' }}
                render={({ field: { value, onChange } }) => (
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={esLocale}
                  >
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      value={value}
                      onChange={onChange}
                      error={errors.contractDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                )}
              />
              {errors?.contractDate && (
                <span className="new-contract__error-message">
                  {errors.contractDate.message}
                </span>
              )}
            </div>
            <div className="new-contract__basic-data-input">
              <label className="NewContractFormRightProviderSelectTitle">
                Selecciona un proveedor
              </label>
              <Controller
                control={control}
                name="supplierId"
                rules={{ required: 'El campo es obligatorio' }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={providersList}
                    placeholder="Proveedor"
                    value={value}
                    error={errors.supplierId}
                    onChange={onChange}
                    onExtraChange={() => {
                      onChangeSupplier();
                      resetLineItems();
                    }}
                  />
                )}
              />
            </div>
          </div>
          <h3 className="new-contract__subheader">Selección de la calidad</h3>
          <div className="new-contract__basic-data">
            <div className="new-contract__basic-data-input">
              <div className="">
                <label className="LineItemNewLineFormLabel">
                  Filtra por variedad
                </label>
                <Controller
                  control={control}
                  name="VarietyId"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      options={varieties}
                      placeholder="Sin variedad"
                      value={value}
                      disabled={!supplier}
                      onChange={onChange}
                      onExtraChange={onChangeVariety}
                    />
                  )}
                />
              </div>
            </div>
            <div className="new-contract__basic-data-input">
              <div className="">
                <label className="LineItemNewLineFormLabel">
                  Filtra por origen
                </label>
                <Controller
                  control={control}
                  name="OriginId"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      options={origins}
                      placeholder="Sin origen"
                      value={value}
                      disabled={!variety}
                      onChange={onChange}
                      onExtraChange={onChangeOrigin}
                    />
                  )}
                />
              </div>
            </div>
            <div className="new-contract__basic-data-input">
              <div className="">
                <label className="LineItemNewLineFormLabel">
                  Selecciona una calidad
                </label>
                <Controller
                  control={control}
                  name="qualityId"
                  rules={{ required: 'El campo es obligatorio' }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      options={filteredQualities}
                      placeholder="Sin calidad"
                      value={value}
                      disabled={!origin}
                      error={errors.qualityId}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="new-contract__switches">
            <Controller
              control={control}
              name="palletized"
              render={({ field: { value, onChange } }) => (
                <Switch id="palletized" value={value} onChange={onChange}>
                  Paletizado
                </Switch>
              )}
            />
          </div>
          <div className="new-contract__clients">
            <h3 className="new-contract__title">Clientes:</h3>
            <LineClients
              error={errorDetails}
              isContractPalletized={isContractPalletized}
            />
          </div>
          <div className="new-contract__new-line">
            <h3 className="new-contract__title">Líneas de contrato:</h3>
            <LineItems
              lineItems={lineItems}
              qualities={filteredQualities}
              setLineItems={setLineItems}
              error={errorDetails}
            />
          </div>

          <div className="new-contract__bottom-fields">
            <Controller
              control={control}
              name="contractFooter"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Textarea
                  id="contractFooter"
                  label="Pie de página"
                  name="contractFooter"
                  placeholder="Escribe un pie de página para el contrato"
                  type="text"
                  rows="10"
                  error={errors.contractFooter}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-contract__footer">
            <Button
              type="submit"
              update
              loading={submitting}
              text="Crear contrato"
              disabled={!lineItems?.length}
            />
          </div>
        </form>
      </>
    </LineClientsContext.Provider>
  );
};

export default NewProviderContract;
