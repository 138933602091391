import { useState, useEffect } from 'react';
/** Utils */
import { createToast, dismissToast } from 'utils/toast';
/** Components */
import PaymentsList from './PaymentsList';
import Loader from 'components/Loader';
/** Services */
import { getAllPayments } from 'services/payments';
/** Styles */
import styles from './Payments.module.scss';

const Payments = () => {
  const [paymentsList, setPaymentsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dismissToast();

    getAllPayments()
      .then(({ status, data: { fetchedPaymentOptions } }) => {
        if (status === 200) {
          setPaymentsList(fetchedPaymentOptions);
        }

        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);

        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({ text: 'No exiten métodos de pago', type: 'error' });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Payments}>
      <div className={styles.PaymentsHeader}>
        <h1 className="title">Métodos de pago</h1>
      </div>
      <>
        <div className={styles.PaymentList}>
          <PaymentsList payments={paymentsList} />
        </div>
      </>
    </div>
  );
};
export default Payments;
