import { forwardRef } from 'react';
import cx from 'classnames';
/** Styles */
import styles from './Input.module.scss';

const Input = ({ name, label, className, error, ...props }, ref) => {
  const inputContainerClassnames = cx(styles.InputContainer, {
    ...(className && { [className]: !!className }),
  });
  const inputElementClassnames = cx(styles.InputElement, {
    [styles.InputElementError]: !!error,
  });

  return (
    <div className={inputContainerClassnames}>
      {label && (
        <label htmlFor={name} className={styles.InputLabel}>
          {label}
        </label>
      )}
      <input
        {...props}
        id={name}
        name={name}
        ref={ref}
        className={inputElementClassnames}
      />
      {error && error.message && (
        <span className={styles.InputErrorMessage}>{error.message}</span>
      )}
    </div>
  );
};

export default forwardRef(Input);
