import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import BillInvoiceList from './BillInvoiceList';
/** Services */
import { getSuppliersBillInvoices } from 'services/invoices';
import { getAllProviders } from 'services/providers';
/** Utils */
import { createToast } from 'utils/toast';
/** Styles */
import styles from './BillInvoices.module.scss';

const BillInvoices = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllProviders()
      .then(({ data: { fetchedSuppliers } }) => {
        setProviders(fetchedSuppliers);
      })
      .catch(({ status, response }) => {
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: response.data.feedback, type: 'error' });
      });

    getSuppliersBillInvoices()
      .then(({ data }) => {
        setInvoiceList(data);
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen facturas de abonos de los proveedores',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  const handleDeleteInvoices = (ids) => {
    setInvoiceList(invoiceList.filter((invoice) => !ids.includes(invoice.id)));
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Invoices}>
      <div className={styles.InvoicesHeader}>
        <h1 className="title">Facturas de abonos de proveedores</h1>
        <NavLink to="/proveedores/abonos/nuevo">
          <Button update text="Crear nueva" />
        </NavLink>
      </div>
      <div className={styles.InvoicesList}>
        <BillInvoiceList
          invoices={invoiceList}
          providers={providers}
          onDeleteInvoices={handleDeleteInvoices}
        />
      </div>
    </div>
  );
};

export default BillInvoices;
