import cx from 'classnames';
/** Context */
import useAuth from 'context/useAuth';
/** Routes */
import Routes from 'routes';
/** Components */
import SideBar from 'components/SideBar';
import Header from 'components/Header';
/** Utils */
import { registerIcons } from 'utils/fontAwesome';
/** Styles */
import '@fortawesome/fontawesome-svg-core/styles.css';

registerIcons();

function App() {
  const { user } = useAuth();
  // const isLoggedIn = true;
  const dashboardContainerClasses = cx({
    DashboardContainer: user,
    LoginContainer: !user,
  });

  return (
    <div className="App">
      <div className={dashboardContainerClasses}>
        {user && <SideBar />}
        <div className="MainContainer">
          {user && <Header />}
          <main className="MainContent">
            <Routes />
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
