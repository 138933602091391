import { useState, useEffect } from 'react';
/** Utils */
import { createToast, dismissToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import NewClientForm from './NewClientForm';
import ClientsList from './ClientsList';
/** Services */
import { getAllClients } from 'services/clients.js';
/** Styles */
import styles from './Clients.module.scss';

const Clients = () => {
  const [clientsList, setClientsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    dismissToast();
    setShouldFetch(false);

    getAllClients()
      .then(({ status, data: { fetchedClients } }) => {
        if (status === 200) {
          setClientsList(fetchedClients);
        }
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({ text: 'No existen clientes', type: 'error' });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, [shouldFetch]);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Clients}>
      <div className={styles.ClientsHeader}>
        <h1 className="title">Clientes</h1>
        <Button text="Crear nuevo" update click={() => setShowModal(true)} />
      </div>
      <Modal
        show={showModal}
        title="Añadir nuevo cliente"
        id="new-client"
        successButton="Crear"
        onClose={() => setShowModal(false)}
        showFooter={false}
      >
        <NewClientForm
          closeModalHandler={() => setShowModal(false)}
          reloadClients={() => setShouldFetch(true)}
        />
      </Modal>
      <div className={styles.ClientsList}>
        <ClientsList
          clients={clientsList}
          reloadClients={() => setShouldFetch(true)}
        />
      </div>
    </div>
  );
};

export default Clients;
