import { useState, useEffect } from 'react';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import OriginsList from './OriginsList';
import NewOriginForm from 'pages/Origins/NewOriginForm';
/** Services */
import { getAllOrigins } from 'services/qualities';
/** Styles */
import styles from './Origins.module.scss';

const Origins = () => {
  const [origins, setOrigins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    setLoading(true);
    setShouldFetch(false);
    const fetchOrigins = async () => {
      try {
        const { data } = (await getAllOrigins()) ?? {};
        const { fetchedOrigins } = data;
        fetchedOrigins && setOrigins(fetchedOrigins);
      } catch (err) {
        console.error(err);
      }
    };
    fetchOrigins();
    setLoading(false);
  }, [shouldFetch]);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Origins}>
      <div className={styles.OriginsHeader}>
        <h1 className="title">Orígenes</h1>
        <Button text="Crear nuevo" update click={() => setShowModal(true)} />
      </div>
      <>
        <Modal
          show={showModal}
          title={'Añadir nuevo origen'}
          id={'new-origin'}
          successButton={'Crear'}
          onClose={() => setShowModal(false)}
          showFooter={false}
        >
          <NewOriginForm
            closeModalHandler={() => setShowModal(false)}
            reloadOrigins={() => setShouldFetch(true)}
          />
        </Modal>
        <div className={styles.OriginsList}>
          <OriginsList
            origins={origins}
            reloadOrigins={(origins) => setOrigins(origins)}
          />
        </div>
      </>
    </div>
  );
};

export default Origins;
