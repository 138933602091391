import { useState, useEffect } from 'react';
/** Utils */
import { createToast, dismissToast } from 'utils/toast';
/** Components */
import NewUserForm from './NewUserForm';
import UserList from './UserList';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
/** Services */
import { getAllUsers } from 'services/users';
/** Styles */
import styles from './Users.module.scss';

const Users = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    dismissToast();
    setShouldFetch(false);

    getAllUsers()
      .then(({ status, data: { fetchedUsers } }) => {
        if (status === 200) {
          setUserList(fetchedUsers);
        }
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({ text: 'No exiten usuarios', type: 'error' });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, [shouldFetch]);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Users}>
      <div className={styles.UserHeader}>
        <h1 className="title">Usuarios</h1>
        <Button text="Crear nuevo" update click={() => setShowModal(true)} />
      </div>
      <>
        <Modal
          show={showModal}
          title={'Añadir nuevo usuario'}
          id={'new-user'}
          successButton={'Crear'}
          onClose={() => setShowModal(false)}
          showFooter={false}
        >
          <NewUserForm
            closeModalHandler={() => setShowModal(false)}
            reloadUsers={() => setShouldFetch(true)}
          />
        </Modal>
        <div className={styles.UserList}>
          <UserList users={userList} reloadUsers={() => setShouldFetch(true)} />
        </div>
      </>
    </div>
  );
};

export default Users;
