export const NEW_QUALITY_DATA = {
  name: '',
  quantity: 0,
  type: {
    organic: false,
    microLots: false,
    specials: false,
    decaffeinated: false,
    decSwissWater: false,
  },
  QualityVarietyId: '',
  tareWeight: 0,
  OriginId: '',
  SupplierId: '',
};

export const QUALITIES_HEAD_CELLS = [
  {
    id: 'qualityId',
    numeric: false,
    disablePadding: false,
    label: 'ID.',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'QualityVarietyId',
    numeric: false,
    disablePadding: false,
    label: 'Variedad',
  },
  {
    id: 'types',
    numeric: false,
    disablePadding: false,
    label: 'Grupo',
  },
  {
    id: 'OriginId',
    numeric: false,
    disablePadding: false,
    label: 'Origen',
  },
  {
    id: 'SupplierId',
    numeric: false,
    disablePadding: false,
    label: 'Proveedor',
  },
];

export const MODIFIY_QUALITY_FORM_DATA = {
  id: '',
  ...NEW_QUALITY_DATA,
};

export const QUALITY_TYPES = [
  {
    id: 'organic',
    label: 'Orgánico',
  },
  {
    id: 'microLots',
    label: 'Microlotes',
  },
  {
    id: 'specials',
    label: 'Especiales',
  },
  {
    id: 'decaffeinated',
    label: 'Descafeinado',
  },
  {
    id: 'decSwissWater',
    label: 'Desc. Swiss Water',
  },
  {
    id: 'commertial',
    label: 'Comerciales',
  },
  {
    id: 'certified',
    label: 'Certificados',
  },
];

export const QUALITY_PACKS = [
  {
    id: 'coat',
    name: 'Saco',
  },
  {
    id: 'bag',
    name: 'Bolsa',
  },
  {
    id: 'barrel',
    name: 'Barril',
  },
];

export const ORIGINS_HEAD_CELLS = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Origen',
  },
];

export const NEW_ORIGIN_DATA = {
  name: '',
};
