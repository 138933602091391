import axiosWrapper, { baseUrl } from './core';

const BASE_URL = '/api/clients';
const CLIENT_INVOICES_URL = `${BASE_URL}-invoices`;

export const createClient = (params) =>
  axiosWrapper.put(`${baseUrl}${BASE_URL}/create-client`, params);

export const createContactData = (params) =>
  axiosWrapper.put(`${baseUrl}${BASE_URL}/create-client-contact-data`, params);

export const getAllClients = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-all-clients`);

export const getClient = (id) =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-client-by-id/${id}`);

export const updateClient = (params) =>
  axiosWrapper.patch(`${baseUrl}${BASE_URL}/update-client`, params);

export const updateContactData = (params) =>
  axiosWrapper.patch(
    `${baseUrl}${BASE_URL}/update-client-contact-data`,
    params
  );

export const deleteClient = (params) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete-client`, {
    data: { id: params },
  });

export const deleteContactData = (params) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete-client-contact-data`, {
    data: { id: params },
  });

export const getAllAddresses = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-all-clients-send-addresses`);

export const createAddressData = (params) =>
  axiosWrapper.put(`${baseUrl}${BASE_URL}/create-client-send-address`, params);

export const updateAddressData = (params) =>
  axiosWrapper.patch(
    `${baseUrl}${BASE_URL}/update-client-send-address`,
    params
  );

export const deleteAddress = (params) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete-client-send-address`, {
    data: { id: params },
  });

export const getAllAssignments = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-all-assignments`);

export const getAllInvoices = () =>
  axiosWrapper.get(`${baseUrl}${CLIENT_INVOICES_URL}/get-all-invoices`);

export const getAllProformas = () =>
  axiosWrapper.get(`${baseUrl}${CLIENT_INVOICES_URL}/get-all-proformas`);

export const getAllBillInvoices = () =>
  axiosWrapper.get(`${baseUrl}${CLIENT_INVOICES_URL}/get-all-bill-invoices`);
