import { useState } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import Switch from 'components/Switch';
import Loader from 'components/Loader';
/** Services */
import { createClient } from 'services/clients.js';
/** Constants */
import { NEW_CLIENT_DATA, CREATE_CLIENT_FORM } from 'constants/clients';
/** Styles */
import styles from './NewClientForm.module.scss';

const NewClientForm = ({ closeModalHandler, reloadClients }) => {
  const [formData, setFormData] = useState(NEW_CLIENT_DATA);
  const [isLoaded, setIsLoaded] = useState(true);

  const changeHandler = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: typeof value === 'string' ? value.trim() : value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoaded(false);

    createClient(formData)
      .then(({ status }) => {
        closeModalHandler();
        reloadClients();
        setIsLoaded(true);

        if (status === 201) {
          setFormData(NEW_CLIENT_DATA);
          createToast({
            text: 'Se ha creado el cliente',
            type: 'success',
            duration: 2500,
          });
        }
      })
      .catch(({ status }) => {
        closeModalHandler();

        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({
          text: 'Algo ha ido mal, intentalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <div className={styles.FormContainer}>
      <form
        id="new-client-form"
        className={styles.FormWrapper}
        onSubmit={submitHandler}
      >
        <div className={styles.FormField}>
          {CREATE_CLIENT_FORM.map(({ id, label, placeholder, type }) => (
            <Input
              key={id}
              id={id}
              label={label}
              name={id}
              placeholder={placeholder}
              type={type}
              onChange={changeHandler}
            />
          ))}
          <div className={styles.FormSwitch}>
            <Switch
              id="VAT"
              onChange={(value) =>
                changeHandler({ target: { name: 'VAT', value } })
              }
            >
              IVA comunitario
            </Switch>
          </div>
        </div>
        <div className={styles.FormButtonWrapper}>
          <Button
            cancel
            text="Cancelar"
            type="button"
            click={closeModalHandler}
          />
          <Button submit loading={!isLoaded} text="Crear" />
        </div>
      </form>
    </div>
  );
};

export default NewClientForm;
