import PersonalData from './PersonalData';
import ContactData from './ContactData';
import AddressData from './AddressData';

const Datos = ({ client, onSubmit }) => (
  <>
    <PersonalData client={client} />
    <ContactData
      clientId={client.id}
      contacts={client.ClientContacts}
      onSubmit={onSubmit}
    />
    <AddressData
      clientId={client.id}
      addresses={client.ClientSendAddresses}
      onSubmit={onSubmit}
    />{' '}
  </>
);

export default Datos;
