import { Routes, Route, Navigate } from 'react-router-dom';
/** Context */
import useAuth from 'context/useAuth';
/** Config */
import { routes, protectedRoutes } from './config';

const AppRoutes = () => {
  const { user } = useAuth();
  return (
    <Routes>
      {/* Routes */}
      {routes.map((route) => {
        const { id, path, RouteComponent } = route;
        return <Route key={id} path={path} element={<RouteComponent />} />;
      })}
      {/* Protected routes */}
      {protectedRoutes.map((route) => {
        const { id, path, RouteComponent } = route;
        return (
          <Route
            key={id}
            path={path}
            element={user ? <RouteComponent /> : <Navigate replace to="/" />}
          />
        );
      })}
    </Routes>
  );
};

export default AppRoutes;
