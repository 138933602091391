import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Services */
import { deleteClient, getAllClients } from 'services/clients.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Constants */
import { CLIENTS_HEAD_CELLS } from 'constants/clients.js';
/** Styles */
import styles from './ClientsList.module.scss';

const ClientsList = ({ clients = [], reloadClients }) => {
  const navigate = useNavigate();

  const mappedClients = clients.map(
    ({
      name = '',
      NIF = '',
      country = '',
      city = '',
      id,
      ClientContacts = [],
    }) => {
      const contact = ClientContacts.length ? ClientContacts[0] : null;
      return {
        clientId: id,
        ...(name ? { name } : { name: 'Sin nombre' }),
        ...(NIF ? { NIF } : { NIF: 'Sin NIF' }),
        ...(country ? { country } : { country: 'Sin País' }),
        ...(city ? { city } : { city: 'Sin ciudad' }),
        ...(contact
          ? { mainContactName: contact.mainContactName || 'Sin nombre' }
          : { mainContactName: 'Sin contacto principal' }),
        ...(contact?.mainContactPhone
          ? { mainContactPhone: contact.mainContactPhone }
          : { mainContactPhone: 'Sin teléfono principal' }),
        ...(id && { id }),
      };
    }
  );

  const handleSelectClient = (clientId) => {
    navigate(`/clientes/${clientId}`);
  };

  const deleteHandler = (clientsIds) => {
    deleteClient(clientsIds)
      .then(({ status }) => {
        if (status === 204) {
          createToast({
            text: 'Cliente eliminado con éxito',
            type: 'success',
            duration: 2500,
          });
          getAllClients().then(({ status, data: { fetchedClients } }) => {
            if (status === 200) {
              reloadClients(fetchedClients);
            }
          });
        }
      })
      .catch(() => {
        createToast({
          text: 'No se ha podido eliminar el cliente, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {clients.length !== 0 ? (
        <Table
          rowsData={mappedClients}
          headCells={CLIENTS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectClient}
          deleteHandler={(value) => deleteHandler(value)}
        />
      ) : (
        <div className={styles.EmptyState}>No hay datos.</div>
      )}
    </>
  );
};

export default ClientsList;
