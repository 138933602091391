import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
/** Components */
import Button from 'components/Button';
import LineClient from '../LineClient';
import LineClientForm from '../LineClientForm';
/** Context */
import { useLineClients } from 'context/useLineClients';
/** Styles */
import './LineClients.scss';

const LineClients = ({ error, isContractPalletized }) => {
  const [addingLineClients, setAddingLineClients] = useState(false);
  const { lineClients, setLineClients } = useLineClients();

  // Handler to save line item
  const saveLineClientHandler = (lineClient) => {
    setLineClients([...lineClients, lineClient]);
    setAddingLineClients();
  };

  // Handler to update line item
  const updateLineClientHandler = (lineClient) => {
    const index = lineClients.findIndex(({ id }) => id === lineClient.id);
    if (index !== -1) {
      lineClients[index] = { ...lineClient };
    }
    setLineClients([...lineClients]);
  };

  // Handler to remove line item
  const removeLineClientHandler = (index) => {
    setLineClients([
      ...lineClients.slice(0, index),
      ...lineClients.slice(index + 1),
    ]);
  };

  const lineClientsClassNames = cx('line-items__empty', {
    'line-items__empty--error': !!error,
  });

  return (
    <div className="line-items">
      {lineClients.length ? (
        <div className="line-items__items">
          {lineClients.map((lineClient, index) => (
            <LineClient
              key={`line-client-item-${index}`}
              addingLineClients={addingLineClients}
              onUpdate={updateLineClientHandler}
              onRemove={() => removeLineClientHandler(index)}
              {...lineClient}
            />
          ))}
        </div>
      ) : (
        !addingLineClients && (
          <>
            <div className={lineClientsClassNames}>
              <Button
                update
                text="Añadir cliente"
                click={() => setAddingLineClients(true)}
              >
                <FontAwesomeIcon icon="plus" className="line-items__add-btn" />
              </Button>
            </div>
            {error && (
              <span className="line-items__error-message">
                Necesitas añadir al menos un cliente
              </span>
            )}
          </>
        )
      )}
      {addingLineClients && (
        <LineClientForm
          isContractPalletized={isContractPalletized}
          onSubmit={saveLineClientHandler}
          cancelEdit={() => setAddingLineClients(false)}
        />
      )}
      {!!lineClients.length && !addingLineClients && (
        <div className="line-items__action">
          <Button
            update
            text="Añadir más clientes"
            click={() => setAddingLineClients(true)}
          >
            <FontAwesomeIcon icon="plus" className="line-items__add-btn" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default LineClients;
