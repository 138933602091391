import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Back from 'components/Back';
import Select from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Table from 'components/Table';
/** Services */
import { createSupplierBillInvoice } from 'services/invoices';
import { getAllProviders } from 'services/providers';
import { getSuppliersContracts } from 'services/contracts';
import { getSupplierOrders, getOrder } from 'services/orders';
/** Constants */
import {
  BILL_INVOICES_LINE_ITEMS_HEAD_CELLS,
  IVA_OPTIONS,
} from 'constants/providers';
/** Styles */
import './NewBillInvoiceForm.scss';

const NewBillInvoiceForm = () => {
  const [providersList, setProvidersList] = useState([]);
  const [supplierContractList, setSupplierContractList] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({ defaultValues: { vat: 10, date: new Date() } });

  const supplierId = watch('supplierId');
  const supplierContractId = watch('supplierContractId');
  const orderId = watch('orderId');

  const mappedLineItems = useMemo(
    () =>
      lineItems.map(
        ({
          id,
          quality = {},
          units = 0,
          packCapacity = 0,
          price = 0,
          expectedWeight = 0,
          batch,
        }) => {
          return {
            ...(quality
              ? { qualityName: quality.name }
              : { qualityName: 'Sin calidad' }),
            ...(units ? { units } : { units: 0 }),
            ...(packCapacity ? { packCapacity } : { packCapacity: 0 }),
            ...(price ? { price } : { price: 0 }),
            ...(expectedWeight
              ? { realWeight: expectedWeight }
              : { realWeight: 0 }),
            ...(batch ? { batch } : { batch: undefined }),
            ...(id && { id }),
          };
        }
      ),
    [lineItems]
  );

  useEffect(() => {
    getAllProviders()
      .then(({ status, data: { fetchedSuppliers } }) => {
        if (status === 200) {
          setProvidersList(fetchedSuppliers);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!supplierId) return;

    getSuppliersContracts()
      .then(({ status, data: { suppliersContracts } }) => {
        if (status === 200) {
          const mappedContracts =
            suppliersContracts.filter(
              (contract) => contract?.SupplierId === supplierId
            ) ?? [];
          setSupplierContractList(mappedContracts);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    getSupplierOrders(supplierId)
      .then(({ data }) => {
        const orders = data.map(({ orderId: id, clientName, ...order }) => ({
          ...order,
          id,
          clientName: `${id} - ${clientName}`,
        }));
        setOrders(orders);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [supplierId]);

  useEffect(() => {
    if (!supplierId) return;
    if (!orderId) return;

    const fetchOrder = async () => {
      try {
        setFetching(true);
        setLineItems([]);
        const { data } = await getOrder(orderId);
        const { details } = data;
        const parsedDetails = details.map(
          ({
            qualityId,
            qualityName,
            initialQuantity: units,
            packCapacity,
            sellPrice: price,
            detailId: orderDetailId,
          }) => {
            return {
              qualityId,
              qualityName,
              quality: { name: qualityName },
              units,
              packCapacity,
              price,
              orderDetailId,
              expectedWeight: units * packCapacity,
              realWeight: units * packCapacity,
            };
          }
        );
        setFetching(false);
        setLineItems(parsedDetails);
      } catch (err) {
        setFetching(false);
        console.error(err);
      }
    };
    return fetchOrder();
  }, [orderId]);

  // Handler to create an invoice
  const createInvoice = ({
    supplierContractNumber,
    basePrice,
    totalPrice,
    description,
    price,
    aditionalConceptVat,
    ...data
  }) => {
    setSubmitting(true);

    // const additionalConcepts = {
    //   description,
    //   price: parseFloat(price),
    //   vat: aditionalConceptVat,
    // };

    const normalizedLineItems = lineItems.map(
      ({
        qualityId,
        batch,
        units,
        packCapacity,
        price: pricePerKg,
        orderDetailId,
        expectedWeight,
        realWeight,
      }) => ({
        ...(qualityId && { qualityId }),
        batches: batch,
        units,
        packCapacity,
        pricePerKg: pricePerKg ? parseFloat(pricePerKg) : 0,
        orderDetailId,
        vat: data.vat,
        expectedWeight,
        realWeight,
      })
    );

    const payload = {
      ...data,
      supplierContractId,
      supplierContractNumber: supplierContractId, // Esto es porque en el payload de facturas la key es supplierContractId
      details: [...normalizedLineItems],
    };

    createSupplierBillInvoice(payload)
      .then(() => {
        createToast({
          text: 'Factura de proveedor registrada con éxito',
          type: 'success',
        });
        navigate('/proveedores/abonos');
      })
      .catch(({ status }) => {
        setSubmitting(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  };

  // Handler to update line item
  const onUpdate = (itemId, { name, value }) => {
    const targetItem = lineItems.find(({ id }) => id === itemId);

    let parsedValue = value;
    if (name === 'batch' && value?.length === 1 && !value?.[0]?.quantity) {
      parsedValue = [
        {
          batch: value?.[0]?.batch,
          quantity: targetItem.units,
        },
      ];
    }

    if (name === 'batch' && !targetItem.units) {
      createToast({
        text: 'Guarda una cantidad antes de configurar sus lotes',
        type: 'error',
      });
      return;
    }

    const index = lineItems.findIndex(({ id }) => id === itemId);
    const target = lineItems[index];
    if (name === 'units') {
      delete target?.batch;
    }
    const item = {
      ...target,
      [name]: parsedValue,
    };

    setLineItems([
      ...lineItems.slice(0, index),
      item,
      ...lineItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    createInvoice(data);
  };

  // Classes to make error for dates
  const inputDateClassNames = cx('new-bill-invoice__basic-date', {
    'new-bill-invoice__basic-date--error': !!errors.date,
  });

  console.log(111, lineItems);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back
        text="Volver a las facturas de abonos de proveedor"
        path="/proveedores/abonos"
      />

      <form className="new-bill-invoice" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="new-bill-invoice__header">
          Registrar factura de abonos de proveedor
        </h2>
        <div className="new-bill-invoice__basic-data-custom">
          <div className="new-bill-invoice__basic-data-input">
            <label>Proveedor:</label>
            <Controller
              control={control}
              name="supplierId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={providersList}
                  placeholder="Proveedor"
                  value={value}
                  error={errors.supplierId}
                  onChange={onChange}
                />
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          <div className="new-bill-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Contrato del proveedor:
            </label>
            <Controller
              control={control}
              name="supplierContractId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={supplierContractList}
                  placeholder="Proveedor"
                  textProperty="contractNumber"
                  value={value}
                  error={errors.supplierContractId}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-bill-invoice__basic-data-input">
            <Controller
              control={control}
              name="invoiceNumber"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  key="invoiceNumber"
                  name="invoiceNumber"
                  placeholder="Nº Factura"
                  label="Nº Factura"
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="new-bill-invoice__basic-data-custom">
          <div className={inputDateClassNames}>
            <label>Selecciona una fecha:</label>
            <Controller
              control={control}
              name="date"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.date}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          <div className="new-bill-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              IVA:
            </label>
            <Controller
              control={control}
              name="vat"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={IVA_OPTIONS}
                  placeholder="IVA"
                  value={value}
                  canSearch={false}
                  error={errors.vat}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-bill-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Pedidos:
            </label>
            <Controller
              control={control}
              name="orderId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={orders}
                  placeholder="Pedido"
                  textProperty="clientName"
                  value={value}
                  error={errors.orderId}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        {!!lineItems.length && (
          <>
            <Table
              rowsData={mappedLineItems}
              headCells={BILL_INVOICES_LINE_ITEMS_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              stickyColumns={['batches']}
              onCellValueChange={onUpdate}
            />
            {/* <div className="new-bill-invoice__basic-data new-bill-invoice__order-lines-extra">
              <div className="new-bill-invoice__basic-data-input">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="description"
                      placeholder="Descripción"
                      label="Conceptos adicionales:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="new-bill-invoice__basic-data-input">
                <Controller
                  control={control}
                  name="price"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="price"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="Precio"
                      label="Precio:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="new-bill-invoice__basic-data-input">
                <label className="NewContractFormRightProviderSelectTitle">
                  IVA:
                </label>
                <Controller
                  control={control}
                  name="aditionalConceptVat"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      options={IVA_OPTIONS}
                      placeholder="IVA"
                      value={value}
                      canSearch={false}
                      error={errors.vat}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div> */}
          </>
        )}
        <div className="new-bill-invoice__footer">
          <Button
            type="submit"
            update
            disabled={
              fetching || lineItems.some((item) => !item?.batch?.[0]?.batch)
            }
            loading={submitting}
            text="Registrar factura de abonos"
          />
        </div>
      </form>
    </>
  );
};

export default NewBillInvoiceForm;
