import { useState } from 'react';
import { Navigate } from 'react-router';
/** Context */
import useAuth from 'context/useAuth';
/** Components */
import SignInForm from './SignInForm';
import Button from 'components/Button';
/** Styles */
import styles from 'styles/pages/StartPage.module.scss';

const SignIn = () => {
  const [startLogin, setStartLogin] = useState(false);
  const { user } = useAuth();

  return user ? (
    <Navigate replace to="/dashboard" />
  ) : (
    <div className={styles.delicoffeStart}>
      <main className={styles.main}>
        <div className={styles.wrapper}>
          {startLogin ? (
            <SignInForm />
          ) : (
            <div className={styles.delicoffeStartWrapper}>
              <img
                src={`${process.env.PUBLIC_URL}/img/logos/delicoffe-logo.png`}
                alt="logo-start"
              />
              <Button text="Inicio" click={() => setStartLogin(true)} />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default SignIn;
