import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Constants */
import {
  LINE_ITEM_DATA_FORM,
  LINE_ITEM_CLIENT_DATA_FORM,
} from 'constants/providers';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
/** Context */
import { useLineClients } from 'context/useLineClients';
/** Styles */
import './LineItemForm.scss';

const LineItemForm = ({ lineItemData = {}, cancelEdit, onSubmit }) => {
  const [clients, setClients] = useState([]);
  const [unitsError, setUnitsError] = useState(false);
  const { lineClients } = useLineClients();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: { supplierTerm: new Date() },
  });

  const supplierTerm = watch('supplierTerm');
  const boughtUnits = watch('boughtUnits');

  useEffect(() => {
    const updatedClients = clients.map((client) => ({
      ...client,
      clientTerm: supplierTerm,
    }));
    setClients(updatedClients);
  }, [supplierTerm]);

  const inputDateClassNames = cx('LineItemNewLineFormDate', {
    LineItemNewLineFormDateError: !!errors.supplierTerm,
  });

  useEffect(() => {
    if (!!Object.entries(lineItemData).length) {
      reset(lineItemData);
      setClients(lineItemData.clients ?? []);
    }
  }, [lineItemData]);

  useEffect(() => {
    if (lineItemData.clients?.length) {
      setClients(lineItemData.clients);
      return;
    }
    setClients(
      lineClients.map(({ clientId, clientName }) => ({
        clientId,
        clientName,
        soldUnits: 0,
        sellPrice: 0,
        clientTerm: new Date(),
      }))
    );
  }, [lineClients]);

  const onSubmitHandler = (data) => {
    if (errors && Object.keys(errors).length) return;

    const totalUnits = clients.reduce(
      (acc, { soldUnits }) => acc + Math.floor(soldUnits),
      0
    );

    if (totalUnits !== Math.floor(boughtUnits)) {
      setUnitsError(true);
      return;
    }

    onSubmit({ ...data, clients });
  };

  const handleClientFieldChange = (clientId, fieldId, value) => {
    const index = clients.findIndex(({ clientId: id }) => id === clientId);
    if (index === -1) return;
    const newClients = [...clients];
    newClients[index] = { ...newClients[index], [fieldId]: value };
    setClients(newClients);

    if (fieldId === 'soldUnits') {
      setUnitsError(false);
    }
  };

  return (
    <div className="LineItemNewLine">
      <p className="LineItemNewLineTitle">Detalles de línea generales:</p>
      <div className="LineItemNewLineForm">
        {LINE_ITEM_DATA_FORM.map(
          ({ id, label, type, placeholder, errorMessage }) =>
            type === 'date' ? (
              <div className={inputDateClassNames} key={id}>
                <label>{label}</label>
                <Controller
                  control={control}
                  name={id}
                  rules={{ required: 'El campo es obligatorio' }}
                  render={({ field: { value, onChange } }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={esLocale}
                    >
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        error={errors[id]}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
                {errors[id] && (
                  <span className="LineItemNewLineFormDateErrorMessage">
                    {errors[id].message}
                  </span>
                )}
              </div>
            ) : (
              <Controller
                key={id}
                control={control}
                name={id}
                rules={{
                  required: errorMessage,
                  validate: (value) => value > 0 || errorMessage,
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    id={id}
                    label={label}
                    name={id}
                    placeholder={placeholder}
                    type={type}
                    autoComplete="off"
                    className="InputName"
                    error={errors[id]}
                    defaultValue={value}
                    onChange={onChange}
                    step={
                      type === 'number'
                        ? id === 'boughtUnits' || id === 'soldUnits'
                          ? '1'
                          : '0.01'
                        : null
                    }
                  />
                )}
              />
            )
        )}
      </div>
      {!!clients.length && (
        <>
          <p className="LineItemNewLineTitle">Detalles de los clientes:</p>
          {clients.map(({ clientId, clientName, ...client }) => (
            <div className="LineItemNewLineForm" key={clientId}>
              <div>
                <Input
                  id={clientId}
                  label="Cliente"
                  name={clientId}
                  type="text"
                  autoComplete="off"
                  className="InputName"
                  value={clientName}
                  disabled={true}
                />
              </div>
              {LINE_ITEM_CLIENT_DATA_FORM.map(
                ({ id, label, type, placeholder }, index) =>
                  type === 'date' ? (
                    <div
                      className="LineItemNewLineFormDate"
                      key={`${clientId}_${id}_${index}`}
                    >
                      <label>{label}</label>
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={esLocale}
                      >
                        <DatePicker
                          value={client[id]}
                          onChange={(value) =>
                            handleClientFieldChange(clientId, id, value)
                          }
                          inputFormat="dd/MM/yyyy"
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  ) : (
                    <Input
                      key={`${clientId}_${id}_${index}`}
                      id={id}
                      label={label}
                      name={id}
                      placeholder={placeholder}
                      type={type}
                      autoComplete="off"
                      className="InputName"
                      value={client[id]}
                      onChange={({ target: { value } }) =>
                        handleClientFieldChange(clientId, id, value)
                      }
                      step={
                        type === 'number'
                          ? id === 'boughtUnits' || id === 'soldUnits'
                            ? '1'
                            : '0.01'
                          : null
                      }
                    />
                  )
              )}
            </div>
          ))}
        </>
      )}
      <div className="LineItemFormFooter">
        {unitsError ? (
          <span className="LineItemFormFooterError">
            Las unidades de los detalles de línea debe coincidir con la suma de
            las unidades de todos los clientes
          </span>
        ) : (
          <span />
        )}
        <div className="LineItemFormAction">
          <Button cancel text="Cancelar" click={cancelEdit} />
          <Button update text="Guardar" click={handleSubmit(onSubmitHandler)} />
        </div>
      </div>
    </div>
  );
};

export default LineItemForm;
