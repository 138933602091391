import { useEffect, useState, useMemo } from 'react';
/** Components */
import Table from 'components/Table';
import Loader from 'components/Loader';
/** Services */
import { getPlanning } from 'services/planning';
/** Constants */
import { PLANNING_HEAD_CELLS } from 'constants/planning';
/** Styles */
import './Planning.scss';

const Planning = () => {
  const [planning, setPlanning] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPlanning()
      .then(({ status, data }) => {
        if (status === 200) {
          setPlanning(data);
          console.log(111, data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, []);

  const mappedPlanning = useMemo(() => {
    const parsedPlanning = planning.map((data, index) => {
      data.id = index;
      data.utilityPerKg =
        data?.utilityPerKg?.toFixed(2)?.replace('.', ',') || 'error de formato';
      data.utilityPerTotalKg =
        data?.utilityPerTotalKg?.toFixed(2)?.replace('.', ',') ||
        'error de formato';
      return data;
    });

    return parsedPlanning.map(
      ({
        id,
        client,
        term,
        clientContract,
        supplier,
        supplierContract,
        qualityCode,
        qualityName,
        currentStock,
        sellPrice,
        buyPrice,
        utilityPerKg,
        packCapacity,
        initialStock,
        utilityPerTotalKg,
        transferedStock,
        orderedStock,
        boughtStock,
        soldStock,
      }) => {
        return {
          ...(client ? { client } : { client: 'Sin cliente' }),
          ...(term ? { term } : { term: 'Sin plazo de retiro' }),
          ...(clientContract
            ? { clientContract }
            : { clientContract: 'Sin contrato de cliente' }),
          ...(supplier ? { supplier } : { supplier: 'Sin proveedor' }),
          ...(supplierContract
            ? { supplierContract }
            : { supplierContract: 'Sin contrato de proveedor' }),
          ...(qualityCode ? { qualityCode } : { qualityCode: 'Sin código' }),
          ...(qualityName ? { qualityName } : { qualityName: 'Sin calidad' }),
          ...(currentStock
            ? { currentStock }
            : { currentStock: 'Sin stock actual' }),
          ...(sellPrice ? { sellPrice } : { sellPrice: '-' }),
          ...(buyPrice ? { buyPrice } : { buyPrice: '-' }),
          ...(utilityPerKg ? { utilityPerKg } : { utilityPerKg: '-' }),
          ...(packCapacity ? { packCapacity } : { packCapacity: '-' }),
          ...(initialStock
            ? { initialStock }
            : { initialStock: 'Sin stock inicial' }),
          ...(transferedStock
            ? { transferedStock }
            : { transferedStock: 'Sin stock cedido' }),
          ...(orderedStock
            ? { orderedStock }
            : { orderedStock: 'Sin stock solicitado' }),
          ...(boughtStock
            ? { boughtStock }
            : { boughtStock: 'Sin stock comprado' }),
          ...(soldStock ? { soldStock } : { soldStock: 'Sin stock vendido' }),
          ...(utilityPerTotalKg
            ? { utilityPerTotalKg }
            : { utilityPerTotalKg: '-' }),
          id,
        };
      }
    );
  }, [planning]);

  return loading ? (
    <Loader />
  ) : (
    <div className="planning">
      <Table
        rowsData={mappedPlanning}
        headCells={PLANNING_HEAD_CELLS}
        ignoreColumn="id"
        targetItem="id"
        queryField="client"
        showDelete={false}
        showFilters={true}
        defaultFiltersEnabled={true}
        stickyHeader
      />
    </div>
  );
};

export default Planning;
