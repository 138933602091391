import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
/** Hooks */
import usePortal from 'hooks/usePortal';
/** Styles */
import styles from './Modal.module.scss';

const Modal = ({
  id,
  show = false,
  showFooter = true,
  title = 'Sin título',
  successButton,
  isNarrow = false,
  loading = false,
  children,
  onClose,
  onSubmit,
}) => {
  const target = usePortal('modal-root');

  const wrapperClasses = cx(styles.modalWrapper, {
    [styles.modalNarrow]: isNarrow,
  });

  return show
    ? createPortal(
        <div
          className={styles.modalBackdrop}
          tabIndex="0"
          id={id}
          onClick={onClose}
        >
          <div className={wrapperClasses} onClick={(e) => e.stopPropagation()}>
            <div className={styles.modalContent}>
              <header className={styles.modalHeader}>
                <h2>{title}</h2>
                <FontAwesomeIcon
                  icon="times"
                  className={styles.modalClose}
                  onClick={onClose}
                />
              </header>

              <section className={styles.modalBody}>{children}</section>

              {showFooter && (
                <footer className={styles.modalFooter}>
                  <button
                    type="button"
                    className={styles.modalButton}
                    aria-label="Cancel"
                    onClick={onClose}
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className={`${styles.modalButton} ${styles.modalButtonSubmit}`}
                    aria-label="Submit"
                    disabled={loading}
                    onClick={onSubmit}
                  >
                    {successButton}
                  </button>
                </footer>
              )}
            </div>
          </div>
        </div>,
        target
      )
    : null;
};
export default Modal;
