/** Pages */
import SignIn from 'pages/SignIn';
import Planning from 'pages/Planning';
import Users from 'pages/Users';
import User from 'pages/User';
import Clients from 'pages/Clients';
import Client from 'pages/Client';
import ClientContracts from 'pages/Clients/Contracts';
import ClientContract from 'pages/Clients/Contract';
import Transfers from 'pages/Transfers';
import NewTransfer from 'pages/Transfers/NewTransfer';
import Transfer from 'pages/Transfer';
import Replacements from 'pages/Replacements';
import NewReplacement from 'pages/Replacements/NewReplacement';
import NewClientContract from 'pages/Clients/Contracts/NewClientContract';
import ClientsInvoices from 'pages/Clients/Invoices/';
import ClientsBillInvoices from 'pages/Clients/BillInvoices/';
import Proformas from 'pages/Clients/Proformas/';
import ResetPassword from 'pages/ResetPassword';
import Register from 'pages/Register';
import Qualities from 'pages/Qualities';
import Quality from 'pages/Quality';
import Origins from 'pages/Origins';
import Origin from 'pages/Origin';
import Providers from 'pages/Providers';
import Provider from 'pages/Provider';
import ProviderContracts from 'pages/Providers/Contracts';
import ProviderContract from 'pages/Providers/Contract';
import NewProviderContract from 'pages/Providers/Contracts/NewProviderContract';
import ProviderInvoices from 'pages/Providers/Invoices';
import ProviderBillInvoices from 'pages/Providers/BillInvoices';
import ProviderBillInvoice from 'pages/Providers/BillInvoice';
import NewBillInvoiceForm from 'pages/Providers/BillInvoices/NewBillInvoiceForm';
import ProviderInvoice from 'pages/Providers/Invoice';
import NewInvoiceForm from 'pages/Providers/Invoices/NewInvoiceForm';
import Payments from 'pages/Payments';
import Orders from 'pages/Orders';
import NewOrderForm from 'pages/Orders/NewOrderForm';
import Order from 'pages/Orders/Order';
import RegisterNetWeights from 'pages/Orders/RegisterNetWeights';

export const routes = [
  {
    id: 'main',
    path: '/',
    RouteComponent: SignIn,
  },
  {
    id: 'reset-password',
    path: '/resetPassword',
    RouteComponent: ResetPassword,
  },
  {
    id: 'register',
    path: '/register',
    RouteComponent: Register,
  },
];

export const protectedRoutes = [
  {
    id: 'dashboard',
    path: '/dashboard',
    RouteComponent: Planning,
  },
  {
    id: 'user',
    path: '/usuarios/:id',
    RouteComponent: User,
  },
  {
    id: 'users',
    path: '/usuarios',
    RouteComponent: Users,
  },
  {
    id: 'clients-new-contract',
    path: '/clientes/contratos/nuevo',
    RouteComponent: NewClientContract,
  },
  {
    id: 'client-contract',
    path: '/clientes/contratos/:id',
    RouteComponent: ClientContract,
  },
  {
    id: 'clients-contracts',
    path: '/clientes/contratos',
    RouteComponent: ClientContracts,
  },
  {
    id: 'client',
    path: '/clientes/:id',
    RouteComponent: Client,
  },
  {
    id: 'clients-invoices',
    path: '/clientes/facturas',
    RouteComponent: ClientsInvoices,
  },
  {
    id: 'clients-bill-invoices',
    path: '/clientes/facturas-de-abonos',
    RouteComponent: ClientsBillInvoices,
  },
  {
    id: 'proformas',
    path: '/clientes/proformas',
    RouteComponent: Proformas,
  },
  {
    id: 'clients',
    path: '/clientes',
    RouteComponent: Clients,
  },
  {
    id: 'replacements',
    path: '/proveedores/reposicion',
    RouteComponent: Replacements,
  },
  {
    id: 'replacements-new',
    path: '/proveedores/reposicion/:id',
    RouteComponent: NewReplacement,
  },
  {
    id: 'transfers',
    path: '/clientes/cesion',
    RouteComponent: Transfers,
  },
  {
    id: 'transfer-new',
    path: '/clientes/cesion/nuevo',
    RouteComponent: NewTransfer,
  },
  {
    id: 'transfer',
    path: '/clientes/cesion/:id',
    RouteComponent: Transfer,
  },
  {
    id: 'quality',
    path: '/calidades/:id',
    RouteComponent: Quality,
  },
  {
    id: 'qualities',
    path: '/calidades',
    RouteComponent: Qualities,
  },
  {
    id: 'origin',
    path: '/origenes/:id',
    RouteComponent: Origin,
  },
  {
    id: 'origins',
    path: '/origenes',
    RouteComponent: Origins,
  },
  {
    id: 'providers-new-contract',
    path: '/proveedores/contratos/nuevo',
    RouteComponent: NewProviderContract,
  },
  {
    id: 'provider-contract',
    path: '/proveedores/contratos/:id',
    RouteComponent: ProviderContract,
  },
  {
    id: 'providers-contracts',
    path: '/proveedores/contratos',
    RouteComponent: ProviderContracts,
  },
  {
    id: 'providers-new-invoice',
    path: '/proveedores/facturas/nuevo',
    RouteComponent: NewInvoiceForm,
  },
  {
    id: 'providers-invoice',
    path: '/proveedores/facturas/:id',
    RouteComponent: ProviderInvoice,
  },
  {
    id: 'providers-invoices',
    path: '/proveedores/facturas',
    RouteComponent: ProviderInvoices,
  },
  {
    id: 'providers-new-bill-invoices',
    path: '/proveedores/abonos/nuevo',
    RouteComponent: NewBillInvoiceForm,
  },
  {
    id: 'providers-bill-invoices',
    path: '/proveedores/abonos',
    RouteComponent: ProviderBillInvoices,
  },
  {
    id: 'providers-bill-invoice',
    path: '/proveedores/abonos/:id',
    RouteComponent: ProviderBillInvoice,
  },
  {
    id: 'provider',
    path: '/proveedores/:id',
    RouteComponent: Provider,
  },
  {
    id: 'providers',
    path: '/proveedores',
    RouteComponent: Providers,
  },
  {
    id: 'payments',
    path: '/pagos',
    RouteComponent: Payments,
  },
  {
    id: 'orders',
    path: '/pedidos',
    RouteComponent: Orders,
  },
  {
    id: 'orders-new-order',
    path: '/pedidos/nuevo',
    RouteComponent: NewOrderForm,
  },
  {
    id: 'credit-invoice',
    path: '/pedidos/:id/registro-pesos-reales',
    RouteComponent: RegisterNetWeights,
  },
  {
    id: 'order',
    path: '/pedidos/:id',
    RouteComponent: Order,
  },
];
