import { useState, useEffect } from 'react';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import {
  CREATE_SEND_CLIENT_FORM,
  CLIENT_SEND_FORM_DATA,
} from 'constants/providers';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import AddressCard from './WarehouseCard';
/** Services */
import { createWarehouseData, updateWarehouseData } from 'services/providers';
/** Styles */
import styles from './Warehouse.module.scss';

const WarehouseData = ({ providerId, warehouses = [], onSubmit }) => {
  const [addressData, setAddressData] = useState(CLIENT_SEND_FORM_DATA);
  const [addressList, setAddressList] = useState([]);
  const [hasAddresses, setHasAddresses] = useState(false);
  const [addressId, setAddressId] = useState(0);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const asyncFunction = async () => {
      const target = warehouses.find(({ id }) => id === addressId);
      if (target === undefined) return;
      await setHasAddresses(false);
      populateAddressForm(target);
    };
    asyncFunction();
  }, [addressId]);

  useEffect(() => {
    setAddressList(warehouses.length ? warehouses : []);
    setHasAddresses(!!warehouses.length);
  }, [warehouses]);

  const populateAddressForm = (address) => {
    setIsUpdatingAddress(true);
    CREATE_SEND_CLIENT_FORM.forEach(({ id }) => {
      const input = document.querySelector(`#client-address-form #${id}`);
      input.value = address[id];
    });

    setAddressData({
      ...addressData,
      id: address.id,
      name: address.name,
      country: address.country,
      city: address.city,
      zipCode: address.zipCode,
      addressFirstLine: address.addressFirstLine,
      addressSecondLine: address.addressSecondLine,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isUpdatingAddress) {
      createAddressHandler();
      return;
    }

    updateAddressHandler();
  };

  const createAddressHandler = (e) => {
    setSubmitting(true);

    createWarehouseData({ ...addressData, SupplierId: providerId })
      .then(() => {
        createToast({
          text: 'Dirección de envío añadida',
          type: 'success',
          duration: 2500,
        });
        resetFormHandler();
        onSubmit();
        setSubmitting(false);
      })
      .catch(({ status }) => {
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
        }

        if (status === 500) {
          createToast({ text: 'Algo ha salido mal', type: 'error' });
        }

        setSubmitting(false);
      });
  };

  const updateAddressHandler = (e) => {
    setSubmitting(true);

    updateWarehouseData({
      ...addressData,
      id: addressData.id,
      SupplierId: providerId,
    })
      .then(() => {
        createToast({
          text: 'Datos de almacen actualizados',
          type: 'success',
          duration: 2500,
        });
        resetFormHandler();
        onSubmit();
        setSubmitting(false);
      })
      .catch(({ status }) => {
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
        }

        if (status === 500) {
          createToast({ text: 'Algo ha salido mal', type: 'error' });
        }

        setSubmitting(false);
      });
  };

  const resetFormHandler = () => {
    setAddressData();
    setAddressId();
    setHasAddresses(true);
    setIsUpdatingAddress(false);
  };

  const addNewAddress = () => {
    setHasAddresses(false);
  };

  const changeHandler = ({ target: { name, value } }) => {
    setAddressData({
      ...addressData,
      [name]: value.trim(),
    });
  };

  return (
    <div className={styles.ClientDataWrapper}>
      <div className={styles.ClientDataTitleWrapper}>
        <h2 className={styles.ClientDataTitle}>Datos de Almacenes</h2>
        {hasAddresses && <p onClick={addNewAddress}>Añadir nuevo</p>}
      </div>
      {hasAddresses ? (
        <>
          <div className={styles.ContactWrapper}>
            {addressList.map((values) => (
              <AddressCard
                {...values}
                setAddress={(value) => setAddressId(value)}
                key={values.id}
                onSubmit={onSubmit}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles.ContactData}>
          <form
            id="client-address-form"
            onSubmit={submitHandler}
            className={styles.FormWrapper}
          >
            {CREATE_SEND_CLIENT_FORM.map(({ id, label, type, placeholder }) => (
              <Input
                key={`contact-${id}`}
                name={id}
                className={styles.InputContact}
                placeholder={placeholder}
                type={type}
                label={label}
                onChange={changeHandler}
              />
            ))}
            <div className={styles.ContactFormButtons}>
              {!!warehouses.length && (
                <Button
                  cancel
                  text="Volver"
                  type="button"
                  click={resetFormHandler}
                />
              )}
              <Button
                update
                loading={submitting}
                text={isUpdatingAddress ? 'Actualizar' : 'Añadir'}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default WarehouseData;
