import { forwardRef } from 'react';
import cx from 'classnames';
/** Styles */
import styles from './Textarea.module.scss';

const Textarea = ({ name, label, className, error, ...props }, ref) => {
  const textareaContainerClassnames = cx(styles.textareaContainer, {
    ...(className && { [className]: !!className }),
  });

  const textareaClassnames = cx(styles.textarea, {
    [styles.textareaError]: !!error,
  });

  return (
    <div className={textareaContainerClassnames}>
      {label && (
        <label htmlFor={name} className={styles.textareaLabel}>
          {label}
        </label>
      )}
      <textarea
        id={name}
        name={name}
        ref={ref}
        className={textareaClassnames}
        {...props}
      />
      {error && error.message && (
        <span className={styles.textareaErrorMessage}>{error.message}</span>
      )}
    </div>
  );
};

export default forwardRef(Textarea);
