import { useState } from 'react';
/** Utils */
import { getFormattedDate } from 'utils/date';
/** Components */
import LineItemForm from '../LineItemForm';
/** Styles */
import './LineItem.scss';

const LineItem = ({
  id,
  QualityId,
  price,
  term,
  units = 0,
  providerContracts = [],
  supplierContractId,
  onUpdate,
  onRemove,
  ...rest
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const lineItemData = {
    id,
    QualityId,
    price,
    term,
    units,
    providerContracts,
    supplierContractId,
  };

  const name =
    rest?.Quality?.name || 'Error al recuperar el nombre de la calidad';
  const quantity = rest.Quality.quantity;

  const totalWeight = quantity * units;

  const togglerEdit = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  const updateHandler = (lineItem) => {
    togglerEdit();
    onUpdate(lineItem);
  };

  return (
    <>
      {isEditing ? (
        <LineItemForm
          lineItemData={lineItemData}
          providerContracts={providerContracts}
          cancelEdit={togglerEdit}
          onSubmit={updateHandler}
        />
      ) : (
        <div className="line-item">
          <div className="line-item__wrapper">
            <h3 className="line-item__quality">{name}</h3>
            <ul className="line-item__metadata">
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">Unidades:</span>{' '}
                {units}
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">Envases:</span>{' '}
                {quantity} Kg / saco
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">Peso:</span>{' '}
                {totalWeight} Kg
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">
                  Fecha de retirada:
                </span>{' '}
                {getFormattedDate(term)}
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">Total:</span>{' '}
                {price}€
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default LineItem;
