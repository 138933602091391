import { useState } from 'react';
/** Components */
import LineItem from '../LineItem';
import LineItemForm from '../LineItemForm';
/** Styles */
import './LineItems.scss';

const LineItems = ({
  lineItems,
  providerContracts,
  setLineItems,
  error,
  onUpdateStock,
  onResetStock,
}) => {
  const [addingLineItems, setAddingLineItems] = useState(false);

  // Handler to save line item
  const saveLineItemHandler = (lineItem) => {
    setLineItems([...lineItems, lineItem]);
    setAddingLineItems();
    onUpdateStock(lineItem);
  };

  // Handler to update line item
  const updateLineItemHandler = (lineItem, lineItemIndex) => {
    lineItems[lineItemIndex] = { ...lineItem };
    setLineItems([...lineItems]);
    onUpdateStock(lineItem, true);
  };

  // Handler to remove line item
  const removeLineItemHandler = (index) => {
    onResetStock(lineItems[index]);
    setLineItems([...lineItems.slice(0, index), ...lineItems.slice(index + 1)]);
  };

  return (
    <div className="line-items">
      {lineItems.length ? (
        <div className="line-items__items">
          {lineItems.map((lineItem, index) => (
            <LineItem
              key={`line-item-${index}`}
              providerContracts={providerContracts}
              addingLineItems={addingLineItems}
              onUpdate={(lineItem) => updateLineItemHandler(lineItem, index)}
              onRemove={() => removeLineItemHandler(index)}
              {...lineItem}
            />
          ))}
        </div>
      ) : (
        !addingLineItems && (
          <>
            {error && (
              <span className="line-items__error-message">
                Necesitas añadir al menos una línea de contrato
              </span>
            )}
          </>
        )
      )}
      {addingLineItems && (
        <LineItemForm
          providerContracts={providerContracts}
          onSubmit={saveLineItemHandler}
          cancelEdit={() => setAddingLineItems(false)}
        />
      )}
    </div>
  );
};

export default LineItems;
