import axiosWrapper, { baseUrl } from './core';

const BASE_URL = '/api/qualities';
const ORIGIN_BASE_URL = '/api/origins';

export const getAllVarieties = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-shallow-qualities-varieties`);

export const getAllQualities = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-deep-qualities`);

export const getQuality = (id) =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-quality-by-id/${id}`);

export const createQuality = (params) =>
  axiosWrapper.put(`${baseUrl}${BASE_URL}/create-quality`, params);

export const updateQuality = (params) =>
  axiosWrapper.patch(`${baseUrl}${BASE_URL}/update-quality`, params);

export const deleteQuality = (params) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete-quality`, {
    data: { id: params },
  });

export const createOrigin = (params) =>
  axiosWrapper.put(`${baseUrl}${ORIGIN_BASE_URL}/create-origin`, params);

export const getOrigin = (id) =>
  axiosWrapper.get(`${baseUrl}${ORIGIN_BASE_URL}/get-origin-by-id/${id}`);

export const getAllOrigins = () =>
  axiosWrapper.get(`${baseUrl}${ORIGIN_BASE_URL}/get-all-origins`);

export const updateOrigin = (params) =>
  axiosWrapper.patch(`${baseUrl}${ORIGIN_BASE_URL}/update-origin`, params);

export const deleteOrigin = (params) =>
  axiosWrapper.delete(`${baseUrl}${ORIGIN_BASE_URL}/delete-origin`, {
    data: { id: params },
  });
