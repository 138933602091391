import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
import Loader from 'components/Loader';
/** Services */
import { getClientsContracts } from 'services/contracts';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { CONTRACT_HEAD_CELLS } from 'constants/clients';
/** Styles */
import './Contratos.scss';

const Contratos = ({ client }) => {
  const navigate = useNavigate();
  const [contractList, setContractList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getClientsContracts()
      .then(({ status, data: { clientContracts } }) => {
        if (status === 200) {
          const mappedContracts = clientContracts.filter(
            (contract) => contract.Client.id === client
          );
          setContractList(mappedContracts);
        }
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen contratos de clientes',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  const mappedClients = contractList.map(
    ({ contractNumber = '', contractDate = '', Client, id }) => {
      return {
        ...(contractNumber
          ? { contractNumber }
          : { contractNumber: 'Sin nº de contrato' }),
        ...(Client ? { ClientId: Client.name } : { ClientId: 'Sin cliente' }),
        ...(contractDate ? { contractDate } : { contractDate: 'Sin fecha' }),
        metadata: { fileId: id, key: 'clientContract' },
        ...(id && { id }),
      };
    }
  );

  const handleSelectContract = (contractId) => {
    navigate(`/clientes/contratos/${contractId}`);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {contractList.length !== 0 ? (
        <Table
          rowsData={mappedClients}
          headCells={CONTRACT_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectContract}
        />
      ) : (
        <div className="EmptyState">No hay Contratos.</div>
      )}
    </>
  );
};

export default Contratos;
