import { useCallback } from 'react';
/** Styles */
import styles from './Switch.module.scss';

const Switch = ({
  id,
  value = false,
  children,
  onChange,
  className = '',
  disabled = false,
}) => {
  const handleChange = useCallback(
    ({ target: { checked } }) => {
      if (disabled) return;
      onChange(checked);
    },
    [onChange]
  );

  return (
    <div className={className ?? styles.Switch}>
      <input
        id={id}
        name={id}
        type="checkbox"
        className={styles.SwitchInput}
        value={value}
        defaultChecked={value}
        disabled={disabled}
        onChange={handleChange}
      />
      <label className={styles.SwitchLabel} htmlFor={id}>
        <div className={styles.SwitchSliderWrapper}>
          <span className={styles.SwitchSlider} />
        </div>
        <span className={styles.SwitchText}>{children}</span>
      </label>
    </div>
  );
};

export default Switch;
