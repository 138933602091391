import { useState } from 'react';
/** Utils */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Components */
import LineClientForm from '../LineClientForm';
/** Styles */
import './LineClient.scss';

const LineItem = ({
  clientId,
  clientName,
  palletized,
  transportIsIncluded,
  warehouseAddress,
  paymentTypeId,
  paymentName,
  onUpdate,
  onRemove,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const lineClientData = {
    clientId,
    palletized,
    transportIsIncluded,
    paymentTypeId,
    warehouseAddress,
  };

  const togglerEdit = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  const updateHandler = (lineClient) => {
    togglerEdit();
    onUpdate(lineClient);
  };

  return (
    <>
      {isEditing ? (
        <LineClientForm
          lineClientData={lineClientData}
          cancelEdit={togglerEdit}
          onSubmit={updateHandler}
        />
      ) : (
        <div className="line-item">
          <div className="line-item__wrapper">
            <h3 className="line-item__quality">{clientName}</h3>
            <ul className="line-item__metadata">
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">Paletizado:</span>{' '}
                {palletized ? 'Si' : 'No'}
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">
                  Transporte incluido:
                </span>{' '}
                {transportIsIncluded ? 'Si' : 'No'}
              </li>
              <li className="line-item__metadata-line">
                <span className="line-item__metadata-title">
                  Método de pago:
                </span>{' '}
                {paymentName}
              </li>
            </ul>
          </div>
          <div className="line-item__actions">
            <FontAwesomeIcon
              icon="pencil-alt"
              className="line-item__actions-icon"
              onClick={togglerEdit}
            />
            <FontAwesomeIcon
              icon="trash"
              className="line-item__actions-icon"
              onClick={onRemove}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LineItem;
