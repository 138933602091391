import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import OrdersList from './OrdersList';
/** Services */
import { getOrders } from 'services/orders';
/** Utils */
import { createToast } from 'utils/toast';
/** Styles */
import './Orders.scss';

const Orders = () => {
  const [ordersList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrders()
      .then(({ status, data = [] }) => {
        if (status === 200) {
          setOrderList(data);
        }
        setLoading(false);
      })
      .catch(({ status, response }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: response.data.feedback, type: 'error' });
      });
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="orders">
      <div className="orders__header">
        <h1 className="orders__header-title">Pedidos</h1>
        <NavLink to="/pedidos/nuevo">
          <Button update text="Crear nuevo pedido" />
        </NavLink>
      </div>
      <div className="orders__list">
        <OrdersList orders={ordersList} />
      </div>
    </div>
  );
};

export default Orders;
