import axiosWrapper, { baseUrl } from './core';

const BASE_URL = '/api/orders';

export const getStock = (clientId) =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-stock/${clientId}`);

export const createNewOrder = (params) =>
  axiosWrapper.put(`${baseUrl}${BASE_URL}/create-order`, params);

export const getOrders = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-all-orders`);

export const getSupplierOrders = (supplierId) =>
  axiosWrapper.get(
    `${baseUrl}${BASE_URL}/get-orders-by-supplier-id/${supplierId}`
  );

export const getOrder = (orderId) =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-order-by-id/${orderId}`);

export const getOrderByBatches = (id, query = '') =>
  axiosWrapper.get(
    `${baseUrl}${BASE_URL}/get-order-sorted-by-batch/${id}${query}`
  );

export const updateOrder = (params) =>
  axiosWrapper.patch(`${baseUrl}${BASE_URL}/update-order`, params);

export const removeOrder = (orderId) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete-order`, {
    orderId,
  });

export const registerBatches = (params) =>
  axiosWrapper.post(`${baseUrl}${BASE_URL}/register-batches`, params);

export const registerNetWeights = (params) =>
  axiosWrapper.post(`${baseUrl}${BASE_URL}/register-net-weights`, params);

export const getOrdersBySupplierAndContract = (supplierId, contractId) =>
  axiosWrapper.get(
    `${baseUrl}/api/stock/get-stock-by-supplier-and-contract/${supplierId}/${contractId}`
  );

export const getOrdersBySupplierContract = (contractId, params = '') =>
  axiosWrapper.get(
    `${baseUrl}${BASE_URL}/get-orders-by-supplier-contract/${contractId}${params}`
  );
