/** Styles */
import styles from './Loader.module.scss';
import cx from 'classnames';

const Loader = ({
  isInverse = false,
  hasBox = true,
  hasText = false,
  sizingClass = '',
}) => {
  const loaderContainerClasses = cx(styles.AnimationContainer, {
    [styles.Boxed]: !!hasBox,
  });

  const loaderSizingClass = cx(styles.loader, {
    [styles[sizingClass]]: !!sizingClass.length,
    [styles.loaderInverse]: isInverse,
  });

  return (
    <div className={loaderContainerClasses}>
      {hasText && <h3 className={sizingClass}>Cargando...</h3>}
      <div className={loaderSizingClass}></div>
    </div>
  );
};

export default Loader;
