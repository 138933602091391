import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Services */
import { deleteQuality } from 'services/qualities.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/**Constants */
import { QUALITIES_HEAD_CELLS, QUALITY_TYPES } from 'constants/qualities.js';
/** Styles */
import styles from './QualityList.module.scss';

const QualityList = ({
  qualities = [],
  varieties = {},
  origins = [],
  providers = [],
  reloadQualities,
}) => {
  const navigate = useNavigate();

  const qualityTypes = useMemo(
    () =>
      QUALITY_TYPES.reduce((acc, type) => {
        acc[type.id] = type;
        return acc;
      }, {}),
    []
  );

  const mappedQualities = qualities.map(
    ({
      id,
      name = '',
      QualityVarietyId = '',
      OriginId = '',
      SupplierId = '',
      type = {},
    }) => {
      const origin = origins.find(({ id }) => id === OriginId);
      const supplier = providers.find(({ id }) => id === SupplierId);
      const types = Object.entries(type)
        .filter(([, value]) => value)
        .map(([key]) => qualityTypes[key].label)
        .join(', ');
      console.log(1111, qualityTypes);
      return {
        qualityId: id,
        ...(id && { id }),
        ...(name ? { name } : { name: 'Sin nombre' }),
        ...(QualityVarietyId
          ? { qualityVarietyId: varieties[QualityVarietyId] }
          : { qualityVarietyId: 'Sin variedad' }),
        ...(types ? { types } : { types: 'Sin grupo' }),
        ...(origin ? { OriginId: origin.name } : { OriginId: 'Sin origen' }),
        ...(supplier
          ? { SupplierId: supplier.name }
          : { SupplierId: 'Sin proveedor' }),
      };
    }
  );

  const handleSelectQuality = (qualityId) => {
    navigate(`/calidades/${qualityId}`);
  };

  const deleteHandler = (clientsIds) => {
    deleteQuality(clientsIds)
      .then(({ status }) => {
        if (status === 204) {
          createToast({
            text: 'Calidad eliminada con éxito',
            type: 'success',
            duration: 2500,
          });
          const updatedQualities = qualities.filter(
            ({ id }) => !clientsIds.includes(id)
          );
          reloadQualities(updatedQualities);
        }
      })
      .catch((error) => {
        console.error(error);
        createToast({
          text: 'No se ha podido eliminar la calidad, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {qualities.length ? (
        <Table
          rowsData={mappedQualities}
          headCells={QUALITIES_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectQuality}
          deleteHandler={(value) => deleteHandler(value)}
        />
      ) : (
        <div className={styles.EmptyState}>No hay datos.</div>
      )}
    </>
  );
};

export default QualityList;
