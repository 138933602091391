import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Syles */
import styles from './Back.module.scss';

const Back = ({ text = 'Volver', path = '/' }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(path);
  };

  return (
    <button className={styles.Back} onClick={handleGoBack}>
      <FontAwesomeIcon icon="chevron-left" className={styles.BackIcon} />
      {text}
    </button>
  );
};

export default Back;
