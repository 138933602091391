import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
/** Services */
import { setToken } from 'services/core';
import { signIn, createUser, passwordRecovery } from 'services/auth';
/** Utils */
import {
  getCurrentUser,
  setCurrentUser,
  removeTokens,
  renameCurrentUser,
} from 'utils/session';
import { createToast } from 'utils/toast';

const AuthContext = createContext();

// Export the provider as we need to wrap the entire app with it
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  //
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    getCurrentUser()
      .then((user) => setUser(user))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoadingInitial(false));
  }, []);

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that loading the
  // loading state is over.
  const login = (email, password) => {
    setLoading(true);

    signIn({ email, password })
      .then(({ status, data: { token, safeUserData = {} } }) => {
        if (status === 200) {
          setToken(token);
          const user = { ...safeUserData, email };
          setUser(user);
          setCurrentUser(user, token);
          navigate('/');
        }
      })
      .catch((error) => {
        setError(error);
        console.error(error);
        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  // Sends sign up details to the server. On success we just apply
  // the created user to the state.
  const signUp = (userData) => {
    setLoading(true);

    createUser(userData)
      .then(({ status }) => {
        if (status === 204) {
          navigate('/');
        }
      })
      .catch((error) => {
        setError(error);
        console.error(error);
        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  // Call the logout endpoint and then remove the user
  // from the state.
  const logout = () => {
    removeTokens();
    setUser(null);
    navigate('/');
  };

  const recoverPassword = (email) => {
    passwordRecovery({ email })
      .then(({ status }) => {
        if (status === 200) {
          createToast({
            text: 'Se ha solicitado un cambio de contraseña',
            type: 'success',
            duration: 2500,
          });
        }
        return Promise.resolve(status);
      })
      .catch((error) => {
        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
        return Promise.reject(error);
      });
  };

  const renameUser = (name) => {
    setUser({ ...user, name });
    renameCurrentUser(name);
  };

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      signUp,
      logout,
      recoverPassword,
      renameUser,
    }),
    [user, loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
