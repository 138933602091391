import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import cx from 'classnames';
/** Components */
import Back from 'components/Back';
import Select from 'components/Select';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Table from 'components/Table';
/** Services */
import { getAllClients } from 'services/clients';
import { getAllQualities } from 'services/qualities';
import { getClientsContracts, getClientContract } from 'services/contracts';
/** Constants */
import {
  NEW_TRANSFER_HEAD_CELLS_ORIGIN,
  NEW_TRANSFER_EDIT_HEAD_CELLS,
} from 'constants/transfers';
/** Styles */
import './Transfer.scss';

const Transfer = () => {
  const [qualities, setQualities] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [originContracts, setOriginContracts] = useState([]);
  const [originContractData, setOriginContractData] = useState([]);
  const [targetContractData, setTargetContractData] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    defaultValues: { date: new Date() },
  });

  const originClient = watch('originClient');
  const originContract = watch('originContract');
  const targetClient = watch('targetClient');

  const contractName = useMemo(() => {
    if (!originContract && originContracts.length !== 0) return;
    return originContracts.find(({ id }) => id === originContract)
      ?.contractNumber;
  }, [originContract]);

  const targetClientName = useMemo(() => {
    if (!targetClient && !clientList.length !== 0) return;
    return clientList.find(({ id }) => id === targetClient)?.name;
  }, [targetClient]);

  const mapperOriginContractData = originContractData.map(
    ({
      QualityId: qualityName = '',
      buyPrice = 0,
      sellPrice = 0,
      term = '',
      assignedStock = 0,
      id,
    }) => {
      return {
        ...(qualityName
          ? {
              qualityName: qualities.find(
                (quality) => quality.id === qualityName
              ).name,
            }
          : { qualityName: 'Sin calidad' }),
        ...(buyPrice ? { buyPrice } : { buyPrice: 'Sin precio de compra' }),
        ...(sellPrice ? { sellPrice } : { sellPrice: 'Sin precio de venta' }),
        ...(term ? { term } : { term: 'Sin fecha de retiro' }),
        ...(assignedStock
          ? { assignedStock }
          : { assignedStock: 'Sin stock disponible' }),
        ...(id && { id }),
      };
    }
  );

  const mapperTargetContractData = targetContractData.map(
    ({
      QualityId: qualityName = '',
      buyPrice = 0,
      sellPrice = 0,
      assignedStock = 0,
      id,
    }) => {
      return {
        ...(qualityName
          ? {
              qualityName: qualities.find(
                (quality) => quality.id === qualityName
              ).name,
            }
          : { qualityName: 'Sin calidad' }),
        ...(buyPrice ? { buyPrice } : { buyPrice: 'Sin precio de compra' }),
        ...(sellPrice ? { sellPrice } : { sellPrice: 0 }),
        ...(assignedStock ? { assignedStock } : { assignedStock: 0 }),
        ...(id && { id }),
      };
    }
  );

  // Classes to make error for dates
  const inputDateClassNames = cx('new-transfer__basic-date', {
    'new-transfer__basic-date--error': !!errors.date,
  });

  useEffect(() => {
    setLoading(true);
    const fetchTransfer = async () => {
      try {
        // const { data } = (await getTransfer(id)) ?? {};
        // const { originClient, originContract, targetClient, term, details } = data;
        await reset({
          originClient: 16,
          originContract: 80,
          targetClient: 17,
          term: '2022-04-02T12:58:42.000Z',
        });

        // Get clients
        await getAllClients()
          .then(({ status, data: { fetchedClients } }) => {
            if (status === 200) {
              setClientList(fetchedClients);
            }
          })
          .catch((error) => {
            console.error(error);
          });

        // Get al qualities
        await getAllQualities()
          .then(({ data: { parsedVarieties } }) => {
            const normalizedQualities = parsedVarieties
              .map(({ qualities = [] }) => qualities)
              .flat();
            setQualities(normalizedQualities);
          })
          .catch((error) => {
            console.error(error);
          });

        await getClientsContracts(originClient)
          .then(({ data: { clientContracts } }) => {
            const filterContracts = clientContracts
              .filter(({ ClientId }) => ClientId === originClient)
              .map((contract) => ({
                ...contract,
                name: contract.contractNumber,
              }));
            setOriginContracts(filterContracts);
          })
          .catch((error) => {
            console.error(error);
          });

        await getClientContract(originContract)
          .then(
            ({
              data: {
                clientContract: { ClientContractDetailData },
              },
            }) => {
              setOriginContractData(ClientContractDetailData);
              setTargetContractData(ClientContractDetailData);
              setOrderItems(ClientContractDetailData);
            }
          )
          .catch((error) => {
            console.error(error);
          });
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchTransfer();
  }, [id]);

  useEffect(() => {
    // Get clients
    getAllClients()
      .then(({ status, data: { fetchedClients } }) => {
        if (status === 200) {
          setClientList(fetchedClients);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // Get al qualities
    getAllQualities()
      .then(({ data: { parsedVarieties } }) => {
        const normalizedQualities = parsedVarieties
          .map(({ qualities = [] }) => qualities)
          .flat();
        setQualities(normalizedQualities);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!originClient) return;

    setFetching(true);

    getClientsContracts(originClient)
      .then(({ data: { clientContracts } }) => {
        const filterContracts = clientContracts
          .filter(({ ClientId }) => ClientId === originClient)
          .map((contract) => ({ ...contract, name: contract.contractNumber }));
        setOriginContracts(filterContracts);
        setFetching(false);
      })
      .catch((error) => {
        console.error(error);
        setFetching(false);
      });
  }, [originClient]);

  useEffect(() => {
    if (!originContract) return;

    setFetching(true);

    getClientContract(originContract)
      .then(
        ({
          data: {
            clientContract: { ClientContractDetailData },
          },
        }) => {
          setOriginContractData(ClientContractDetailData);
          setTargetContractData(ClientContractDetailData);
          setOrderItems(ClientContractDetailData);
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }, [originContract]);

  // TO DO
  const createTransfer = (data) => {
    navigate('/proveedores/cesion');
  };

  // Handler to update order item
  const onUpdate = (itemId, { name, value }) => {
    const index = orderItems.findIndex(({ id }) => id === itemId);
    const target = orderItems[index];
    const item = {
      ...target,
      [name]: value,
    };

    setOrderItems([
      ...orderItems.slice(0, index),
      item,
      ...orderItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    createTransfer(data);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back text="Volver a las cesiones" path="/clientes/cesion" />

      <form className="new-transfer" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="new-transfer__header">Cesión de stock</h2>
        <div className="new-transfer__basic-data">
          <div className="new-transfer__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Cliente de origen:
            </label>
            <Controller
              control={control}
              name="originClient"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clientList}
                  placeholder="Cliente de origen"
                  value={value}
                  error={errors.originClient}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-transfer__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Contrato de origen:
            </label>
            <Controller
              control={control}
              name="originContract"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={originContracts}
                  placeholder="Contrato de origen"
                  value={value}
                  error={errors.originContract}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-transfer__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Cliente destino:
            </label>
            <Controller
              control={control}
              name="targetClient"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clientList}
                  placeholder="Cliente de destino"
                  value={value}
                  error={errors.targetClient}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className={inputDateClassNames}>
            <label>Fecha de retiro:</label>
            <Controller
              control={control}
              name="term"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.term}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
        </div>
        {!!originContractData.length && targetClient ? (
          <>
            <h2 className="new-transfer__header-table">
              Contrato de origen: {contractName}
            </h2>
            <Table
              id="origin-client"
              rowsData={mapperOriginContractData}
              headCells={NEW_TRANSFER_HEAD_CELLS_ORIGIN}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={onUpdate}
            />
          </>
        ) : (
          <>
            <div className="new-transfer__empty">
              Selecciona un cliente y contrato de origen
            </div>
          </>
        )}
        {!!targetContractData.length && targetClient && (
          <>
            <h2 className="new-transfer__header-table">
              Receptor: {targetClientName}
            </h2>
            <Table
              id="target-client"
              rowsData={mapperTargetContractData}
              headCells={NEW_TRANSFER_EDIT_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={onUpdate}
            />
          </>
        )}
        <div className="new-contract__footer">
          <Button
            type="submit"
            disabled={fetching}
            loading={submitting}
            update
            text="Actualizar cesión"
          />
        </div>
      </form>
    </>
  );
};

export default Transfer;
