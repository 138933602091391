import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Back from 'components/Back';
import Input from 'components/Input';
import Select from 'components/Select';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Table from 'components/Table';
/** Services */
import { getAllClients } from 'services/clients';
import { createNewOrder, getStock } from 'services/orders';
import { getAllPayments } from 'services/payments';
/** Constants */
import { ORDER_QUALITIES_HEAD_CELLS, IVA_OPTIONS } from 'constants/orders';
/** Styles */
import './NewOrderForm.scss';

const NewOrderForm = () => {
  const [clientList, setClientList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      date: new Date(),
      vat: 10,
      description: '',
      sellPrice: '',
    },
  });

  const clientId = watch('clientId');
  const isDisabled = useMemo(
    () =>
      !clientId ||
      fetching ||
      orderItems.every((item) => Boolean(item?.quantity) === false),
    [clientId, fetching, orderItems]
  );

  const buyTotalPrice = useMemo(
    () =>
      orderItems.reduce((acc, { quantity, buyPrice, packCapacity }) => {
        const parsedQuantity = Math.floor(quantity ?? 0);
        const parsedCapacity = Math.floor(packCapacity ?? 0);
        const total = buyPrice * parsedCapacity * parsedQuantity;
        return total + acc;
      }, 0),
    [orderItems]
  );

  const sellTotalPrice = useMemo(
    () =>
      orderItems.reduce((acc, { quantity, sellPrice, packCapacity }) => {
        const parsedQuantity = Math.floor(quantity ?? 0);
        const parsedCapacity = Math.floor(packCapacity ?? 0);
        const total = sellPrice * parsedCapacity * parsedQuantity;
        return total + acc;
      }, 0),
    [orderItems]
  );

  const totalUtility = useMemo(
    () => Math.abs(buyTotalPrice - sellTotalPrice),
    [buyTotalPrice, sellTotalPrice]
  );

  const mappedOrderItems = orderItems.map(
    ({
      qualityName = '',
      comesFromSupplierContractNumber = '',
      comesFromClientContractNumber = '',
      sellPrice = '',
      buyPrice = '',
      availableStock = '',
      term = '',
      quantity = '',
      stockId: id,
    }) => {
      return {
        ...(qualityName ? { qualityName } : { qualityName: 'Sin calidad' }),
        ...(comesFromSupplierContractNumber
          ? { comesFromSupplierContractNumber }
          : { comesFromSupplierContractNumber: '-' }),
        ...(comesFromClientContractNumber
          ? { comesFromClientContractNumber }
          : { comesFromClientContractNumber: '-' }),
        ...(sellPrice ? { sellPrice } : { sellPrice: 'Sin precio de venta' }),
        ...(buyPrice ? { buyPrice } : { buyPrice: 'Sin precio de compra' }),
        ...(availableStock
          ? { availableStock }
          : { availableStock: 'Sin stock' }),
        ...(term ? { term } : { term: 'Sin fecha de retiro' }),
        ...(quantity ? { quantity } : { quantity: 0 }),
        ...(id && { id }),
      };
    }
  );

  // Classes to make error for dates
  const inputDateClassNames = cx('new-order__basic-date', {
    'new-order__basic-date--error': !!errors.date,
  });

  useEffect(() => {
    setLoading(true);

    // Get clients
    getAllClients()
      .then(({ status, data: { fetchedClients } }) => {
        if (status === 200) {
          setClientList(fetchedClients);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

    // Get payments
    getAllPayments()
      .then(({ status, data: { fetchedPaymentOptions } }) => {
        if (status === 200) {
          const normalizedPayments = fetchedPaymentOptions.map((payment) => ({
            ...payment,
            name: payment.label,
          }));
          setPaymentList(normalizedPayments);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!clientId) return;

    setFetching(true);
    setOrderItems([]);

    getStock(clientId)
      .then(({ status, data }) => {
        if (status === 200) {
          setOrderItems(data);
        }
        setFetching(false);
      })
      .catch((error) => {
        console.error(error);
        setFetching(false);
      });
  }, [clientId]);

  // Check if order is valid
  const checkIfOrderIsValid = () => {
    const orderItemErrors = orderItems.some((item) => item.availableStock < 0);

    return orderItemErrors;
  };

  // Handler to create a order
  const createOrder = ({ description, sellPrice, vat, ...data }) => {
    if (checkIfOrderIsValid()) return;

    setSubmitting(true);

    const normalizedOrderItems = orderItems.map(
      ({
        comesFromClientContractNumber,
        comesFromSupplierContractNumber,
        availableStock,
        qualityName,
        supplierName,
        id,
        ...orderItem
      }) => ({
        ...orderItem,
        vat: 10,
      })
    );

    const additionalConcepts = { description, sellPrice, vat };

    const payload = {
      ...data,
      details: [...normalizedOrderItems],
      additionalConcept: additionalConcepts,
    };

    createNewOrder(payload)
      .then(({ status }) => {
        if (status === 201) {
          createToast({
            text: 'Pedido creado con éxito',
            type: 'success',
          });
          navigate('/pedidos');
        }
      })
      .catch(({ status, response }) => {
        setSubmitting(false);

        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: response.data.feedback, type: 'error' });
      });
  };

  // Handler to update order item
  const onUpdate = (itemId, { name, value }) => {
    const index = orderItems.findIndex(({ stockId: id }) => id === itemId);
    const target = orderItems[index];
    const item = {
      ...target,
      [name]: value,
    };

    setOrderItems([
      ...orderItems.slice(0, index),
      item,
      ...orderItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    createOrder(data);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back text="Volver a los pedidos" path="/pedidos" />

      <form className="new-order" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="new-order__header">Nuevo pedido</h2>
        <div className="new-order__basic-data">
          <div className={inputDateClassNames}>
            <label>Plazo de entrega:</label>
            <Controller
              control={control}
              name="date"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.date}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          <div className="new-order__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Método de pago:
            </label>
            <Controller
              control={control}
              name="paymentTypeId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={paymentList}
                  placeholder="Método de pago"
                  value={value}
                  error={errors.paymentTypeId}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-order__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Cliente:
            </label>
            <Controller
              control={control}
              name="clientId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clientList}
                  placeholder="Cliente"
                  value={value}
                  error={errors.clientId}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        {!orderItems?.length && !fetching && <h1>No hay stock disponible</h1>}
        {!orderItems?.length && fetching && <h1>Cargando stock...</h1>}
        {!!orderItems?.length && (
          <div className="new-order__order-lines">
            <h3 className="new-order__order-lines-header">Líneas de pedido</h3>
            <Table
              rowsData={mappedOrderItems}
              headCells={ORDER_QUALITIES_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={onUpdate}
              stickyColumns={['quantity']}
            />
            <div className="new-order__basic-data new-order__order-lines-extra">
              <div className="new-order__basic-data-input">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="description"
                      placeholder="Descripción"
                      label="Descripción:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="new-order__basic-data-input">
                <Controller
                  control={control}
                  name="sellPrice"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="sellPrice"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="Precio de venta"
                      label="Precio de venta:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="new-order__basic-data-input">
                <label className="NewContractFormRightProviderSelectTitle">
                  IVA:
                </label>
                <Controller
                  control={control}
                  name="vat"
                  rules={{ required: 'El campo es obligatorio' }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      options={IVA_OPTIONS}
                      placeholder="IVA"
                      value={value}
                      canSearch={false}
                      error={errors.vat}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="new-order__total-price">
              Precio total de compra: <span>{buyTotalPrice.toFixed(2)}€</span>
            </div>
            <div className="new-order__total-price">
              Precio total de venta: <span>{sellTotalPrice.toFixed(2)}€</span>
            </div>
            <div className="new-order__total-price">
              Utilidad total: <span>{totalUtility.toFixed(2)}€</span>
            </div>
          </div>
        )}
        <div className="new-contract__footer">
          <Button
            disabled={isDisabled}
            loading={submitting}
            type="submit"
            update
            text="Crear pedido"
          />
        </div>
      </form>
    </>
  );
};

export default NewOrderForm;
