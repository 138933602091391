import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
/** Context */
import useAuth from 'context/useAuth';
/** Services */
import { updateUser, deleteUser } from 'services/users';
/** Constants */
import { MODIFIY_USER_FORM, MODIFIY_USER_FORM_DATA } from 'constants/users';
/** Utils */
import { createToast } from 'utils/toast';
import { validateEmail } from 'utils/email';
/** Styles */
import styles from './UserForm.module.scss';

export const UserForm = ({ user }) => {
  const [userData, setUserData] = useState(MODIFIY_USER_FORM_DATA);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [safeDelete, setSafeDelete] = useState(false);
  const navigate = useNavigate();
  const { user: currentUser, renameUser } = useAuth();

  useEffect(() => {
    populateForm();
  }, []);

  const populateForm = () => {
    MODIFIY_USER_FORM.forEach(({ id }) => {
      const input = document.querySelector(`#${id}`);
      input.value = user[id];
    });
    setUserData({
      ...userData,
      id: user.id,
      name: user.name,
      surnames: user.surnames,
      email: user.email,
    });
    setIsValidEmail(validateEmail(user.email));
  };

  const changeHandler = ({ target: { name, value } }) => {
    setUserData({
      ...userData,
      [name]: value,
    });

    if (name === 'email') {
      setIsValidEmail(validateEmail(value));
    }
  };

  const removeHandler = () => {
    if (safeDelete) {
      deleteUser(user.id)
        .then(({ status }) => {
          if (status === 204) {
            createToast({
              text: 'Usuario eliminado con éxito',
              type: 'success',
              duration: 2500,
            });
          }
          setIsLoaded(false);
          setTimeout(() => {
            navigate('/usuarios');
          }, 500);
        })
        .catch(() => {
          createToast({
            text: 'No se ha podido eliminar el usuario, intentalo de nuevo más tarde',
            type: 'error',
          });
        });
      return;
    }

    setSafeDelete(true);
    setTimeout(() => {
      setSafeDelete(false);
    }, 3000);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setIsLoaded(false);

    updateUser(userData)
      .then(({ status }) => {
        if (status === 204) {
          createToast({
            text: 'Usuario modificado con éxito',
            type: 'success',
            duration: 2500,
          });

          const { name, email } = userData;
          const { name: authName, email: authEmail } = currentUser;

          if (email === authEmail && name !== authName) {
            renameUser(name);
          }
        }

        setTimeout(() => {
          navigate('/usuarios');
        }, 500);
      })
      .catch(() => {
        setIsLoaded(true);
        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {isLoaded ? (
        <form onSubmit={submitHandler} className={styles.UserForm}>
          {MODIFIY_USER_FORM.map(({ id, label, type, placeholder }) => (
            <Input
              key={id}
              name={id}
              placeholder={placeholder}
              type={type}
              label={label}
              onChange={changeHandler}
            />
          ))}

          <div className={styles.UserFormButtons}>
            <Button update text="Actualizar" disabled={!isValidEmail} />
            <Button
              remove
              text={safeDelete ? 'Confirmar' : 'Eliminar'}
              type="button"
              click={removeHandler}
            />
          </div>
        </form>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default UserForm;
