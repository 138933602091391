import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Components */
import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';
/** Styles */
import './InvoiceBatches.scss';

const InvoiceBatches = ({ id, batches: initialBatches, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [batches, setBatches] = useState(initialBatches ?? [{}]);

  const toggleModal = () => {
    setBatches(initialBatches ?? [{}]);
    setShowModal((show) => !show);
  };

  const handleBatchChange = ({ target: { value } }, index) => {
    const newBatches = [...batches];
    newBatches[index].batch = value;
    setBatches(newBatches);
  };

  const handleBatchQuantityChange = ({ target: { value } }, index) => {
    const newBatches = [...batches];
    newBatches[index].quantity = parseInt(value);
    setBatches(newBatches);
  };

  const handleAddNewBatch = () => {
    setBatches([...batches, {}]);
  };

  const handleRemoveNewBatch = () => {
    if (batches?.length > 1) {
      setBatches(batches.slice(0, -1));
    }
  };

  const handleSubmnit = () => {
    const filteredBatches = batches.filter((batch) => batch);
    onChange && onChange({ name: 'batch', value: filteredBatches });
    toggleModal();
    setBatches(filteredBatches);
  };

  return (
    <>
      {initialBatches?.length ? (
        <div className="batches-modal__cell">
          <Button
            type="button"
            update
            text={`Lotes (${initialBatches.length})`}
            click={toggleModal}
          />
        </div>
      ) : (
        <Button type="button" update text="Añadir lotes" click={toggleModal} />
      )}
      <Modal
        show={showModal}
        title="Registrar lotes"
        id={`invoice-batches-${id}`}
        successButton="Guardar"
        isNarrow={true}
        onClose={() => toggleModal()}
        onSubmit={handleSubmnit}
      >
        <div className="batches-modal">
          {batches.map(({ batch, quantity }, index) => (
            <div className="batches-modal__batch" key={index}>
              <label htmlFor={`batch-number-${index}`}>
                Lote {index + 1}
                {batches.length > 1 && ' / Cantidad'}
              </label>
              <Input
                name={`batch-number-${index}`}
                value={batch}
                onChange={(e) => handleBatchChange(e, index)}
              />
              {batches.length > 1 && (
                <Input
                  name={`batch-quantity-${index}`}
                  type="number"
                  value={quantity}
                  onChange={(e) => handleBatchQuantityChange(e, index)}
                />
              )}
              {index === batches.length - 1 && (
                <>
                  <button
                    type="button"
                    className="batches-modal__add"
                    onClick={handleAddNewBatch}
                  >
                    <FontAwesomeIcon
                      icon="plus"
                      className="batches-modal__add-icon"
                    />
                  </button>
                  {batches?.length > 1 && (
                    <button
                      type="button"
                      className="batches-modal__add"
                      onClick={handleRemoveNewBatch}
                    >
                      <FontAwesomeIcon
                        icon="trash"
                        className="batches-modal__add-icon"
                      />
                    </button>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default InvoiceBatches;
