import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Back from 'components/Back';
import Select from 'components/Select';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Table from 'components/Table';
/** Services */
import { getAllClients } from 'services/clients';
import { getOrder, updateOrder, getStock } from 'services/orders';
import { getAllPayments } from 'services/payments';
import { getSuppliersInvoices } from 'services/invoices';
/** Constants */
import { ORDER_QUALITIES_HEAD_CELLS, IVA_OPTIONS } from 'constants/orders';
/** Styles */
import './Order.scss';

const parseOrderItems = (orderItems = []) =>
  orderItems.map(
    ({
      qualityName = '',
      comesFromSupplierContractNumber = '',
      comesFromClientContractNumber = '',
      sellPrice = '',
      buyPrice = '',
      availableStock = '',
      term = '',
      quantity = '',
      comesFromAvailableStockId: id,
    }) => {
      return {
        ...(qualityName ? { qualityName } : { qualityName: 'Sin calidad' }),
        ...(comesFromSupplierContractNumber
          ? { comesFromSupplierContractNumber }
          : { comesFromSupplierContractNumber: '-' }),
        ...(comesFromClientContractNumber
          ? { comesFromClientContractNumber }
          : { comesFromClientContractNumber: '-' }),
        ...(sellPrice ? { sellPrice } : { sellPrice: 'Sin precio de venta' }),
        ...(buyPrice ? { buyPrice } : { buyPrice: 'Sin precio de compra' }),
        ...(availableStock
          ? { availableStock }
          : { availableStock: 'Sin stock' }),
        ...(term ? { term } : { term: 'Sin fecha de retiro' }),
        ...(quantity ? { quantity } : { quantity: 0 }),
        ...(id && { id }),
        id,
      };
    }
  );

const Order = () => {
  const [clientList, setClientList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [proforma, setProforma] = useState({});
  const [addMoreOrderItems, setAddMoreOrderItems] = useState(true);
  const clientRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: { date: new Date(), vat: 10 },
  });

  const clientId = watch('clientId');
  const orderStatus = watch('status');

  const isDisabled = useMemo(
    () => orderStatus !== 'beforePayment',
    [orderStatus]
  );

  const buyTotalPrice = useMemo(
    () =>
      orderItems.reduce((acc, { quantity, buyPrice, packCapacity }) => {
        const parsedQuantity = Math.floor(quantity ?? 0);
        const parsedCapacity = Math.floor(packCapacity ?? 0);
        const total = buyPrice * parsedCapacity * parsedQuantity;
        return total + acc;
      }, 0),
    [orderItems]
  );

  const sellTotalPrice = useMemo(
    () =>
      orderItems.reduce((acc, { quantity, sellPrice, packCapacity }) => {
        const parsedQuantity = Math.floor(quantity ?? 0);
        const parsedCapacity = Math.floor(packCapacity ?? 0);
        const total = sellPrice * parsedCapacity * parsedQuantity;
        return total + acc;
      }, 0),
    [orderItems]
  );

  const totalUtility = useMemo(
    () => Math.abs(buyTotalPrice - sellTotalPrice),
    [buyTotalPrice, sellTotalPrice]
  );

  const addedOrderItems = useMemo(
    () => orderItems.filter(({ quantity }) => quantity > 0),
    [orderItems]
  );

  const extraOrderItems = useMemo(
    () => orderItems.filter(({ quantity }) => !quantity || quantity <= 0),
    [orderItems]
  );

  const mappedAddedOrderItems = useMemo(
    () => parseOrderItems(addedOrderItems),
    [addedOrderItems]
  );

  const mappedExtraOrderItems = useMemo(
    () => parseOrderItems(extraOrderItems),
    [extraOrderItems]
  );

  // Classes to make error for dates
  const inputDateClassNames = cx('new-order__basic-date', {
    'new-order__basic-date--error': !!errors.date,
  });

  useEffect(() => {
    const fetchStock = async (clientId, details) => {
      // Get clients
      getAllClients()
        .then(({ data: { fetchedClients } }) => {
          setClientList(fetchedClients);

          // Get payments
          getAllPayments()
            .then(({ data: { fetchedPaymentOptions } }) => {
              const normalizedPayments = fetchedPaymentOptions.map(
                (payment) => ({
                  ...payment,
                  name: payment.label,
                })
              );
              setPaymentList(normalizedPayments);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
      try {
        const { data } = (await getStock(clientId)) ?? {};
        const updatedOrderItems = details.map(
          ({ comesFromClientDetailId, ...orderItem }) => {
            const detail =
              data.find(
                ({ comesFromClientDetailId: detailId }) =>
                  detailId === comesFromClientDetailId
              ) ?? {};
            const { availableStock = 0, term = null, supplierId = '' } = detail;
            return {
              ...orderItem,
              comesFromClientDetailId,
              availableStock,
              term,
              supplierId,
            };
          }
        );
        const updatedOrderItemsIds = updatedOrderItems.map(
          ({ comesFromClientDetailId }) => comesFromClientDetailId
        );
        const notUpdatedOrderItems = data.filter(
          ({ comesFromClientDetailId }) =>
            !updatedOrderItemsIds.includes(comesFromClientDetailId)
        );
        setOrderItems([...updatedOrderItems, ...notUpdatedOrderItems]);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchOrder = async () => {
      try {
        const { data } = (await getOrder(id)) ?? {};
        const {
          clientId,
          paymentTypeId,
          date,
          details = [],
          status,
          additionalDetail = {},
          proforma: targetProforma,
        } = data;
        const filteredDetails = details.filter(({ qualityId }) => !!qualityId);
        const {
          description = '',
          price: sellPrice = '',
          vat = 10,
        } = additionalDetail;
        setProforma(targetProforma);

        await fetchStock(clientId, filteredDetails);

        clientRef.current = clientId;

        reset({
          clientId,
          paymentTypeId,
          date,
          status,
          vat,
          description,
          sellPrice,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };

    fetchOrder();
  }, [id]);

  // useEffect(() => {
  //   if (!clientId || clientRef.current === clientId) return;
  //   setLoading(true);

  //   setFetching(true);

  //   clientRef.current = clientId;

  //   getStock(clientId)
  //     .then(({ status, data }) => {
  //       if (status === 200) {
  //         setOrderItems(data);
  //         setFetching(false);
  //         setLoading(false);
  //       }
  //     })
  //     .catch(({ response }) => {
  //       setOrderItems([]);
  //       setFetching(false);
  //       setLoading(false);
  //       createToast({ text: response.data.message, type: 'error' });
  //     });
  // }, [clientId]);

  // Check if order is valid
  const checkIfOrderIsValid = () => {
    const orderItemErrors = addedOrderItems.some(
      (item) => item.availableStock < 0
    );

    return orderItemErrors;
  };

  const handleAddMoreOrderItems = () => {
    setAddMoreOrderItems(false);
  };

  // Handler to update an order
  const editOrder = ({ description, sellPrice, vat, ...data }) => {
    if (checkIfOrderIsValid()) return;

    setSubmitting(true);

    const normalizedOrderItems = addedOrderItems.map(
      ({
        comesFromClientContractNumber,
        comesFromSupplierContractNumber,
        // availableStock,
        qualityName,
        supplierName,
        ...orderItem
      }) => ({
        ...orderItem,
        vat,
      })
    );

    const additionalDetail = { description, sellPrice, vat };

    const payload = {
      ...data,
      orderId: id,
      details: normalizedOrderItems,
      additionalDetail,
      proforma,
    };

    updateOrder(payload)
      .then(() => {
        createToast({
          text: 'Pedido editado con éxito',
          type: 'success',
        });
        navigate('/pedidos');
      })
      .catch(({ status, response }) => {
        setSubmitting(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: response.data.feedback, type: 'error' });
      });
  };

  // Handler to update order item
  const onUpdate = (itemId, { name, value }) => {
    const index = orderItems.findIndex(
      ({ comesFromAvailableStockId: id }) => id === itemId
    );
    const target = orderItems[index];
    const previousQuantity = target?.quantity || 0;
    const item = {
      ...target,
      [name]: value,
      availableStock: target.availableStock + previousQuantity - value,
    };

    setOrderItems([
      ...orderItems.slice(0, index),
      item,
      ...orderItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    editOrder(data);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back text="Volver a los pedidos" path="/pedidos" />

      <form className="new-order" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="new-order__header">
          {orderStatus === 'beforePayment'
            ? 'Editar pedido'
            : 'Visualizar pedido'}
        </h2>
        <div className="new-order__basic-data">
          <div className={inputDateClassNames}>
            <label>Selecciona una fecha:</label>
            <Controller
              control={control}
              name="date"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.date}
                    disabled={isDisabled}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          <div className="new-order__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Método de pago:
            </label>
            <Controller
              control={control}
              name="paymentTypeId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={paymentList}
                  placeholder="Método de pago"
                  value={value}
                  disabled={isDisabled}
                  error={errors.paymentTypeId}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-order__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Cliente:
            </label>
            <Controller
              control={control}
              name="clientId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clientList}
                  placeholder="Cliente"
                  value={value}
                  disabled
                  error={errors.clientId}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        {!!addedOrderItems.length && (
          <div className="new-order__order-lines">
            <h3 className="new-order__order-lines-header">
              Stock en el pedido actual:
            </h3>
            <Table
              id="added_order_items"
              rowsData={mappedAddedOrderItems}
              headCells={ORDER_QUALITIES_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={onUpdate}
              disabled={isDisabled}
              showFilters={true}
              defaultFiltersEnabled={false}
              stickyColumns={['quantity']}
            />
            <div className="new-order__basic-data new-order__order-lines-extra">
              <div className="new-order__basic-data-input">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="description"
                      placeholder="Descripción"
                      label="Descripción:"
                      defaultValue={value}
                      disabled={isDisabled}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="new-order__basic-data-input">
                <Controller
                  control={control}
                  name="sellPrice"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="sellPrice"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="Precio de venta"
                      label="Precio de venta:"
                      defaultValue={value}
                      disabled={isDisabled}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="new-order__basic-data-input">
                <label className="NewContractFormRightProviderSelectTitle">
                  IVA:
                </label>
                <Controller
                  control={control}
                  name="vat"
                  rules={{ required: 'El campo es obligatorio' }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      options={IVA_OPTIONS}
                      placeholder="IVA"
                      value={value}
                      canSearch={false}
                      disabled={isDisabled}
                      error={errors.vat}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="new-order__total-price">
              Precio total de compra: <span>{buyTotalPrice.toFixed(2)}€</span>
            </div>
            <div className="new-order__total-price">
              Precio total de venta: <span>{sellTotalPrice.toFixed(2)}€</span>
            </div>
            <div className="new-order__total-price">
              Utilidad total: <span>{totalUtility.toFixed(2)}€</span>
            </div>
          </div>
        )}
        {!!addMoreOrderItems.length && !isDisabled && (
          <div className="new-order__extra-order-lines">
            <Button
              type="button"
              update
              text="Añadir más líneas de pedido"
              click={handleAddMoreOrderItems}
            />
          </div>
        )}
        {!!extraOrderItems.length && !addMoreOrderItems.length && (
          <div className="new-order__extra-table">
            <h3>
              Más stock disponible (edita aquí si quieres añadir stock al
              pedido):
            </h3>
            <Table
              id="extra_order_items"
              rowsData={mappedExtraOrderItems}
              headCells={ORDER_QUALITIES_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={onUpdate}
              disabled={isDisabled}
              showFilters={true}
              defaultFiltersEnabled={false}
              stickyColumns={['quantity']}
            />
          </div>
        )}
        <div className="new-contract__footer">
          {!isDisabled && (
            <Button
              type="submit"
              disabled={fetching}
              loading={submitting}
              update
              text="Editar pedido"
            />
          )}
        </div>
      </form>
    </>
  );
};

export default Order;
