import { useCallback } from 'react';
/** Styles */
import styles from './Checkbox.module.scss';

const Checkbox = ({ id, value = false, children, onChange }) => {
  const handleChange = useCallback(
    ({ target: { checked } }) => {
      onChange(checked);
    },
    [onChange]
  );

  return (
    <div className={styles.Checkbox}>
      <input
        id={id}
        name={id}
        type="checkbox"
        className={styles.CheckboxCheck}
        value={value}
        defaultChecked={value}
        onChange={handleChange}
      />
      <label className={styles.CheckboxLabel} htmlFor={id}>
        <span className={styles.CheckboxBox} />
        <span className={styles.CheckboxText}>{children}</span>
      </label>
    </div>
  );
};

export default Checkbox;
