import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Back from 'components/Back';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Table from 'components/Table';
import Select from 'components/Select';
/** Services */
import { getOrderByBatches, registerNetWeights } from 'services/orders';
/** Constants */
import {
  ORDER_QUALITIES_REAL_WEIGHT_HEAD_CELLS,
  BATCHES_REAL_WEIGHT_HEAD_CELLS,
} from 'constants/orders';
/** Styles */
import './RegisterNetWeights.scss';

const LINE = 'LINE';
const BULK = 'BULK';

const parseBatches = (orders) => {
  let batches = [];

  orders.forEach((order) => {
    const index = batches.findIndex(
      ({ batch = null }) => batch === order.batch
    );

    if (index === -1) {
      batches.push({
        id: order.batch,
        batch: order.batch,
        expectedWeight: order.expectedWeight,
        qualityName: order.qualityName,
        units: order.units,
        netWeight: order.netWeight || order.expectedWeight || 0,
      });
    } else {
      const targetBatch = batches[index];
      const {
        id,
        batch,
        expectedWeight,
        qualityName,
        units,
        netWeight = 0,
      } = targetBatch;
      batches[index] = {
        id,
        batch,
        expectedWeight: expectedWeight + order.expectedWeight,
        qualityName:
          qualityName === order.qualityName ? qualityName : 'Varias calidades',
        units: units + order.units,
        netWeight: order?.netWeight ? netWeight + order.netWeight : netWeight,
      };
    }
  });

  return batches;
};

const apportRealWeights = (details, batches) => {
  const newDetails = [...details];

  batches.forEach(({ batch, netWeight, units }) => {
    const weightPerUnit = netWeight / units;

    newDetails.forEach((detail) => {
      if (detail.batch === batch) {
        detail.netWeight = detail.units * weightPerUnit;
      }
    });
  });

  return newDetails;
};

const RegisterNetWeights = () => {
  const [showSelectScreen, setShowSelectScreen] = useState(true);
  const [hasOnlyOneInvoice, setHasOnlyOneInvoice] = useState(true);
  const [availableInvoices, setAvailableInvoices] = useState([]);
  const [targetInvoice, setTargetInvoice] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [orderItems, setOrderItems] = useState([]);
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [hasStock, setHasStock] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ defaultValues: { vat: 10 } });

  const handleSelectTargetInvoice = (id) => {
    setTargetInvoice(id);
  };

  const handleSubmitTargetInvoice = () => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const { data } =
          (await getOrderByBatches(id, `?invoiceId=${targetInvoice}`)) ?? {};
        setHasOnlyOneInvoice(true);
        const availableData = data.filter(({ units }) => units > 0);
        if (!availableData?.length) {
          setHasStock(false);
        }
        setOrderItems(
          availableData.map((order) => ({
            ...order,
            netWeight: order.expectedWeight,
          }))
        );
        setBatches(parseBatches(data));
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchOrder();
  };

  const handleSelectMode = (mode) => {
    setEditMode(mode);
    setShowSelectScreen(false);
  };

  const mappedOrderItems = useMemo(
    () =>
      orderItems.map(
        (
          {
            batch = '',
            qualityName = '',
            clientContractNumber = '',
            supplierContractNumber = '',
            units = 0,
            expectedWeight = 0,
            netWeight = 0,
          },
          index
        ) => {
          return {
            ...(batch ? { batch } : { batch: '' }),
            ...(qualityName ? { qualityName } : { qualityName: '' }),
            ...(clientContractNumber
              ? { clientContractNumber }
              : { clientContractNumber: '' }),
            ...(supplierContractNumber
              ? { supplierContractNumber }
              : { supplierContractNumber: '' }),
            ...(units ? { units } : { units: 0 }),
            ...(expectedWeight ? { expectedWeight } : { expectedWeight: 0 }),
            ...(netWeight ? { netWeight } : { netWeight: expectedWeight }),
            ...{ id: index },
          };
        }
      ),
    [orderItems]
  );

  const mappedBatches = useMemo(
    () =>
      batches.map(
        (
          {
            batch = '',
            qualityName = '',
            units = 0,
            expectedWeight = 0,
            netWeight = 0,
          },
          index
        ) => {
          return {
            ...(batch ? { batch } : { batch: '' }),
            ...(qualityName ? { qualityName } : { qualityName: '' }),
            ...(units ? { units } : { units: 0 }),
            ...(expectedWeight ? { expectedWeight } : { expectedWeight: 0 }),
            ...(netWeight ? { netWeight } : { netWeight: expectedWeight }),
            ...{ id: index },
          };
        }
      ),
    [batches]
  );

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        setLoading(true);
        const { data } = (await getOrderByBatches(id)) ?? {};
        if (data?.availableInvoices) {
          setHasOnlyOneInvoice(false);
          setAvailableInvoices(data.availableInvoices);
        } else {
          const availableData = data.filter(({ units }) => units > 0);
          if (!availableData?.length) {
            setHasStock(false);
          }
          setOrderItems(
            availableData.map((order) => ({
              ...order,
              netWeight: order.expectedWeight,
            }))
          );
          setBatches(parseBatches(data));
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchOrder();
  }, [id]);

  // Handler to update an order
  const editOrder = () => {
    setSubmitting(true);

    const payload = {
      orderId: id,
      details: orderItems.map(
        ({ netWeight, stockBoughtId, expectedWeight }) => ({
          netWeight,
          stockBoughtId,
          expectedWeight,
        })
      ),
    };

    registerNetWeights(payload)
      .then(() => {
        createToast({
          text: 'Pesos reales registrados',
          type: 'success',
        });
        navigate('/pedidos');
      })
      .catch(({ status, response }) => {
        setSubmitting(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: response.data.feedback, type: 'error' });
      });
  };

  // Handler to update order item
  const onUpdate = (itemId, { name, value }) => {
    const target = orderItems[itemId];
    const item = {
      ...target,
      [name]: value,
    };

    setOrderItems([
      ...orderItems.slice(0, itemId),
      item,
      ...orderItems.slice(itemId + 1),
    ]);
  };

  const onUpdateBatch = (itemId, { name, value }) => {
    const target = batches[itemId];
    const item = {
      ...target,
      [name]: value,
    };

    setBatches(
      parseBatches([
        ...batches.slice(0, itemId),
        item,
        ...batches.slice(itemId + 1),
      ])
    );
  };

  const handleApportion = () => {
    setLoading(true);
    setOrderItems(apportRealWeights(orderItems, batches));
    setEditMode(LINE);
    setLoading(false);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    editOrder();
  };

  if (loading) {
    return <Loader />;
  }

  if (showSelectScreen && !hasOnlyOneInvoice) {
    return (
      <>
        <Back text="Volver a los pedidos" path="/pedidos" />
        <div className="new-order">
          <h2 className="new-order__header">Registrar pesos reales</h2>
          <div className="net-weights__options-container">
            <div className="net-weights__options-container__invoice-selector">
              <div className="net-weights__options-container__invoice-selector__header">
                <h3>
                  Hay varias facturas de proveedor asociadas a este pedido
                </h3>
                <p>
                  Por favor, antes de continuar, selecciona en qué factura
                  quieres registrar los pesos reales
                </p>
              </div>
              <div className="net-weights__options-container__invoice-selector__options">
                <div className="net-weights__options-container__invoice-selector__options--select">
                  <Select
                    options={availableInvoices}
                    textProperty="invoiceNumber"
                    valueProperty="id"
                    onChange={handleSelectTargetInvoice}
                    placeholder="Selecciona la factura del proveedor"
                    value={targetInvoice}
                  />
                </div>
                <div className="net-weights__options-container__invoice-selector__options--btn">
                  <Button
                    update
                    text="Seleccionar factura"
                    click={handleSubmitTargetInvoice}
                    disabled={!targetInvoice}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (showSelectScreen) {
    return (
      <>
        <Back text="Volver a los pedidos" path="/pedidos" />
        <div className="new-order">
          <h2 className="new-order__header">Registrar pesos reales</h2>
          <div className="net-weights__options-container">
            {hasStock ? (
              <div className="net-weights__options-container__mode-selector">
                <div className="net-weights__options-container__mode-selector--line-btn">
                  <Button
                    update
                    text="Registrar por línea"
                    click={() => handleSelectMode(LINE)}
                  />
                </div>
                <div className="net-weights__options-container__mode-selector--bulk-btn">
                  <Button
                    update
                    text="Prorratear por lote"
                    click={() => handleSelectMode(BULK)}
                  />
                </div>
              </div>
            ) : (
              <p>Esta factura ya tiene los pesos reales asignados</p>
            )}
          </div>
        </div>
      </>
    );
  }

  if (editMode === LINE) {
    return (
      <>
        <Back text="Volver a los pedidos" path="/pedidos" />
        <form className="new-order" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="new-order__header">Registrar pesos reales</h2>
          {!!orderItems.length && (
            <Table
              rowsData={mappedOrderItems}
              headCells={ORDER_QUALITIES_REAL_WEIGHT_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={onUpdate}
              stickyColumns={['netWeight']}
            />
          )}
          <div className="new-contract__footer">
            <Button
              type="submit"
              loading={submitting}
              update
              text="Registrar pesos reales"
            />
          </div>
        </form>
      </>
    );
  }

  if (editMode === BULK) {
    return (
      <>
        <Back text="Volver a los pedidos" path="/pedidos" />
        <h2 className="new-order__header">Registrar pesos reales</h2>
        {!!orderItems.length && (
          <Table
            rowsData={mappedBatches}
            headCells={BATCHES_REAL_WEIGHT_HEAD_CELLS}
            ignoreColumn="id"
            targetItem="id"
            showDelete={false}
            showSearchBar={false}
            onCellValueChange={onUpdateBatch}
            stickyColumns={['netWeight']}
          />
        )}
        <div className="new-contract__footer">
          <Button update text="Prorratear pesos" click={handleApportion} />
        </div>
      </>
    );
  }
};

export default RegisterNetWeights;
