import axiosWrapper, { baseUrl } from './core';

const INVOICES_URL = '/api/suppliers-invoices';

export const getSuppliersInvoices = () =>
  axiosWrapper.get(`${baseUrl}${INVOICES_URL}/get-all-supplier-invoices`);

export const getSupplierInvoices = (invoiceId) =>
  axiosWrapper.get(
    `${baseUrl}${INVOICES_URL}/get-all-supplier-invoices/${invoiceId}`
  );

export const getSupplierInvoice = (invoiceId) =>
  axiosWrapper.get(
    `${baseUrl}${INVOICES_URL}/get-supplier-invoice-by-id/${invoiceId}`
  );

export const createSupplierInvoice = (params) =>
  axiosWrapper.put(`${baseUrl}${INVOICES_URL}/create-supplier-invoice`, params);

export const updateSupplierInvoice = (params) =>
  axiosWrapper.patch(
    `${baseUrl}${INVOICES_URL}/update-supplier-invoice`,
    params
  );

export const removeSupplierInvoices = (id) =>
  axiosWrapper.delete(`${baseUrl}${INVOICES_URL}/delete-supplier-invoice`, {
    id,
  });

export const getSuppliersBillInvoices = () =>
  axiosWrapper.get(`${baseUrl}${INVOICES_URL}/get-all-bill-invoices`);

export const getSupplierBillInvoice = (invoiceId) =>
  axiosWrapper.get(
    `${baseUrl}${INVOICES_URL}/get-supplier-bill-invoice-by-id/${invoiceId}`
  );

export const createSupplierBillInvoice = (params) =>
  axiosWrapper.put(
    `${baseUrl}${INVOICES_URL}/create-supplier-bill-invoice`,
    params
  );

export const updateSupplierBillInvoice = (params) =>
  axiosWrapper.patch(
    `${baseUrl}${INVOICES_URL}/update-supplier-bill-invoice`,
    params
  );

export const removeSupplierBillInvoices = (id) =>
  axiosWrapper.delete(
    `${baseUrl}${INVOICES_URL}/delete-supplier-bill-invoice`,
    {
      id,
    }
  );
