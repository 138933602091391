import { useState, useEffect } from 'react';
/** Components */
import Loader from 'components/Loader';
import ReplacementList from './ReplacementList';
/** Services */
import { getAllAssignments } from 'services/contracts';
/** Utils */
import { createToast } from 'utils/toast';
/** Styles */
import './Replacements.scss';

const Replacements = () => {
  const [replacementsList, setReplacementsList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllAssignments()
      .then(({ status, data: { contracts } }) => {
        if (status === 200) {
          setReplacementsList(contracts);
        }
        setLoading(false);
      })
      .catch((err) => {
        const feedback = 'Algo ha salido mal';
        setLoading(false);
        createToast({
          text: feedback,
          type: 'error',
        });
      }, []);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="replacements">
      <div className="replacements__header">
        <h1 className="replacements__header-title">Reposición de stock</h1>
      </div>
      <div className="replacements__list">
        <ReplacementList replacements={replacementsList} />
      </div>
    </div>
  );
};

export default Replacements;
