import { useState, useEffect } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
/** Services */
import { setNewPassword, checkToken } from 'services/auth.js';
/** Constants */
import {
  CHANGE_PASSWORD_INITIAL_DATA,
  CHANGE_PASSWORD_FORM,
} from 'constants/auth';
/** Styles */
import styles from './ResetPasswordForm.module.scss';

const ResetPasswordForm = () => {
  const [formData, setFormData] = useState(CHANGE_PASSWORD_INITIAL_DATA);
  const [clientToken, setClientToken] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (!searchParams.get('token') && !searchParams.get('email')) {
      // window.location.replace('/');
      return;
    }

    setClientToken(searchParams.get('token'));
    setUserEmail(searchParams.get('email'));

    checkToken(searchParams.get('token'))
      .then(({ status, message }) => {
        if (status === 204) {
          // window.location.replace('/');
          return;
        }

        createToast({ text: message, type: 'error' });
      })
      .catch(() => {
        createToast({ text: 'Ha ocurrido un error', type: 'error' });
      });
  }, []);

  const changeHandler = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formData.password !== formData.passwordRepeat) {
      createToast({
        text: 'Las contraseñas tienen que coincidir',
        type: 'error',
      });
      return;
    }

    if (!formData.password.length || !formData.passwordRepeat.length) {
      createToast({
        text: 'No puedes introducir una contraseña vacia',
        type: 'error',
      });
      return;
    }

    setNewPassword({
      email: userEmail,
      token: clientToken,
      password: formData.password,
    })
      .then(({ status }) => {
        if (status === 200) {
          setFormData(CHANGE_PASSWORD_INITIAL_DATA);
          createToast({
            text: 'Contraseña modificada',
            type: 'success',
            duration: 2500,
          });
          window.location.replace('/');
        }
      })
      .catch(() => {
        createToast({
          text: 'Algo ha ido mal, intentalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <div className={styles.FormContainer}>
      <h2 className={styles.FormTitle}>Recuperar contraseña</h2>
      <form
        id="reset-password-form"
        className={styles.FormWrapper}
        onSubmit={submitHandler}
      >
        {CHANGE_PASSWORD_FORM.map(({ id, label, type }) => (
          <div className={styles.FormField} key={id}>
            <Input
              id={id}
              label={label}
              name={id}
              placeholder={label}
              type={type}
              onChange={changeHandler}
            />
          </div>
        ))}
        <div className={styles.FormButtonWrapper}>
          <Button text="Recuperar" />
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
