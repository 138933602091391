import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Services */
import { removeSupplierContracts } from 'services/contracts';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { CONTRACT_HEAD_CELLS } from 'constants/providers';
/** Styles */
import styles from './ContractList.module.scss';

const ContractList = ({ contracts = [], reloadContracts }) => {
  const navigate = useNavigate();

  const mappedClientContracts = contracts.map(
    ({ contractNumber = '', contractDate = '', Supplier, id }) => {
      return {
        ...(contractNumber
          ? { contractNumber }
          : { contractNumber: 'Sin nº de contrato' }),
        ...(Supplier
          ? { SupplierId: Supplier.name }
          : { SupplierId: 'Sin proveedor' }),
        ...(contractDate ? { contractDate } : { contractDate: 'Sin fecha' }),
        // metadata: { fileId: id, key: 'supplierContract' },
        ...(id && { id }),
      };
    }
  );

  const handleSelectContract = (contractId) => {
    navigate(`/proveedores/contratos/${contractId}`);
  };

  const deleteHandler = (contractsIds) => {
    removeSupplierContracts(contractsIds)
      .then(({ status }) => {
        if (status === 204) {
          createToast({
            text: 'Contrato eliminado con éxito',
            type: 'success',
            duration: 2500,
          });
          reloadContracts(contractsIds);
        }
      })
      .catch(() => {
        createToast({
          text: 'No se ha podido eliminar el contrato, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {contracts.length !== 0 ? (
        <Table
          rowsData={mappedClientContracts}
          headCells={CONTRACT_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectContract}
          deleteHandler={(value) => deleteHandler(value)}
          orderBy="id"
        />
      ) : (
        <div className={styles.EmptyState}>No hay datos.</div>
      )}
    </>
  );
};

export default ContractList;
