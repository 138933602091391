import axiosWrapper, { baseUrl } from './core';

const BASE_URL = '/public/auth';

export const signIn = (params) =>
  axiosWrapper.post(`${baseUrl}${BASE_URL}/login`, params);

export const passwordRecovery = (params) =>
  axiosWrapper.post(`${baseUrl}${BASE_URL}/get-password-recovery-link`, params);

export const setNewPassword = (params) =>
  axiosWrapper.post(`${baseUrl}${BASE_URL}/set-password`, params);

export const checkToken = (token) =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/check-token-legitimacy/${token}`);

export const createUser = (params) =>
  axiosWrapper.post(`${baseUrl}${BASE_URL}/set-new-user-data`, params);
