import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Back from 'components/Back';
import Select from 'components/Select';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Table from 'components/Table';
/** Services */
import {
  getSupplierBillInvoice,
  updateSupplierBillInvoice,
} from 'services/invoices';
import { getAllProviders } from 'services/providers';
import { getSuppliersContracts } from 'services/contracts';
import { getSupplierOrders } from 'services/orders';
/** Constants */
import {
  BILL_INVOICES_EDIT_LINE_ITEMS_HEAD_CELLS,
  IVA_OPTIONS,
} from 'constants/providers';
/** Styles */
import './BillInvoice.scss';

const BillInvoice = () => {
  const [providersList, setProvidersList] = useState([]);
  const [supplierContractList, setSupplierContractList] = useState([]);
  const [orders, setOrders] = useState([]);

  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ defaultValues: { vat: 10, date: new Date() } });

  const mappedLineItems = useMemo(
    () =>
      lineItems.map(
        ({
          id,
          qualityName = '',
          units = 0,
          packCapacity = 0,
          pricePerKg,
          batch,
          expectedWeight,
          realWeight,
        }) => {
          return {
            ...(qualityName ? { qualityName } : { qualityName: 'Sin calidad' }),
            ...(units ? { units } : { units: 0 }),
            ...(packCapacity ? { packCapacity } : { packCapacity: 0 }),
            ...(pricePerKg ? { pricePerKg } : { pricePerKg: 0 }),
            ...(expectedWeight ? { expectedWeight } : { expectedWeight: 0 }),
            ...(realWeight ? { realWeight } : { realWeight: 0 }),
            ...(batch ? { batch } : { batch: undefined }),
            ...(id && { id }),
          };
        }
      ),
    [lineItems]
  );

  useEffect(() => {
    const fetchSuppliers = async () => {
      const data = await getAllProviders();
      const {
        data: { fetchedSuppliers },
      } = data;
      setProvidersList(fetchedSuppliers);
    };

    const fetchSupplierContracts = async (supplier) => {
      const data = await getSuppliersContracts();
      const {
        data: { suppliersContracts },
      } = data;
      // const filteredContracts =
      //   suppliersContracts.filter(
      //     (contract) => contract?.supplier?.id === supplier
      //   ) ?? [];
      // setSupplierContractList(filteredContracts);
      setSupplierContractList(suppliersContracts);
    };

    const fetchSupplierOrders = async (supplierId) => {
      const { data } = await getSupplierOrders(supplierId);
      const orders = data.map(({ orderId: id, clientName, ...order }) => ({
        ...order,
        id,
        clientName: `${id} - ${clientName}`,
      }));
      setOrders(orders);
    };

    const fetchInvoice = async () => {
      try {
        const { data } = (await getSupplierBillInvoice(id)) ?? {};
        const {
          id: invoiceId,
          supplierId,
          supplierContractId,
          invoiceNumber,
          orderId,
          vat,
          date,
          basePrice,
          totalPrice,
          details,
        } = data;
        await fetchSuppliers();
        await fetchSupplierContracts(supplierId);
        await fetchSupplierOrders(supplierId);

        const filteredDetails = details.filter(({ qualityId }) => qualityId);
        // const additionalConcepts = details.find(({ qualityId }) => !qualityId);

        console.log(999, data);

        reset({
          invoiceId,
          supplierId,
          // supplierContractId: supplierContractList.find(
          //   ({ id }) => id === supplierContractId
          // ),
          supplierContractId: supplierContractId,
          invoiceNumber,
          orderId,
          vat,
          date,
          basePrice,
          totalPrice,
          // ...(additionalConcepts && {
          //   description: additionalConcepts.description,
          //   price: additionalConcepts.totalPrice,
          // }),
        });

        console.log(4444, filteredDetails);

        setLineItems(
          filteredDetails.map((detail) => {
            const { batches: batch, expectedWeight, ...rest } = detail;
            return {
              batch,
              realWeight: expectedWeight,
              expectedWeight,
              ...rest,
            };
          })
        );
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  // Handler to update an invoice
  const editInvoice = ({
    invoiceId,
    basePrice,
    totalPrice,
    description,
    price,
    ...data
  }) => {
    setSubmitting(true);

    const additionalConcepts = { description, price: parseFloat(price) };

    console.log(111, lineItems);

    const normalizedLineItems = lineItems.map(
      ({
        qualityId,
        batch,
        units,
        packCapacity,
        pricePerKg,
        expectedWeight,
        realWeight,
      }) => ({
        ...(qualityId && { qualityId }),
        batches: batch,
        units,
        packCapacity,
        pricePerKg,
        expectedWeight,
        realWeight,
      })
    );

    const payload = {
      id: invoiceId,
      ...data,
      basePrice: parseFloat(basePrice),
      totalPrice: parseFloat(totalPrice),
      details: [...normalizedLineItems /* additionalConcepts */],
    };

    updateSupplierBillInvoice(payload)
      .then(() => {
        createToast({
          text: 'Factura de proveedor actualizada con éxito',
          type: 'success',
        });
        navigate('/proveedores/abonos');
      })
      .catch(({ status }) => {
        setSubmitting(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  };

  // Handler to update order item
  const onUpdate = (itemId, { name, value }) => {
    const targetItem = lineItems.find(({ id }) => id === itemId);

    let parsedValue = value;
    if (name === 'batch' && value?.length === 1 && !value?.[0]?.quantity) {
      parsedValue = [
        {
          batch: value?.[0]?.batch,
          quantity: targetItem.units,
        },
      ];
    }

    if (name === 'batch' && !targetItem.units) {
      createToast({
        text: 'Guarda una cantidad antes de configurar sus lotes',
        type: 'error',
      });
      return;
    }

    const index = lineItems.findIndex(({ id }) => id === itemId);
    const target = lineItems[index];
    if (name === 'units') {
      delete target?.batch;
    }
    const item = {
      ...target,
      [name]: parsedValue,
    };

    setLineItems([
      ...lineItems.slice(0, index),
      item,
      ...lineItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    editInvoice(data);
  };

  // Classes to make error for dates
  const inputDateClassNames = cx('bill-invoice__basic-date', {
    'bill-invoice__basic-date--error': !!errors.date,
  });

  console.log(1111, lineItems);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back
        text="Volver a las facturas de abono de proveedor"
        path="/proveedores/abonos"
      />

      <form className="bill-invoice" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="bill-invoice__header">
          Editar factura de abonos del proveedor
        </h2>
        <div className="bill-invoice__basic-data-custom">
          <div className="bill-invoice__basic-data-input">
            <label>Proveedor:</label>
            <Controller
              control={control}
              name="supplierId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={providersList}
                  placeholder="Proveedor"
                  value={value}
                  error={errors.supplierId}
                  onChange={onChange}
                  disabled
                />
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          <div className="bill-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Contrato del proveedor:
            </label>
            <Controller
              control={control}
              name="supplierContractId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={supplierContractList}
                  placeholder="Proveedor"
                  textProperty="contractNumber"
                  value={value}
                  error={errors.supplierContractId}
                  onChange={onChange}
                  disabled
                />
              )}
            />
          </div>
          <div className="bill-invoice__basic-data-input">
            <Controller
              control={control}
              name="invoiceNumber"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  key="invoiceNumber"
                  name="invoiceNumber"
                  placeholder="Nº Factura"
                  label="Nº Factura"
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="bill-invoice__basic-data-custom">
          <div className={inputDateClassNames}>
            <label>Selecciona una fecha:</label>
            <Controller
              control={control}
              name="date"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.date}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          {/* <div className="bill-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              IVA:
            </label>
            <Controller
              control={control}
              name="vat"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={IVA_OPTIONS}
                  placeholder="IVA"
                  value={value}
                  canSearch={false}
                  error={errors.vat}
                  onChange={onChange}
                />
              )}
            />
          </div> */}
          <div className="bill-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Pedido:
            </label>
            <Controller
              control={control}
              name="orderId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={orders}
                  placeholder="Pedido"
                  textProperty="clientName"
                  value={value}
                  error={errors.orderId}
                  onChange={onChange}
                  disabled
                />
              )}
            />
          </div>
        </div>
        {/* <div className="bill-invoice__basic-data-custom">
          <div className="bill-invoice__basic-data-input">
            <Controller
              control={control}
              name="basePrice"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  key="basePrice"
                  name="basePrice"
                  placeholder="Precio base"
                  label="Precio base"
                  type="number"
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="bill-invoice__basic-data-input">
            <Controller
              control={control}
              name="totalPrice"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  key="totalPrice"
                  name="totalPrice"
                  placeholder="Precio total"
                  type="number"
                  label="Precio total"
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div> */}
        {!!lineItems.length && (
          <>
            <Table
              rowsData={mappedLineItems}
              headCells={BILL_INVOICES_EDIT_LINE_ITEMS_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              stickyColumns={['batches']}
              onCellValueChange={onUpdate}
            />
            {/* <div className="bill-invoice__basic-data bill-invoice__order-lines-extra">
              <div className="bill-invoice__basic-data-input">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="description"
                      placeholder="Descripción"
                      label="Descripción:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="bill-invoice__basic-data-input">
                <Controller
                  control={control}
                  name="price"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="price"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="Precio"
                      label="Precio:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div> */}
          </>
        )}
        <div className="new-contract__footer">
          <Button
            type="submit"
            update
            loading={submitting}
            text="Actualizar factura de proveedor"
          />
        </div>
      </form>
    </>
  );
};

export default BillInvoice;
