import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
import Loader from 'components/Loader';
/** Services */
import { getOrders } from 'services/orders';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { ORDERS_HEAD_CELLS } from 'constants/orders';
/** Styles */
import './Orders.scss';

const Orders = ({ provider }) => {
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrders()
      .then(({ data }) => {
        setOrderList(
          data.filter((order) =>
            order.details.some((detail) => detail.supplierId === provider.id)
          )
        );
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen facturas de proveedores',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  const mappedOrders = orderList.map(
    ({
      clientName = '',
      date = '',
      paymentTypeName = '',
      status,
      orderId: id,
    }) => {
      return {
        orderId: id,
        ...(clientName ? { clientName } : { clientName: 'Sin cliente' }),
        ...(date ? { date } : { date: 'Sin fecha' }),
        ...(paymentTypeName
          ? { paymentTypeName }
          : { paymentTypeName: 'Sin método de pago' }),
        metadata: { fileId: id, key: 'supplierOrder' },
        status,
        id,
      };
    }
  );

  const handleSelectInvoice = (orderId) => {
    navigate(`/pedidos/${orderId}`);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {orderList.length !== 0 ? (
        <Table
          rowsData={mappedOrders}
          headCells={ORDERS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectInvoice}
        />
      ) : (
        <div className="EmptyState">No hay pedidos.</div>
      )}
    </>
  );
};

export default Orders;
