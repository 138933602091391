import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Services */
import { deleteAddress } from 'services/clients.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Styles */
import styles from './AddressData.module.scss';

const AddressCard = ({
  id,
  name,
  city,
  country,
  zipCode,
  addressFirstLine,
  addressSecondLine,
  setAddress,
  onSubmit,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [safeDelete, setSafeDelete] = useState(false);

  const editAddressHandler = () => {
    setAddress(id);
    setShowOptions();
  };

  const removeContactHandler = () => {
    if (safeDelete) {
      deleteAddress(id)
        .then(({ status }) => {
          if (status === 204) {
            createToast({
              text: 'Datos de envío eliminados con éxito',
              type: 'success',
              duration: 2500,
            });
            onSubmit();
          }
        })
        .catch(({ status }) => {
          if (status === 401) {
            createToast({
              text: 'No tienes suficientes permisos',
              type: 'error',
            });
          }

          if (status === 500) {
            createToast({ text: 'Algo ha salido mal', type: 'error' });
          }
        });

      setShowOptions();
      return;
    }

    setSafeDelete(true);
    setTimeout(() => {
      setSafeDelete(false);
    }, 3000);
  };

  return (
    <div className={styles.ContactItem}>
      <div className={styles.ContactItemHeader}>
        <h3>{name}</h3>
        <FontAwesomeIcon
          icon="ellipsis-v"
          className={styles.EllipsisIcon}
          onClick={() => setShowOptions(!showOptions)}
        />
        {showOptions && (
          <div className={styles.ContactItemOptions}>
            <p onClick={editAddressHandler}>Editar</p>
            <p onClick={removeContactHandler}>
              {safeDelete ? 'Confirmar' : 'Eliminar'}
            </p>
          </div>
        )}
      </div>
      <div className={styles.ContactItemData}>
        <div className={styles.ContactItemAddres}>
          <p>{addressFirstLine} </p>
          {addressSecondLine && <p>{addressSecondLine}</p>}
          <p>
            {city}, {country}, {zipCode}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
