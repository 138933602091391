export const USER_INITIAL_DATA = {
  name: '',
  surnames: '',
  email: '',
  password: '',
  token: '',
};

export const NEW_USER_INITIAL_DATA = {
  email: '',
};

export const NEW_USER_FORM = {
  id: 'email',
  label: 'Email',
  type: 'email',
  placeholder: 'angel@ejemplo.com',
};

export const MODIFIY_USER_FORM_DATA = {
  id: '',
  name: '',
  surnames: '',
  email: '',
};

export const MODIFIY_USER_FORM = [
  {
    id: 'name',
    label: 'Nombre',
    type: 'text',
    placeholder: 'Angel',
  },
  {
    id: 'surnames',
    label: 'Apellidos',
    type: 'text',
    placeholder: 'Apellidos',
  },
  {
    id: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'angel@ejemplo.com',
  },
];

export const UPDATE_USER_FORM = [
  {
    id: 'name',
    label: 'Nombre',
    type: 'text',
    placeholder: 'Angel',
  },
  {
    id: 'surnames',
    label: 'Apellidos',
    type: 'text',
    placeholder: 'Apellidos',
  },
  {
    id: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'angel@ejemplo.com',
  },
  {
    id: 'password',
    label: 'Contraseña',
    type: 'password',
    placeholder: '8-12 Carácteres',
  },
];

export const USER_HEAD_CELLS = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nombre',
  },
  {
    id: 'surname',
    numeric: false,
    disablePadding: false,
    label: 'Apellidos',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Correo electrónico',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Rol',
  },
];

export const USER_PERMISSIONS_FORM = [
  {
    name: 'Usuarios',
    id: 'users',
    roles: {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    },
  },
  {
    name: 'Calidades',
    id: 'qualities',
    roles: {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    },
  },
  {
    name: 'Clientes',
    id: 'clients',
    roles: {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    },
  },
  {
    name: 'Proveedores',
    id: 'suppliers',
    roles: {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    },
  },
  {
    name: 'Contratos',
    id: 'contracts',
    roles: {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    },
  },
  {
    name: 'Pedidos',
    id: 'orders',
    roles: {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
    },
  },
];

export const USER_PERMISSIONS_FORM_DATA = {
  users: {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  qualities: {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  clients: {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  suppliers: {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  contracts: {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
  orders: {
    canCreate: false,
    canRead: false,
    canUpdate: false,
    canDelete: false,
  },
};

export const USER_PERMISSION_MAP = {
  canCreate: 'Puede crear',
  canRead: 'Puede leer',
  canUpdate: 'Puede actualizar',
  canDelete: 'Puede eliminar',
};
