import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import cx from 'classnames';
/** Components */
import Back from 'components/Back';
import Select from 'components/Select';
import Button from 'components/Button';
import Switch from 'components/Switch';
import Table from 'components/Table';
/** Services */
import { getAllClients } from 'services/clients';
import {
  assignStock,
  getClientContractsByClientId,
  v2GetClientContractById,
} from 'services/contracts';
import { getAllPayments } from 'services/payments';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Constants */
import {
  NEW_TRANSFER_HEAD_CELLS_ORIGIN,
  NEW_TRANSFER_EDIT_HEAD_CELLS,
} from 'constants/transfers';
/** Styles */
import './NewTransfer.scss';

const NewTransfer = () => {
  const [clientList, setClientList] = useState([]);
  const [originContracts, setOriginContracts] = useState([]);
  const [originContractData, setOriginContractData] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [targetContractData, setTargetContractData] = useState([]);
  const [qualityName, setQualityName] = useState('');
  const [orderItems, setOrderItems] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [target, setTarget] = useState({});
  const [sendAddresses, setSendAddresses] = useState([]);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    defaultValues: { term: new Date() },
  });

  const originClient = watch('originClient');
  const originContract = watch('originContract');
  const targetClient = watch('targetClient');
  const sendAddress = watch('sendAddress');

  const contractName = useMemo(() => {
    if (!originContract) return;
    return originContracts.find(({ id }) => id === originContract)
      .contractNumber;
  }, [originContract]);

  const targetClientName = useMemo(() => {
    if (!targetClient) return;
    return clientList.find(({ id }) => id === targetClient).name;
  }, [targetClient]);

  const clientListExcludingActive = useMemo(() => {
    return clientList.filter(({ id }) => id !== originClient);
  }, [clientList, originClient]);

  const mapperOriginContractData = originContractData.map(
    ({
      buyPrice = 'Sin precio',
      term = 'Sin fecha de retiro',
      stockAvailable,
      stockAvailableId: id,
    }) => {
      return {
        qualityName,
        buyPrice,
        term,
        stock: stockAvailable || 'Error contando stock',
        id,
      };
    }
  );

  const mapperTargetContractData = originContractData.map(
    ({ buyPrice = 'Sin precio', stockAvailableId: id, term }) => {
      return {
        qualityName,
        buyPrice,
        term,
        sellPrice: 0,
        boughtUnits: 0,
        id,
      };
    }
  );

  // Classes to make error for dates
  const inputDateClassNames = cx('new-transfer__basic-date', {
    'new-transfer__basic-date--error': !!errors.date,
  });

  useEffect(() => {
    getAllClients()
      .then(({ status, data: { fetchedClients } }) => {
        if (status === 200) {
          setClientList(fetchedClients);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    getAllPayments()
      .then(({ status, data: { fetchedPaymentOptions } }) => {
        if (status === 200) {
          const normalizedPayments = fetchedPaymentOptions.map((payments) => ({
            ...payments,
            name: payments.label,
          }));
          setPaymentList(normalizedPayments);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!originClient) return;

    getClientContractsByClientId(originClient, '?creating=1')
      .then(({ data: { clientContracts } }) => {
        const mappedContracts = clientContracts.map((contract) => ({
          ...contract,
          name: contract.contractNumber,
        }));
        setOriginContracts(mappedContracts);
        setQualityName('');
        setOriginContractData([]);
        setTargetContractData([]);
        setOrderItems([]);
        setValue('targetClient', '');
      })
      .catch((error) => {
        console.error(error);
      });
  }, [originClient]);

  useEffect(() => {
    if (!originContract) return;

    // Poblar datos
    v2GetClientContractById(originContract, '?creating=1')
      .then(({ data }) => {
        const { qualityName, details } = data;
        setQualityName(qualityName);
        setOriginContractData(details);
        setTargetContractData(details);
        setOrderItems(details);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [originContract]);

  useEffect(() => {
    const client = clientList.find((client) => client.id === targetClient);
    const normalizedAddresses = client?.ClientSendAddresses?.map(
      ({ id, name }) => ({
        id,
        name,
      })
    );
    setSendAddresses(normalizedAddresses);
    setValue('sendAddress', normalizedAddresses?.[0].id);
  }, [targetClient]);

  const handleSetTarget = (itemId, { name, value }) => {
    setTarget((prev) => {
      if (prev[itemId]) {
        prev[itemId][name] = value;
        return prev;
      }
      const newData = { ...prev, [itemId]: { [name]: value } };
      return newData;
    });
  };

  // Handler to update order item
  const onUpdate = (itemId, { name, value }) => {
    const index = orderItems.findIndex(({ id }) => id === itemId);
    const target = orderItems[index];
    const item = {
      ...target,
      [name]: value,
    };

    setOrderItems([
      ...orderItems.slice(0, index),
      item,
      ...orderItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, e) => {
    e.preventDefault();

    setSubmitting(true);

    try {
      const targetList = Object.entries(target);
      targetList.forEach(([key, value]) => {
        if (!value?.sellPrice) {
          throw Error();
        }
        if (!value?.boughtUnits) {
          throw Error();
        }
      });
    } catch (err) {
      createToast({
        text: 'Falta precio o cantidad en alguna línea',
        type: 'error',
      });
      setSubmitting(false);
      return;
    }

    const {
      palletized,
      transportIsIncluded,
      paymentTypeId,
      hasReposition,
      sendAddress,
    } = getValues();

    const payload = {
      comesFromClientContractId: data.originContract,
      contractDate: data.term,
      clientId: data.targetClient,
      details: target,
      palletized,
      transportIsIncluded,
      paymentTypeId,
      hasReposition,
      sendAddress,
    };

    assignStock(payload)
      .then(() => {
        createToast({
          text: 'Cesión de stock creada con éxito',
          type: 'success',
        });
        navigate('/clientes/cesion');
      })
      .catch(({ status }) => {
        setSubmitting(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  };

  const onError = (errors, e) => {
    e.preventDefault();
  };

  return (
    <>
      <Back text="Volver a las cesiones" path="/clientes/cesion" />

      <form className="new-transfer" onSubmit={handleSubmit(onSubmit, onError)}>
        <h2 className="new-transfer__header">Nueva cesión de stock</h2>
        <div className="new-transfer__basic-data">
          <div className="new-transfer__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Cliente de origen:
            </label>
            <Controller
              control={control}
              name="originClient"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clientList}
                  placeholder="Cliente de origen"
                  value={value}
                  error={errors.originClient}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-transfer__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Contrato de origen:
            </label>
            <Controller
              control={control}
              name="originContract"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={originContracts}
                  placeholder="Contrato de origen"
                  value={value}
                  error={errors.originContract}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-transfer__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Cliente destino:
            </label>
            <Controller
              control={control}
              name="targetClient"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clientListExcludingActive}
                  placeholder="Cliente de destino"
                  value={value}
                  error={errors.targetClient}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className={inputDateClassNames}>
            <label>Fecha de retiro:</label>
            <Controller
              control={control}
              name="term"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.term}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
        </div>
        <div className="new-transfer__basic-data new-transfer__options">
          <div className="new-contract__switches new-transfer__options">
            <Controller
              control={control}
              name="palletized"
              render={({ field: { value, onChange } }) => (
                <Switch id="palletized" value={value} onChange={onChange}>
                  Paletizado
                </Switch>
              )}
            />
            <Controller
              control={control}
              name="transportIsIncluded"
              render={({ field: { value, onChange } }) => (
                <Switch
                  id="transportIsIncluded"
                  value={value}
                  onChange={onChange}
                >
                  Transporte incluido
                </Switch>
              )}
            />
            <Controller
              control={control}
              name="hasReposition"
              render={({ field: { value, onChange } }) => (
                <Switch id="hasReposition" value={value} onChange={onChange}>
                  Con reposición
                </Switch>
              )}
            />
            <div className="fullwidth">
              <Controller
                control={control}
                name="paymentTypeId"
                rules={{ required: 'El campo es obligatorio' }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={paymentList}
                    placeholder="Método de pago"
                    value={value}
                    error={errors.paymentTypeId}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            <div className="fullwidth">
              <Controller
                control={control}
                name="sendAddress"
                rules={{ required: 'El campo es obligatorio' }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    options={sendAddresses}
                    placeholder="Dirección de envío"
                    value={value}
                    error={errors.sendAddress}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {!!originContractData?.length && targetClient ? (
          <>
            <h2 className="new-transfer__header-table">
              Contrato de origen: {contractName}
            </h2>
            <Table
              id="origin-client"
              rowsData={mapperOriginContractData}
              headCells={NEW_TRANSFER_HEAD_CELLS_ORIGIN}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={onUpdate}
            />
          </>
        ) : (
          <>
            <div className="new-transfer__empty">
              Selecciona clientes y contrato de origen
            </div>
          </>
        )}
        {!!targetContractData.length && targetClient && (
          <>
            <h2 className="new-transfer__header-table">
              Receptor: {targetClientName}
            </h2>
            <Table
              id="target-client"
              rowsData={mapperTargetContractData}
              headCells={NEW_TRANSFER_EDIT_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              onCellValueChange={handleSetTarget}
            />
          </>
        )}
        <div className="new-contract__footer">
          <Button
            type="submit"
            update
            loading={submitting}
            text="Crear cesión"
            disabled={!Object.entries(target)[0]?.length}
          />
        </div>
      </form>
    </>
  );
};

export default NewTransfer;
