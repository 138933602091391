/** Components */
import Table from 'components/Table';
/** Constants */
import { TRANSFERS_HEAD_CELLS } from 'constants/transfers';
/** Styles */
import './TransfersList.scss';

const TransfersList = ({ transfers = [] }) => {
  const mappedTransfers = transfers.map(
    ({
      originContract = '',
      originClient = '',
      targetContract = '',
      targetClient = '',
      quality = '',
      transferStock = 0,
      buyPrice = 0,
      originSellPrice = 0,
      targetSellPrice = 0,
      transferId: id,
    }) => {
      return {
        ...(originContract
          ? { originContract }
          : { originContract: 'Sin contrato origen' }),
        ...(originClient
          ? { originClient }
          : { originClient: 'Sin cliente origen' }),
        ...(targetContract
          ? { targetContract }
          : { targetContract: 'Sin contrato destino' }),
        ...(targetClient
          ? { targetClient }
          : { targetClient: 'Sin cliente destino' }),
        ...(quality ? { quality } : { quality: 'Sin calidad' }),
        ...(transferStock
          ? { transferStock }
          : { transferStock: 'Sin stock cedido' }),
        ...(buyPrice ? { buyPrice } : { buyPrice: 'Sin precio de compra' }),
        ...(originSellPrice
          ? { originSellPrice }
          : { originSellPrice: 'Sin precio de venta origen' }),
        ...(targetSellPrice
          ? { targetSellPrice }
          : { targetSellPrice: 'Sin precio de venta destino' }),
        id,
      };
    }
  );
  return (
    <>
      {transfers.length !== 0 ? (
        <Table
          rowsData={mappedTransfers}
          headCells={TRANSFERS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
        />
      ) : (
        <div className="transfers-list--empty">No hay datos.</div>
      )}
    </>
  );
};

export default TransfersList;
