export const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_ENDPOINT
    : process.env.REACT_APP_PRODUCTION_ENDPOINT;

export const DOWNLOAD_URL = `${BASE_URL}/api/download`;

export const DOWNLOAD_METADATA = (id) => ({
  clientContract: {
    url: `${DOWNLOAD_URL}/client-contract/${id}`,
    filename: `contrato_cliente_${id}`,
  },
  clientProforma: {
    url: `${DOWNLOAD_URL}/client-proforma/${id}`,
    filename: `proforma_cliente_${id}`,
  },
  clientBillInvoice: {
    url: `${DOWNLOAD_URL}/client-bill-invoice/${id}`,
    filename: `factura_abonos_cliente_${id}`,
  },
  clientInvoice: {
    url: `${DOWNLOAD_URL}/client-invoice/${id}`,
    filename: `factura_cliente_${id}`,
  },
  clientOrder: {
    url: `${DOWNLOAD_URL}/client-order/${id}`,
    filename: `pedido_cliente_${id}`,
  },
  supplierContract: {
    url: `${DOWNLOAD_URL}/supplier-contract/${id}`,
    filename: `contrato_proveedor_${id}`,
  },
  supplierBillInvoice: {
    url: `${DOWNLOAD_URL}/supplier-bill-invoice/${id}`,
    filename: `factura_abonos_proveedor_${id}`,
  },
  supplierInvoice: {
    url: `${DOWNLOAD_URL}/supplier-invoice/${id}`,
    filename: `factura_proveedor_${id}`,
  },
  supplierOrder: {
    url: `${DOWNLOAD_URL}/supplier-order/${id}`,
    filename: `pedido_proveedor_${id}`,
  },
  order: {
    url: `${DOWNLOAD_URL}/order/${id}`,
    filename: `pedido_${id}`,
  },
});
