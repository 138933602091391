import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Services */
import { deleteContactData } from 'services/clients.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Styles */
import styles from './ContactData.module.scss';

const ContactCard = ({
  id,
  addressFirstLine,
  addressSecondLine,
  city,
  country,
  identifyingName,
  mainContactEmail,
  mainContactName,
  mainContactPhone,
  secondaryContactEmail,
  secondaryContactName,
  secondaryContactPhone,
  zipCode,
  setContact,
  onSubmit,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [safeDelete, setSafeDelete] = useState(false);
  const [hasMainContact, setHasMainContact] = useState(false);
  const [hasSecondContact, setHasSecondContact] = useState(false);
  const [hasAddressContact, setHasAddressContact] = useState(false);

  useEffect(() => {
    if (mainContactName || mainContactPhone || mainContactEmail)
      setHasMainContact(true);
    if (secondaryContactName || secondaryContactPhone || secondaryContactEmail)
      setHasSecondContact(true);
    if (addressFirstLine || city || country || zipCode)
      setHasAddressContact(true);
  }, []);

  const editContactHandler = () => {
    setContact(id);
    setShowOptions();
  };

  const removeContactHandler = () => {
    if (safeDelete) {
      deleteContactData(id)
        .then(({ status }) => {
          if (status === 204) {
            createToast({
              text: 'Datos de contacto eliminados con éxito',
              type: 'success',
              duration: 2500,
            });
            onSubmit();
          }
        })
        .catch(({ status }) => {
          if (status === 401) {
            createToast({
              text: 'No tienes suficientes permisos',
              type: 'error',
            });
          }

          if (status === 500) {
            createToast({ text: 'Algo ha salido mal', type: 'error' });
          }
        });

      setShowOptions();
      return;
    }

    setSafeDelete(true);
    setTimeout(() => {
      setSafeDelete(false);
    }, 3000);
  };

  return (
    <div className={styles.ContactItem}>
      <div className={styles.ContactItemHeader}>
        <h3>{identifyingName}</h3>
        <FontAwesomeIcon
          icon="ellipsis-v"
          className={styles.EllipsisIcon}
          onClick={() => setShowOptions(!showOptions)}
        />
        {showOptions && (
          <div className={styles.ContactItemOptions}>
            <p onClick={editContactHandler}>Editar</p>
            <p onClick={removeContactHandler}>
              {safeDelete ? 'Confirmar' : 'Eliminar'}
            </p>
          </div>
        )}
      </div>
      <div className={styles.ContactItemData}>
        {hasMainContact && (
          <div className={styles.ContactItemMain}>
            <h4>Contacto principal:</h4>
            <p>{mainContactName}</p>
            <p>{mainContactPhone}</p>
            <p>{mainContactEmail}</p>
          </div>
        )}
        {hasSecondContact && (
          <div className={styles.ContactItemSecond}>
            <h4>Contacto secundario:</h4>
            <p>{secondaryContactName}</p>
            <p>{secondaryContactPhone}</p>
            <p>{secondaryContactEmail}</p>
          </div>
        )}
        {hasAddressContact && (
          <div className={styles.ContactItemAddres}>
            <h4>Dirección:</h4>
            <p>
              {addressFirstLine}{' '}
              {addressSecondLine?.length && `- ${addressSecondLine}`}
            </p>
            <p>
              {city}, {country}, {zipCode}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactCard;
