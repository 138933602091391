/** Constants */
import { PAYMENTS_HEAD_CELLS } from 'constants/payments.js';
/** Components */
import Table from 'components/Table';

const PaymentsList = ({ payments = [] }) => {
  const mappedPayments = payments.map(
    ({ label = '', privateNotes = '', publicNotes = '', id }, index) => {
      return {
        ...(label ? { label } : { label: 'Sin nombre' }),
        ...(privateNotes
          ? { privateNotes }
          : { privateNotes: 'Sin notas privadas' }),
        ...(publicNotes
          ? { publicNotes }
          : { publicNotes: 'Sin notas públicas' }),
        ...(id && { id }),
      };
    }
  );

  return (
    <>
      {payments && (
        <Table
          rowsData={mappedPayments}
          headCells={PAYMENTS_HEAD_CELLS}
          showDelete={false}
          ignoreColumn="id"
          targetItem="id"
        />
      )}
      {!payments && 'No hay datos'}
    </>
  );
};

export default PaymentsList;
