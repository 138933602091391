import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
/** Components */
import Button from 'components/Button';
import Select from 'components/Select';
import Switch from 'components/Switch';
/** Services */
import { getAllClients } from 'services/clients';
import { getAllPayments } from 'services/payments';
/** Styles */
import './LineClientForm.scss';

const LineClientForm = ({
  lineClientData = {},
  isContractPalletized,
  cancelEdit,
  onSubmit,
}) => {
  const [clientList, setClientList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [clientWarehouses, setClientWarehouses] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      clientId: lineClientData?.clientId,
      palletized: !!lineClientData?.palletized || !!isContractPalletized,
      transportIsIncluded: !!lineClientData?.transportIsIncluded,
      warehouseAddress: lineClientData?.warehouseAddress,
    },
  });

  const targetClientId = watch('clientId');
  const warehouseAddress = watch('warehouseAddress');

  useEffect(() => {
    if (!warehouseList?.length && !warehouseAddress) {
      return;
    }

    const targetClientWarehouses = warehouseList.filter(
      (warehouse) => warehouse.clientId === targetClientId
    );

    setClientWarehouses(targetClientWarehouses);

    if (targetClientWarehouses?.length === 1) {
      if (warehouseAddress) {
        setValue('warehouseAddress', warehouseAddress);
      } else {
        setValue('warehouseAddress', targetClientWarehouses[0].value);
      }
    }
  }, [targetClientId, warehouseList]);

  useEffect(() => {
    getAllClients()
      .then(({ status, data: { fetchedClients } }) => {
        if (status === 200) {
          setClientList(fetchedClients);
          const warehouses = [];
          for (const client of fetchedClients) {
            const addresses = client?.ClientSendAddresses;

            for (const address of addresses) {
              warehouses.push({
                id: address?.id,
                value: address?.id,
                key: address?.id,
                name: address?.name || 'Sin almacén',
                clientId: client.id,
                rest: address,
              });
            }
          }
          setWarehouseList(warehouses);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    getAllPayments()
      .then(({ status, data: { fetchedPaymentOptions } }) => {
        if (status === 200) {
          const normalizedPayments = fetchedPaymentOptions.map((payments) => ({
            ...payments,
            name: payments.label,
          }));
          setPaymentList(normalizedPayments);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (!!Object.entries(lineClientData).length) {
      reset(lineClientData);
    }
  }, [lineClientData]);

  const onSubmitHandler = (data) => {
    if (errors && Object.keys(errors).length) return;

    const client = clientList.find(({ id }) => id === data.clientId);
    const payment = paymentList.find(({ id }) => id === data.paymentTypeId);

    onSubmit({
      ...data,
      clientName: client?.name ?? '',
      paymentName: payment?.label ?? '',
    });
  };

  return (
    <div className="LineClientNewLine">
      <div className="LineClientForm">
        <div className="LineClientNewLineForm">
          <label className="NewContractFormRightProviderSelectTitle">
            Selecciona un cliente:
          </label>
          <Controller
            control={control}
            name="clientId"
            rules={{ required: 'El campo es obligatorio' }}
            render={({ field: { value, onChange } }) => (
              <Select
                options={clientList}
                placeholder="Cliente"
                value={value}
                error={errors.clientId}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="LineClientNewLineForm">
          <label className="NewContractFormRightProviderSelectTitle">
            Selecciona un método de pago:
          </label>
          <Controller
            control={control}
            name="paymentTypeId"
            rules={{ required: 'El campo es obligatorio' }}
            render={({ field: { value, onChange } }) => (
              <Select
                options={paymentList}
                placeholder="Método de pago"
                value={value}
                error={errors.paymentTypeId}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="LineClientNewLineForm">
          <label className="NewContractFormRightProviderSelectTitle">
            Selecciona un lugar de entrega:
          </label>
          <Controller
            control={control}
            name="warehouseAddress"
            rules={{ required: 'El campo es obligatorio' }}
            render={({ field: { value, onChange } }) => (
              <Select
                options={clientWarehouses}
                placeholder="Lugar de entrega"
                value={value}
                error={errors.warehouseAddress}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>

      <div className="LineClientFormSwitches">
        <div className="LineClientNewLineForm">
          <Controller
            control={control}
            name="palletized"
            render={({ field: { value, onChange } }) => (
              <Switch id="client_palletized" value={value} onChange={onChange}>
                Paletizado
              </Switch>
            )}
          />
        </div>
        <div className="LineClientNewLineForm">
          <Controller
            control={control}
            name="transportIsIncluded"
            render={({ field: { value, onChange } }) => (
              <Switch
                id="transportIsIncluded"
                value={value}
                onChange={onChange}
              >
                Transporte incluido
              </Switch>
            )}
          />
        </div>
      </div>
      <div className="LineClientFormAction">
        <Button cancel text="Cancelar" click={cancelEdit} />
        <Button update text="Guardar" click={handleSubmit(onSubmitHandler)} />
      </div>
    </div>
  );
};

export default LineClientForm;
