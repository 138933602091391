import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/** Utils */
import { createToast } from 'utils/toast';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
/** Context */
import useAuth from 'context/useAuth';
/** Services */
import { checkToken } from 'services/auth';
/** Constants */
import { USER_INITIAL_DATA, UPDATE_USER_FORM } from 'constants/users';
/** Styles */
import styles from './RegisterForm.module.scss';

const RegisterForm = () => {
  const [formData, setFormData] = useState(USER_INITIAL_DATA);
  const navigate = useNavigate();
  const { signUp } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (!searchParams.get('token') && !searchParams.get('email')) {
      navigate('/');
      return;
    }

    checkToken(searchParams.get('token'))
      .then(({ status, message }) => {
        if (status === 204) {
          setFormData({ ...formData, token: searchParams.get('token') });
          return;
        }

        createToast({ text: message, type: 'error' });
        navigate('/');
      })
      .catch(() => {
        createToast({ text: 'Ha ocurrido un error', type: 'error' });
      });

    const targetEmail = document.querySelector('#email');
    targetEmail.value = searchParams.get('email');
    formData.email = searchParams.get('email');
  }, []);

  const changeHandler = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    signUp(formData);
  };

  return (
    <div className={styles.FormContainer}>
      <h2 className={styles.FormTitle}>Nuevo Usuario</h2>
      <form
        id="register-form"
        className={styles.FormWrapper}
        onSubmit={submitHandler}
      >
        {UPDATE_USER_FORM.map(({ id, label, type, placeholder }) => (
          <div className={styles.FormField} key={id}>
            <Input
              id={id}
              label={label}
              name={id}
              placeholder={placeholder}
              type={type}
              onChange={changeHandler}
            />
          </div>
        ))}
        <div className={styles.FormButtonWrapper}>
          <Button text="Regístrate" />
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
