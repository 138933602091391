export const TRANSFERS_HEAD_CELLS = [
  {
    id: 'originContract',
    numeric: false,
    disablePadding: false,
    label: 'Contrato origen',
  },
  {
    id: 'originClient',
    numeric: false,
    disablePadding: false,
    label: 'Cliente origen',
  },
  {
    id: 'targetContract',
    numeric: false,
    disablePadding: false,
    label: 'Contrato destino',
  },
  {
    id: 'targetClient',
    numeric: false,
    disablePadding: false,
    label: 'Cliente destino',
  },
  {
    id: 'quality',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'transferStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock cedido',
  },
  {
    id: 'buyPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de compra',
  },
  {
    id: 'originSellPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de venta origen',
  },
  {
    id: 'targetSellPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de venta destino',
  },
];

export const NEW_TRANSFER_HEAD_CELLS_ORIGIN = [
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'buyPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de compra',
  },
  {
    id: 'term',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de retiro',
    isDate: true,
  },
  {
    id: 'availableStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock disponible',
  },
];

export const NEW_TRANSFER_EDIT_HEAD_CELLS = [
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'buyPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de compra',
  },
  {
    id: 'term',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de retiro',
    isDate: true,
  },
  {
    id: 'sellPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de venta',
    isEditable: true,
  },
  {
    id: 'availableStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock a transferir',
    isEditable: true,
  },
];
