import { NavLink } from 'react-router-dom';
/** Components */
import XLSXLink from 'components/XLSXLink';
import InvoiceBatches from 'pages/Orders/RegisterBatches/InvoiceBatches';
import SupplierInvoiceSelector from 'pages/Orders/RegisterBatches/SupplierInvoiceSelector';
/** Constants */
import { DOWNLOAD_METADATA } from 'constants/download';

export const ORDERS_HEAD_CELLS = [
  {
    id: 'orderId',
    numeric: false,
    disablePadding: false,
    label: 'ID.',
  },
  {
    id: 'clientName',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de pedido',
    isDate: true,
  },
  {
    id: 'paymentTypeName',
    numeric: false,
    disablePadding: false,
    label: 'Método de pago',
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    label: 'Descargar',
    render: ({ metadata: { fileId, key } }) => {
      const { url, filename } = DOWNLOAD_METADATA(fileId)[key];
      return {
        component: XLSXLink,
        props: {
          url,
          filename,
          pdf: false,
        },
      };
    },
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: '',
    align: 'right',
    render: ({ id, status }) => {
      const orderStatus = ORDER_STATUS[status];
      const { url, name } = orderStatus ?? {};
      if (status === 'beforePayment')
        return {
          component: NavLink,
          props: {
            to: url ? `/pedidos/${id}/${url}` : '',
            className: 'orders-list__tag waiting',
          },
          children: 'Esperando Factura',
        };
      {
      }
      if (status === 'finished') {
        return {
          component: NavLink,
          props: {
            to: url ? `/pedidos/${id}/${url}` : '',
            className: 'orders-list__tag finished',
          },
          children: 'Pedido finalizado',
        };
      }
      return {
        component: NavLink,
        props: {
          to: url ? `/pedidos/${id}/${url}` : '',
          className: url ? 'orders-list__action-btn' : '',
        },
        children: name ?? undefined,
      };
    },
  },
];

export const ORDER_QUALITIES_HEAD_CELLS = [
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'comesFromSupplierContractId',
    numeric: false,
    disablePadding: false,
    label: 'Nº contrato proveedor',
  },
  {
    id: 'comesFromClientContractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº contrato cliente',
  },
  {
    id: 'sellPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de venta',
  },
  {
    id: 'buyPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de compra',
  },
  {
    id: 'availableStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock disponible',
  },
  {
    id: 'term',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de retiro',
    isDate: true,
  },
  {
    id: 'quantity',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Cantidad',
    isEditable: true,
  },
];

export const ORDER_QUALITIES_BATCH_HEAD_CELLS = [
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'comesFromSupplierContractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº contrato proveedor',
  },
  {
    id: 'comesFromClientContractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº contrato cliente',
  },
  {
    id: 'sellPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de venta',
  },
  {
    id: 'buyPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio de compra',
  },
  {
    id: 'availableStock',
    numeric: false,
    disablePadding: false,
    label: 'Stock disponible',
  },
  {
    id: 'term',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de retiro',
    isDate: true,
  },
  {
    id: 'quantity',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Cantidad',
  },
  {
    id: 'supplierInvoice',
    numeric: false,
    disablePadding: false,
    label: 'Factura de proveedor',
    render: ({ id, supplierInvoice }) => {
      const { value, options } = supplierInvoice ?? {};
      return {
        component: SupplierInvoiceSelector,
        props: {
          id,
          value,
          options,
        },
      };
    },
  },
  {
    id: 'batch',
    numeric: false,
    disablePadding: false,
    label: 'Lotes',
    render: ({ id, batch }) => {
      return {
        component: InvoiceBatches,
        props: {
          id,
          batches: batch,
        },
      };
    },
  },
];

export const ORDER_QUALITIES_REAL_WEIGHT_HEAD_CELLS = [
  {
    id: 'batch',
    numeric: false,
    disablePadding: false,
    label: 'Lote',
  },
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'clientContractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Contrato Cliente',
  },
  {
    id: 'supplierContractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Contrato Proveedor',
  },
  {
    id: 'units',
    numeric: false,
    disablePadding: false,
    label: 'Unidades',
  },
  {
    id: 'expectedWeight',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Peso esperado',
    isEditable: false,
  },
  {
    id: 'netWeight',
    numeric: false,
    type: 'number',
    step: '0.01',
    disablePadding: false,
    label: 'Peso neto',
    isEditable: true,
  },
];

export const BATCHES_REAL_WEIGHT_HEAD_CELLS = [
  {
    id: 'batch',
    numeric: false,
    disablePadding: false,
    label: 'Lote',
  },
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'units',
    numeric: false,
    disablePadding: false,
    label: 'Unidades',
  },
  {
    id: 'expectedWeight',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Peso esperado',
    isEditable: false,
  },
  {
    id: 'netWeight',
    numeric: false,
    type: 'number',
    step: '0.01',
    disablePadding: false,
    label: 'Peso neto',
    isEditable: true,
  },
];

export const IVA_OPTIONS = [
  {
    id: 10,
    name: '10 %',
  },
  {
    id: 21,
    name: '21 %',
  },
];

export const ORDER_STATUS = {
  beforeSending: {
    url: 'registro-pesos-reales',
    name: 'Registrar pesos reales',
  },
};
