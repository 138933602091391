export const SIGNIN_INITIAL_DATA = {
  email: '',
  password: '',
};

export const RESET_PASSWORD_INITIAL_DATA = {
  email: '',
};

export const CHANGE_PASSWORD_INITIAL_DATA = {
  password: '',
  passwordRepeat: '',
};

export const SIGN_IN_FORM = [
  {
    id: 'email',
    label: 'Email',
    type: 'text',
  },
  {
    id: 'password',
    label: 'Contraseña',
    type: 'password',
  },
];

export const RESET_PASSWORD_FORM = [
  {
    id: 'email',
    label: 'Email',
    type: 'text',
  },
];

export const CHANGE_PASSWORD_FORM = [
  {
    id: 'password',
    label: 'Contraseña',
    type: 'password',
  },
  {
    id: 'passwordRepeat',
    label: 'Repetir contraseña',
    type: 'password',
  },
];
