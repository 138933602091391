import { useState, useEffect } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import NewProviderForm from './NewProviderForm';
import ProvidersList from './ProvidersList';
/** Services */
import { getAllProviders } from 'services/providers.js';
/** Styles */
import styles from './Providers.module.scss';

const Providers = () => {
  const [providersList, setProvidersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    setShouldFetch(false);

    getAllProviders()
      .then(({ status, data: { fetchedSuppliers } }) => {
        if (status === 200) {
          setProvidersList(fetchedSuppliers);
        }
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({ text: 'No exiten usuarios', type: 'error' });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, [shouldFetch]);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Providers}>
      <div className={styles.ProvidersHeader}>
        <h1 className="title">Proveedores</h1>
        <Button text="Crear nuevo" update click={() => setShowModal(true)} />
      </div>
      <>
        <Modal
          show={showModal}
          title="Añadir nuevo proveedor"
          id="new-provider"
          successButton="Crear"
          onClose={() => setShowModal(false)}
          showFooter={false}
        >
          <NewProviderForm
            closeModalHandler={() => setShowModal(false)}
            reloadProviders={() => setShouldFetch(true)}
          />
        </Modal>
        <div className={styles.ProvidersList}>
          <ProvidersList
            providers={providersList}
            reloadProviders={() => setShouldFetch(true)}
          />
        </div>
      </>
    </div>
  );
};

export default Providers;
