import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Constants */
import {
  MODIFIY_PROVIDER_FORM_DATA,
  CREATE_PROVIDER_FORM,
} from 'constants/providers.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
/** Services */
import { updateProvider, deleteProvider } from 'services/providers.js';
/** Utils */
import { validateEmail } from 'utils/email.js';
/** Styles */
import styles from './PersonalData.module.scss';

const PersonalData = ({ provider }) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [saveDelete, setSaveDelete] = useState(false);
  const [providerData, setProviderData] = useState(MODIFIY_PROVIDER_FORM_DATA);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    populateForm();
  }, []);

  const populateForm = () => {
    CREATE_PROVIDER_FORM.forEach(({ id }) => {
      const input = document.querySelector(`#${id}`);
      input.value = provider[id];
    });
    setProviderData({
      ...providerData,
      id: provider.id,
      name: provider.name,
      NIF: provider.NIF,
      country: provider.country,
      city: provider.city,
      zipCode: provider.zipCode,
      addressFirstLine: provider.addressFirstLine,
      addressSecondLine: provider.addressSecondLine,
    });

    if (provider.email !== undefined) {
      setIsValidEmail(validateEmail(provider.email));
    }
  };

  const changeHandler = ({ target: { name, value } }) => {
    setProviderData({
      ...providerData,
      [name]: value.trim(),
    });

    if (name === 'email') {
      setIsValidEmail(validateEmail(value));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setSubmitting(true);
    updateProvider(providerData)
      .then(() => {
        createToast({
          text: 'Proveedor modificado con éxito',
          type: 'success',
          duration: 2500,
        });
        setSubmitting(false);
      })
      .catch(() => {
        createToast({
          text: 'Algo ha ido mal, intentalo de nuevo más tarde',
          type: 'error',
        });
        setSubmitting(false);
      });
  };

  const removeHandler = () => {
    if (saveDelete) {
      deleteProvider(provider.id)
        .then(({ status }) => {
          if (status === 204) {
            createToast({
              text: 'Proveedor eliminado con éxito',
              type: 'success',
              duration: 2500,
            });
          }
          setIsLoaded(false);
          setTimeout(() => {
            navigate('/proveedores');
          }, 500);
        })
        .catch(() => {
          createToast({
            text: 'No se ha podido eliminar el proveedor, inténtalo de nuevo más tarde',
            type: 'error',
          });
        });
      return;
    }

    setSaveDelete(true);
    setTimeout(() => {
      setSaveDelete(false);
    }, 3000);
  };

  return (
    <>
      {isLoaded ? (
        <>
          <div className={styles.FiscalData}>
            <h2 className={styles.ClientDataTitle}>Datos Fiscales</h2>
            <form
              id="provider-form"
              onSubmit={submitHandler}
              className={styles.FormWrapper}
            >
              {CREATE_PROVIDER_FORM.map(({ id, label, type, placeholder }) => (
                <Input
                  key={id}
                  name={id}
                  placeholder={placeholder}
                  className={styles.InputAddress}
                  type={type}
                  label={label}
                  onChange={changeHandler}
                />
              ))}
              <div className={styles.ClientFormButtons}>
                <Button
                  remove
                  text={saveDelete ? 'Confirmar' : 'Eliminar'}
                  type="button"
                  click={removeHandler}
                />
                <Button update loading={submitting} text="Actualizar" />
              </div>
            </form>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PersonalData;
