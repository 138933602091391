import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faUser,
  faKey,
  faTimes,
  faEllipsisV,
  faChevronLeft,
  faUsers,
  faBars,
  faBoxes,
  faTruck,
  faCoffee,
  faCreditCard,
  faUserEdit,
  faPencilAlt,
  faTrash,
  faPlus,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';

export const registerIcons = () => {
  // Add font awesome icons to the library in order to import just used icons
  library.add([
    faAngleDown,
    faUser,
    faKey,
    faTimes,
    faEllipsisV,
    faChevronLeft,
    faUsers,
    faBars,
    faBoxes,
    faTruck,
    faCoffee,
    faCreditCard,
    faUserEdit,
    faPencilAlt,
    faTrash,
    faPlus,
    faShoppingCart,
    faFileExcel,
    faFilePdf,
  ]);
};
