import axiosWrapper, { baseUrl } from './core';

const BASE_URL = '/api/users';

export const updateUser = (params) =>
  axiosWrapper.patch(`${baseUrl}${BASE_URL}/update`, params);

export const deleteUser = (params) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete`, {
    data: { id: params },
  });

export const getAllUsers = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-all`);

export const getUser = (id) =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-by-id/${id}`);

export const signUp = (params) =>
  axiosWrapper.put(`${baseUrl}${BASE_URL}/signup`, params);

export const updatePermissions = (params) =>
  axiosWrapper.patch(`${baseUrl}${BASE_URL}/set-permissions`, params);
