import { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import esLocale from 'date-fns/locale/es';
/** Components */
import SearchBar from 'components/SearchBar';
import Input from 'components/Input';
import Switch from 'components/Switch';
/** Utils */
import { getFormattedDate } from 'utils/date';
/** Styles */
import './Table.scss';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const STICKY_STYLES = {
  right: '-10px',
  position: 'sticky',
  backgroundColor: 'white',
  zIndex: 9,
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    cells,
    showDelete,
    showFilters,
    filtersEnabled,
    filters,
    onFilterChange,
    stickyColumns,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {showDelete && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {cells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={stickyColumns.includes(headCell.id) ? STICKY_STYLES : {}}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            {showFilters &&
              filtersEnabled &&
              !headCell.render &&
              !headCell.isEditable && (
                <Input
                  id={headCell.id}
                  name={headCell.id}
                  type="text"
                  autoComplete="off"
                  placeholder="Ej: 123"
                  value={filters[headCell.id] ?? ''}
                  onChange={({ target: { value } }) =>
                    onFilterChange(headCell.id, value)
                  }
                />
              )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    deleteStatus,
    safeDeleteHandler,
    deleteHandler,
    userIds,
    headerActions,
  } = props;
  const deleteTrigger = () => {
    if (deleteStatus) {
      deleteHandler(userIds);
    }

    safeDeleteHandler(true);

    setTimeout(() => {
      safeDeleteHandler(false);
    }, 3000);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        color="inherit"
        variant="subtitle1"
        component="div"
      >
        {numSelected} Seleccionados
      </Typography>

      {/* TODO: Set actions dynamically */}
      {numSelected > 0 && (
        <>
          {headerActions?.delete && (
            <>
              {!deleteStatus ? (
                <Tooltip title="Eliminar">
                  <IconButton onClick={deleteTrigger}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <span className="RemoveConfirm" onClick={deleteTrigger}>
                  Confirmar
                </span>
              )}
            </>
          )}
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const EditableTableCell = ({
  name,
  type = 'text',
  step = undefined,
  value,
  disabled = false,
  isDatepicker = false,
  onCellValueChange,
  stickyColumns,
}) => {
  const timeoutRef = useRef(null);

  const handleInputChange = ({ target: { value } }) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      // const normalizedValue = type === 'number' ? Math.floor(value) : value;
      const normalizedValue = type === 'number' ? Number(value) : value;
      onCellValueChange &&
        onCellValueChange({
          name,
          value: normalizedValue,
        });
    }, 500);
  };

  const handleDateChange = (value) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      onCellValueChange({
        name,
        value,
      });
    }, 500);
  };

  return (
    <TableCell style={stickyColumns.includes(name) ? STICKY_STYLES : {}}>
      {!isDatepicker ? (
        <Input
          id={name}
          name={name}
          type={type}
          step={step}
          autoComplete="off"
          defaultValue={value}
          disabled={disabled}
          onChange={handleInputChange}
        />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            id={name}
            inputFormat="dd/MM/yyyy"
            value={value}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
    </TableCell>
  );
};

export default function EnhancedTable({
  id = '',
  rowsData,
  headCells,
  targetItem,
  setItem = () => ({}),
  ignoreColumn,
  deleteHandler,
  showDelete = true,
  queryField = 'name',
  showSearchBar = true,
  onCellValueChange,
  showFilters = true,
  defaultFiltersEnabled = false,
  disabled = false,
  stickyColumns = [],
  headerActions,
  order: rowsInitialOrder = 'desc',
  orderBy: initialOrderBy = '',
}) {
  const [order, setOrder] = useState(rowsInitialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [hasData, setHasData] = useState(false);
  const [safeDelete, setSafeDelete] = useState(false);
  const [rows, setRows] = useState([]);
  const [cells, setCells] = useState([]);
  const [query, setQuery] = useState('');
  const [filtersEnabled, setFiltersEnabled] = useState(defaultFiltersEnabled);
  const [filters, setFilters] = useState({});

  const filteredRows = useMemo(() => {
    const rowsByFilterQueries = rows.filter((row) => {
      const filterQueries = Object.entries(filters);
      if (!filterQueries.length) return true;
      return filterQueries.every(([key, filterQuery]) => {
        const rowValue = String(row[key]) ?? '';
        return (
          filterQuery === '' ||
          rowValue.toLowerCase().includes(filterQuery.toLowerCase())
        );
      });
    });
    return rowsByFilterQueries.filter((row) =>
      (row[queryField] ?? '').toLowerCase().includes(query)
    );
  }, [query, queryField, rows, filters]);

  useEffect(() => {
    if (rowsData.length && headCells.length) {
      setHasData(true);
      setRows(rowsData);
      setCells(headCells);
    }
  }, [hasData]);

  useEffect(() => {
    setRows(rowsData);
    setHasData(rowsData.length);
    setSelected([]);
  }, [rowsData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (!showDelete) return;

    if (event.target.checked) {
      const newSelecteds = filteredRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, ids) => {
    if (!showDelete) return;

    const selectedIndex = selected.indexOf(ids);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ids);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const handleSearch = (value) => {
    setQuery(value);
  };

  const handleCellValueChange = (rowId, newVal) => {
    onCellValueChange && onCellValueChange(rowId, newVal);
  };

  const handleFilterChange = (id, value) => {
    setFilters({ ...filters, [id]: value });
  };

  return (
    <>
      {hasData && (
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            {!!selected.length && (
              <EnhancedTableToolbar
                numSelected={selected.length}
                deleteStatus={safeDelete}
                safeDeleteHandler={(value) => setSafeDelete(value)}
                deleteHandler={(value) => deleteHandler(value)}
                userIds={selected}
              />
            )}
            {(showSearchBar || showFilters) && (
              <div className="table__header">
                {showSearchBar && (
                  <SearchBar debounce={300} onChange={handleSearch} />
                )}
                {showFilters && (
                  <Switch
                    id={`table_filters_${id}`}
                    value={filtersEnabled}
                    className="table__switch"
                    onChange={(value) => setFiltersEnabled(value)}
                  >
                    Activar filtros
                  </Switch>
                )}
              </div>
            )}
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
                stickyHeader
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={filteredRows.length}
                  cells={cells}
                  showDelete={showDelete}
                  showFilters={showFilters}
                  filtersEnabled={filtersEnabled}
                  filters={filters}
                  onFilterChange={handleFilterChange}
                  stickyColumns={stickyColumns}
                  headerActions={headerActions}
                />
                <TableBody>
                  {stableSort(filteredRows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const rowsEntries = Object.entries(row);

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id ?? index}
                          selected={isItemSelected}
                        >
                          {showDelete && (
                            <TableCell
                              key={`row_${index}_delete`}
                              padding="checkbox"
                              onClick={(event) => handleClick(event, row.id)}
                            >
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                          )}
                          {rowsEntries.map(([entry], colIndex) => {
                            if (entry === ignoreColumn) return null;
                            const value = row[entry];
                            const cellConfig = cells[colIndex] ?? {};
                            if (cellConfig.render) {
                              const {
                                component: Component,
                                props,
                                children,
                              } = cellConfig.render(row) ?? {};
                              if (!Component) return null;
                              return (
                                <TableCell
                                  key={`${entry}_${index}_${colIndex}`}
                                  align={cellConfig.align}
                                  style={
                                    stickyColumns.includes(entry)
                                      ? STICKY_STYLES
                                      : {}
                                  }
                                >
                                  <Component
                                    {...props}
                                    onChange={(payload) =>
                                      handleCellValueChange(row.id, payload)
                                    }
                                  >
                                    {children}
                                  </Component>
                                </TableCell>
                              );
                            }
                            if (cellConfig.isEditable) {
                              if (
                                cellConfig.isEditableDisabled &&
                                cellConfig.isEditableDisabled(value)
                              ) {
                                return (
                                  <TableCell
                                    key={`${entry}_${index}_${colIndex}`}
                                    style={
                                      stickyColumns.includes(entry)
                                        ? STICKY_STYLES
                                        : {}
                                    }
                                  >
                                    -
                                  </TableCell>
                                );
                              }
                              return (
                                <EditableTableCell
                                  key={`${entry}_${index}_${colIndex}`}
                                  name={entry}
                                  type={cellConfig.type}
                                  step={cellConfig.step}
                                  value={value}
                                  disabled={disabled}
                                  isDatepicker={cellConfig.isDatepicker}
                                  onCellValueChange={(newValue) =>
                                    handleCellValueChange(row.id, newValue)
                                  }
                                  stickyColumns={stickyColumns}
                                />
                              );
                            }
                            const {
                              prefix = '',
                              suffix = '',
                              isDate = false,
                              align = 'left',
                            } = cellConfig;
                            const cellValue = isDate
                              ? getFormattedDate(value)
                              : value;
                            const isFilled = cellValue !== '-';
                            return (
                              <TableCell
                                key={`${entry}_${index}_${colIndex}`}
                                onClick={() => setItem(row[targetItem])}
                                align={align}
                                style={
                                  stickyColumns.includes(entry)
                                    ? STICKY_STYLES
                                    : {}
                                }
                              >
                                {isFilled ? prefix : null}
                                {cellValue}
                                {isFilled ? suffix : null}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {!emptyRows && !filteredRows.length ? (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      {showDelete && <TableCell />}
                      <TableCell align={'left'} colSpan={headCells.length + 1}>
                        No se ha encontrado ningún resultado.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Filas por página"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  );
}
