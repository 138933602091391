import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Back from 'components/Back';
import Select from 'components/Select';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Table from 'components/Table';
/** Services */
import { createSupplierInvoice } from 'services/invoices';
import { getAllProviders } from 'services/providers';
import { getSupplierContractsList } from 'services/contracts';
import {
  getOrdersBySupplierAndContract,
  getOrdersBySupplierContract,
} from 'services/orders';
/** Constants */
import {
  INVOICES_LINE_ITEMS_HEAD_CELLS,
  IVA_OPTIONS,
} from 'constants/providers';
/** Styles */
import './NewInvoiceForm.scss';

const NewInvoiceForm = () => {
  const [providersList, setProvidersList] = useState([]);
  const [supplierContractList, setSupplierContractList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [orders, setOrders] = useState([]);

  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    resetField,
  } = useForm({ defaultValues: { vat: 10, date: new Date() } });

  const supplierId = watch('supplierId');
  const supplierContractId = watch('supplierContractId');
  const orderId = watch('orderId');

  const mappedLineItems = useMemo(() => {
    const activeLineItems = lineItems.filter((detail) =>
      Boolean(+detail?.units)
    );

    return activeLineItems.map(
      ({
        StockOrderedId: id,
        qualityId,
        qualityName,
        units = 0,
        packCapacity = 0,
        price = 0,
        batch,
      }) => {
        if (units > 0) {
          return {
            ...(qualityName ? { qualityName } : { qualityName: 'Sin calidad' }),
            ...(units ? { units } : { units: 0 }),
            ...(packCapacity ? { packCapacity } : { packCapacity: 0 }),
            ...(price ? { price } : { price: 0 }),
            ...(batch ? { batch } : { batch: undefined }),
            ...(qualityId && { qualityId }),
            ...(id && { id }),
          };
        }
      }
    );
  }, [lineItems]);

  useEffect(() => {
    getAllProviders()
      .then(({ data: { fetchedSuppliers } }) => {
        setProvidersList(fetchedSuppliers);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!supplierId) return;
    setFetching(true);
    setSupplierContractList([]);

    setFetching(true);

    getSupplierContractsList(supplierId, `?creating=1`)
      .then(({ status, data: { suppliersContracts } }) => {
        if (status === 200) {
          const mappedContracts =
            suppliersContracts.map(({ id, contractNumber }) => ({
              id: id,
              name: contractNumber,
            })) ?? [];
          setSupplierContractList(mappedContracts);
          setFetching(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setFetching(false);
      });
    setLineItems([]);
    setOrders([]);
    setOrdersList([]);
    resetField('supplierContractId');
    resetField('orderId');
  }, [supplierId]);

  useEffect(() => {
    if (!supplierId || !supplierContractList?.length) return;

    setFetching(true);

    resetField('orderId');
    setLineItems([]);
    setOrders([]);

    getOrdersBySupplierContract(supplierContractId, '?creating=1')
      .then(({ data }) => {
        setOrdersList(
          data.map(({ id, name = '' }) => ({ id, name: `${id} - ${name}` }))
        );
        setFetching(false);
      })
      .catch((error) => {
        console.log(error);
        setFetching(false);
      });
  }, [supplierContractId]);

  useEffect(() => {
    setLineItems([]);
    setFetching(true);
    if (!orderId) return;

    getOrdersBySupplierAndContract(supplierId, orderId)
      .then(({ data }) => {
        const orders = data.map(({ orderId: id, clientName, ...order }) => ({
          ...order,
          id,
          clientName: `${id} - ${clientName}`,
        }));
        const activeDetails = data.filter((data) => Boolean(+data?.units));
        setLineItems(activeDetails);
        setOrders(orders);
        setFetching(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setFetching(false);
        setLoading(false);
      });
  }, [orderId]);

  useEffect(() => {
    setFetching(true);
    const supplierContract = supplierContractList.find(
      ({ id }) => id === supplierContractId
    );
    const { details = [] } = supplierContract || {};
    const activeDetails = details.filter((detail) => Boolean(+detail?.units));
    setLineItems(activeDetails);
    setFetching(false);
  }, [supplierContractId]);

  // Handler to create an invoice
  const createInvoice = ({ description, price, ...data }) => {
    setSubmitting(true);

    const normalizedLineItems = lineItems.map(
      ({
        qualityId,
        batch,
        units,
        packCapacity,
        price: pricePerKg,
        orderId,
        orderDetailId,
      }) => {
        const object = {
          ...(qualityId && { qualityId }),
          units: Number(units),
          packCapacity,
          pricePerKg: Number(pricePerKg),
          orderId,
          orderDetailId,
        };

        if (batch) {
          object.batches = [...batch];
        }

        return object;
      }
    );

    const details = normalizedLineItems;

    const payload = {
      ...data,
      details,
    };

    createSupplierInvoice(payload)
      .then(() => {
        createToast({
          text: 'Factura de proveedor registrada con éxito',
          type: 'success',
        });
        navigate('/proveedores/facturas');
      })
      .catch(({ status, response }) => {
        setSubmitting(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: response.data.feedback, type: 'error' });
      });
  };

  // Handler to update line item
  const onUpdate = (itemId, { name, value }) => {
    const targetItem = lineItems.find(
      ({ StockOrderedId }) => StockOrderedId === itemId
    );

    let parsedValue = value;
    if (name === 'batch' && value?.length === 1 && !value?.[0]?.quantity) {
      parsedValue = [
        {
          batch: value?.[0]?.batch,
          quantity: targetItem.units,
        },
      ];
    }

    if (name === 'batch' && !targetItem.units) {
      createToast({
        text: 'Guarda una cantidad antes de configurar sus lotes',
        type: 'error',
      });
      return;
    }

    const index = lineItems.findIndex(
      ({ StockOrderedId }) => StockOrderedId === itemId
    );
    const target = lineItems[index];
    if (name === 'units') {
      delete target?.batch;
    }
    const item = {
      ...target,
      [name]: parsedValue,
    };

    setLineItems([
      ...lineItems.slice(0, index),
      item,
      ...lineItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    createInvoice(data);
  };

  // Classes to make error for dates
  const inputDateClassNames = cx('new-invoice__basic-date', {
    'new-invoice__basic-date--error': !!errors.date,
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back
        text="Volver a las facturas de proveedor"
        path="/proveedores/facturas"
      />

      <form className="new-invoice" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="new-invoice__header">Registrar factura de proveedor</h2>
        <div className="new-invoice__basic-data-custom">
          <div className="new-invoice__basic-data-input">
            <label>Proveedor:</label>
            <Controller
              control={control}
              name="supplierId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={providersList}
                  placeholder="Proveedor"
                  value={value}
                  error={errors.supplierId}
                  onChange={onChange}
                />
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          <div className="new-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Contrato del proveedor:
            </label>
            <Controller
              control={control}
              name="supplierContractId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={supplierContractList}
                  placeholder="CCV12312"
                  value={value}
                  error={errors.supplierContractId}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-invoice__basic-data-input">
            <Controller
              control={control}
              name="invoiceNumber"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  key="invoiceNumber"
                  name="invoiceNumber"
                  placeholder="Nº Factura"
                  label="Nº Factura"
                  defaultValue={value}
                  error={errors.invoiceNumber}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="new-invoice__basic-data-custom">
          <div className={inputDateClassNames}>
            <label>Selecciona una fecha:</label>
            <Controller
              control={control}
              name="date"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.date}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="new-contract__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          {/* <div className="new-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              IVA:
            </label>
            <Controller
              control={control}
              name="vat"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={IVA_OPTIONS}
                  placeholder="IVA"
                  value={value}
                  canSearch={false}
                  error={errors.vat}
                  onChange={onChange}
                />
              )}
            />
          </div> */}
          <div className="new-invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Pedidos:
            </label>
            <Controller
              control={control}
              name="orderId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={ordersList}
                  placeholder="Pedido"
                  value={value}
                  error={errors.orderId}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        {!!mappedLineItems.length && mappedLineItems[0]?.qualityName ? (
          <Table
            rowsData={mappedLineItems}
            headCells={INVOICES_LINE_ITEMS_HEAD_CELLS}
            ignoreColumn={'id'}
            targetItem="id"
            showDelete={false}
            showSearchBar={false}
            stickyColumns={['batches']}
            onCellValueChange={onUpdate}
          />
        ) : (
          <div></div>
        )}
        <div className="new-contract__footer">
          <Button
            type="submit"
            update
            disabled={
              !supplierId ||
              !supplierContractId ||
              !orderId ||
              fetching ||
              !lineItems?.some((item) => Boolean(item.batch))
            }
            loading={submitting}
            text="Registrar factura de proveedor"
          />
        </div>
      </form>
    </>
  );
};

export default NewInvoiceForm;
