import PersonalData from './PersonalData';
import ContactData from './ContactData';
import WarehouseData from './WarehouseData';

const Datos = ({ provider, onSubmit }) => (
  <>
    <PersonalData provider={provider} />
    <ContactData
      providerId={provider.id}
      contacts={provider.SupplierContacts}
      onSubmit={onSubmit}
    />
    <WarehouseData
      providerId={provider.id}
      warehouses={provider.SupplierWarehouseAddresses}
      onSubmit={onSubmit}
    />
  </>
);

export default Datos;
