import { useRef } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Context */
import useAuth from 'context/useAuth';
/** Hooks */
import useClickOutside from 'hooks/useClickOutside';
/** Styles */
import './Header.scss';

const Header = () => {
  const actionsRef = useRef(null);
  const [isActive, setIsActive] = useClickOutside(actionsRef, false);
  const { user, logout } = useAuth();

  const signOut = () => {
    logout();
  };

  const actionClases = cx('UserActionsHidden', {
    UserActionsWrapper: isActive,
  });

  const isActionOpen = cx('IconNormal', {
    IconActive: isActive,
  });

  const togglerMenu = () => {
    setIsActive((isActiveState) => !isActiveState);
  };

  return (
    <div className="header">
      <div ref={actionsRef} className="actions">
        {user ? (
          <div className="UserName" onClick={togglerMenu}>
            <span className="UserNameText">
              {user.name || user.email || 'Usuario'}
            </span>
            <FontAwesomeIcon icon="angle-down" className={isActionOpen} />
            <div className={actionClases}>
              <button onClick={signOut} className="signin">
                Cerrar sesión
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
