import { useState, useEffect } from 'react';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import Modal from 'components/Modal';
import QualityList from './QualityList';
import NewQualityForm from 'pages/Qualities/NewQualityForm';
/** Services */
import { getAllQualities, getAllOrigins } from 'services/qualities';
import { getAllProviders } from 'services/providers';
/** Utils */
import { createToast } from 'utils/toast';
/** Styles */
import styles from './Qualities.module.scss';

const Qualities = () => {
  const [qualities, setQualities] = useState([]);
  const [varieties, setVarieties] = useState({});
  const [origins, setOrigins] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);

  useEffect(() => {
    setShouldFetch(false);

    getAllQualities()
      .then(({ status, data: { parsedVarieties = [] } = {} }) => {
        if (status === 200) {
          const normalizedQualities = parsedVarieties
            .map(({ qualities = [] }) => qualities)
            .flat();
          const normalizedVarieties = parsedVarieties.reduce(
            (acc, { id, name }) => {
              acc[id] = name;
              return acc;
            },
            {}
          );
          setQualities(normalizedQualities);
          setVarieties(normalizedVarieties);
        }
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({ text: 'No existen las calidades', type: 'error' });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, [shouldFetch]);

  useEffect(() => {
    const fetchOrigins = async () => {
      try {
        const { data } = (await getAllOrigins()) ?? {};
        const { fetchedOrigins } = data;
        fetchedOrigins && setOrigins(fetchedOrigins);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchSuppliers = async () => {
      try {
        const { data } = (await getAllProviders()) ?? {};
        const { fetchedSuppliers } = data;
        fetchedSuppliers && setProviders(fetchedSuppliers);
      } catch (err) {
        console.error(err);
      }
    };
    fetchOrigins();
    fetchSuppliers();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Qualities}>
      <div className={styles.QualitiesHeader}>
        <h1 className="title">Calidades</h1>
        <Button text="Crear nueva" update click={() => setShowModal(true)} />
      </div>
      <>
        <Modal
          show={showModal}
          title={'Añadir nueva calidad'}
          id={'new-quality'}
          successButton={'Crear'}
          onClose={() => setShowModal(false)}
          showFooter={false}
        >
          <NewQualityForm
            varieties={varieties}
            origins={origins}
            providers={providers}
            closeModalHandler={() => setShowModal(false)}
            reloadQualities={() => setShouldFetch(true)}
          />
        </Modal>
        <div className={styles.QualityList}>
          <QualityList
            qualities={qualities}
            varieties={varieties}
            origins={origins}
            providers={providers}
            reloadQualities={(qualities) => setQualities(qualities)}
          />
        </div>
      </>
    </div>
  );
};

export default Qualities;
