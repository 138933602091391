import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Services */
import { deleteOrigin } from 'services/qualities.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/**Constants */
import { ORIGINS_HEAD_CELLS } from 'constants/qualities.js';
/** Styles */
import styles from './OriginsList.module.scss';

const OriginsList = ({ origins = [], reloadOrigins }) => {
  const navigate = useNavigate();

  const mappedOrigins = origins.map(({ id, name = '' }) => {
    return {
      ...(id && { id }),
      ...(name ? { name } : { name: 'Sin nombre' }),
    };
  });

  const handleSelectOrigin = (originId) => {
    navigate(`/origenes/${originId}`);
  };

  const deleteHandler = (originId) => {
    deleteOrigin(originId)
      .then(({ status }) => {
        if (status === 204 || status === 200) {
          createToast({
            text: 'Origen eliminado con éxito',
            type: 'success',
            duration: 2500,
          });
          const updatedOrigins = origins.filter(({ id }) => {
            if (typeof originId === 'object') {
              return !originId.includes(id);
            }
            return id !== originId;
          });
          reloadOrigins(updatedOrigins);
        }
      })
      .catch((error) => {
        console.error(error);
        createToast({
          text: 'No se ha podido eliminar el origen, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {origins.length ? (
        <Table
          rowsData={mappedOrigins}
          headCells={ORIGINS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectOrigin}
          deleteHandler={(value) => deleteHandler(value)}
        />
      ) : (
        <div className={styles.EmptyState}>No hay datos.</div>
      )}
    </>
  );
};

export default OriginsList;
