import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Input from 'components/Input';
import Button from 'components/Button';
import Back from 'components/Back';
import Loader from 'components/Loader';
/** Services */
import { createReplacement, getReplacement } from 'services/contracts';
/** Styles */
import './NewReplacement.scss';

const Contract = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [reposition, setReposition] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
  } = useForm({ defaultValues: { contractDate: new Date() } });

  const contractNumber = watch('contractNumber');
  const contractDate = watch('contractDate');
  const units = watch('units');
  const price = watch('price');

  const completaData = useMemo(
    () => contractNumber && contractDate && units && price,
    [contractNumber, contractDate, units, price]
  );

  const inputDateClassNames = cx('new-replacement__basic-date', {
    'new-replacement__basic-date--error': !!errors.contractDate,
  });

  useEffect(() => {
    setLoading(true);

    getReplacement(id)
      .then(({ status, data }) => {
        if (status === 200) {
          setReposition(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const saveReplacement = async () => {
    setSubmitting(true);

    if (getValues().units > reposition.quantity) {
      createToast({
        text: `No puedes reponer más stock del que hay pendiente (máx. ${reposition.quantity})`,
        type: 'error',
      });
      return;
    }

    const payload = { ...reposition, ...getValues() };

    return createReplacement(payload)
      .then(() => {
        createToast({
          text: 'Reposición de stock creada con éxito',
          type: 'success',
        });
        navigate('/proveedores/reposicion');
      })
      .catch(({ status }) => {
        setSubmitting(false);

        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    try {
      if (!completaData) {
        throw Error('Datos incompletos');
      }

      if (errors && Object.keys(errors).length) {
        throw Error('Campos incorrectos');
      }

      if (!data?.units) {
        throw Error('Debes asignar una cantidad');
      }

      if (reposition.quantity < data?.units) {
        throw Error('No puedes asignar más cantidad de la disponible');
      }
    } catch (err) {
      createToast({
        text: err?.message || 'Ha habido un error. Revisa los campos',
        type: 'error',
      });
      return;
    }

    saveReplacement();
  };
  const onError = (error) => console.error(error);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Back text="Volver a reposiciones" path="/proveedores/reposicion/" />

      <form
        className="new-replacement"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <h2 className="new-replacement__header">Reposición</h2>
        <div>
          <p>
            <b>Contrato cliente: </b>
            {reposition.originContractNumber}
          </p>
          <p>
            <b>Cliente: </b>
            {reposition.clientName}
          </p>
          <p>
            <b>Proveedor: </b>
            {reposition.supplierName}
          </p>
          <p>
            <b>Calidad: </b>
            {reposition.qualityName}
          </p>
          <p>
            <b>Cantidad pendiente de reponer: </b>
            {reposition.quantity}
          </p>
        </div>
        <hr />
        <br />
        <h3>Datos del contrato de reposición:</h3>
        <br />
        <div className="new-replacement__basic-data">
          <div className="new-replacement__basic-data-input">
            <Controller
              control={control}
              name="contractNumber"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  id="contractNumber"
                  label="Número de contrato del proveedor"
                  name="contractNumber"
                  placeholder="ES902022"
                  type="text"
                  autoComplete="off"
                  className="InputName"
                  error={errors.contractNumber}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className={inputDateClassNames}>
            <label>Fecha contrato proveedor:</label>
            <Controller
              control={control}
              name="contractDate"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.contractDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.contractDate && (
              <span className="new-replacement__error-message">
                {errors.contractDate.message}
              </span>
            )}
          </div>
          <div className="new-replacement__basic-data-input">
            <Controller
              control={control}
              name="units"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  id="units"
                  label="Cantidad"
                  name="units"
                  type="text"
                  autoComplete="off"
                  className="InputName"
                  error={errors.units}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="new-replacement__basic-data-input">
            <Controller
              control={control}
              name="price"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  id="price"
                  label="Precio de compra"
                  name="price"
                  type="text"
                  autoComplete="off"
                  className="InputName"
                  error={errors.price}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="new-replacement__footer">
          <Button
            type="submit"
            update
            loading={submitting}
            disabled={!completaData}
            text="Crear reposición"
          />
        </div>
      </form>
    </>
  );
};

export default Contract;
