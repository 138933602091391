import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/** Components */
import Loader from 'components/Loader';
import Tabs from 'components/Tabs';
import Back from 'components/Back';
import Datos from './Datos';
import Contratos from './Contratos';
import Pedidos from './Pedidos';
import Facturas from './Facturas';
import Abonos from './Abonos';
import Proformas from './Proformas';
/** Services */
import { getClient } from 'services/clients';
/** Styles */
import styles from './Client.module.scss';

const getClientTabs = (client, handleSubmit) => [
  {
    id: 'data',
    name: 'Datos',
    component: <Datos client={client} onSubmit={handleSubmit} />,
  },
  {
    id: 'contracts',
    name: 'Contratos',
    component: <Contratos client={client.id} />,
  },
  { id: 'bills', name: 'Facturas', component: <Facturas client={client.id} /> },
  {
    id: 'proformas',
    name: 'Proformas',
    component: <Proformas client={client.id} />,
  },
  {
    id: 'bill-invoice',
    name: 'Abonos',
    component: <Abonos client={client.id} />,
  },
  { id: 'orders', name: 'Pedidos', component: <Pedidos client={client.id} /> },
];

const Client = () => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const { data } = (await getClient(id)) ?? {};
        const { fetchedClient } = data;
        fetchedClient && setClient(fetchedClient);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchClient();
  }, [id]);

  const handleSubmit = () => {
    const fetchClient = async () => {
      try {
        const { data } = (await getClient(id)) ?? {};
        const { fetchedClient } = data;
        fetchedClient && setClient(fetchedClient);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchClient();
  };

  if (loading) return <Loader />;

  return (
    <>
      <Back text="Volver a Clientes" path="/clientes" />
      <div className={styles.ClientContainer}>
        {client ? (
          <Tabs tabs={getClientTabs(client, handleSubmit)} />
        ) : (
          <div className={styles.ClientEmpty}>
            No se ha encontrado el cliente.
          </div>
        )}
      </div>
    </>
  );
};

export default Client;
