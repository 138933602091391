import { useEffect, useState } from 'react';
/** Components */
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
/** Constants */
import {
  USER_PERMISSIONS_FORM,
  USER_PERMISSIONS_FORM_DATA,
  USER_PERMISSION_MAP,
} from 'constants/users.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Services */
import { updatePermissions } from 'services/users.js';
/** Styles */
import styles from './EditRoles.module.scss';

const EditRoles = ({ userId, permissions }) => {
  const [submitting, setSubmitting] = useState(false);
  const [permissionData, setPermissionsData] = useState(
    USER_PERMISSIONS_FORM_DATA
  );

  useEffect(() => {
    setPermissionsData(permissions);
  }, [permissions]);

  /** Handler to set value */
  const changeHandler = (id, key, value) => {
    setPermissionsData((prev) => {
      const data = prev;
      data[id][key] = value;
      return data;
    });
  };

  /** Handler to save permisions */
  const submitHandler = (e) => {
    e.preventDefault();
    setSubmitting(true);
    updatePermissions({ id: userId, permissions: permissionData })
      .then(({ status }) => {
        createToast({
          text: 'Permisos modificados con éxito',
          type: 'success',
          duration: 2500,
        });
        setSubmitting(false);
      })
      .catch(() => {
        createToast({
          text: 'No se han podido modificar los permisos, intentalo de nuevo más tarde',
          type: 'error',
        });
        setSubmitting(false);
      });
  };
  return (
    <>
      <form onSubmit={submitHandler} className={styles.PermissionsForm}>
        {USER_PERMISSIONS_FORM.map(({ name, id, roles }) => (
          <div key={id} className={styles.PermissionContainer}>
            <h3 className={styles.PermissioTitle}>{name}</h3>
            <section id={id} className={styles.PermissionWrapper}>
              {Object.entries(roles).map(([key]) => (
                <Checkbox
                  key={`${id}_${key}`}
                  id={`${id}_${key}`}
                  value={(permissions[id] && permissions[id][key]) || false}
                  onChange={(value) => changeHandler(id, key, value)}
                >
                  {USER_PERMISSION_MAP[key]}
                </Checkbox>
              ))}
            </section>
          </div>
        ))}
        <Button update loading={submitting} text="Actualizar" />
      </form>
    </>
  );
};

export default EditRoles;
