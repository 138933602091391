export const PAYMENTS_HEAD_CELLS = [
  {
    id: 'label',
    numeric: false,
    disablePadding: false,
    label: 'Pago',
  },
  {
    id: 'privateNotes',
    numeric: false,
    disablePadding: false,
    label: 'Notas privadas',
  },
  {
    id: 'publicNotes',
    numeric: false,
    disablePadding: false,
    label: 'Notas públicas',
  },
];
