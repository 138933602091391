import { useState, useMemo } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import Checkbox from 'components/Checkbox';
import Loader from 'components/Loader';
/** Services */
import { createQuality } from 'services/qualities.js';
/** Constants */
import {
  NEW_QUALITY_DATA,
  QUALITY_TYPES,
  QUALITY_PACKS,
} from 'constants/qualities';
/** Styles */
import styles from './NewQualityForm.module.scss';

const NewQualityForm = ({
  varieties,
  origins,
  providers,
  closeModalHandler,
  reloadQualities,
}) => {
  const [formData, setFormData] = useState(NEW_QUALITY_DATA);
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const varietyList = Object.entries(varieties).map(([id, name]) => ({
    id,
    name,
  }));

  const isDisabledSendButton = useMemo(() => {
    const {
      OriginId,
      QualityVarietyId,
      SupplierId,
      name,
      quantity,
      tareWeight,
    } = formData;
    return !(
      OriginId &&
      QualityVarietyId &&
      SupplierId &&
      name &&
      quantity &&
      tareWeight
    );
  }, [formData]);

  const changeHandler = ({ target: { name, value, type } }) => {
    const normalizedValue = type === 'number' ? Number(value) : value;
    setFormData({
      ...formData,
      [name]:
        typeof normalizedValue === 'string' ? value.trim() : normalizedValue,
    });
  };

  const handleChangeVariety = (value) => {
    setFormData({
      ...formData,
      QualityVarietyId: value,
    });
  };

  const handleChangeOrigin = (value) => {
    setFormData({
      ...formData,
      OriginId: value,
    });
  };

  const handleChangeSupplier = (value) => {
    setFormData({
      ...formData,
      SupplierId: value,
    });
  };

  const changeTypeHandler = (id, value) => {
    const type = { ...formData.type };
    type[id] = value;
    setFormData((prev) => {
      return { ...prev, type };
    });
  };

  const handleChangePack = (value) => {
    setFormData({
      ...formData,
      packType: value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setSubmitting(true);

    createQuality(formData)
      .then(({ status }) => {
        if ([200, 201].includes(status)) {
          createToast({
            text: 'Se ha creado la calidad con éxito',
            type: 'success',
            duration: 2500,
          });
          closeModalHandler();
          reloadQualities();
          setSubmitting(false);
        }
      })
      .catch(({ status }) => {
        setSubmitting(false);

        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {isLoaded ? (
        <div className={styles.FormContainer}>
          <form
            id="new-client-form"
            className={styles.FormWrapper}
            onSubmit={submitHandler}
          >
            <div className={styles.FormGroup}>
              <Input
                id="name"
                label="Nombre café"
                name="name"
                placeholder="Sin nombre"
                type="text"
                autoComplete="off"
                className={styles.InputName}
                onChange={changeHandler}
              />
              <Input
                id="quantity"
                label="Envase (kg)"
                name="quantity"
                placeholder="0"
                type="number"
                className={styles.InputQuantity}
                onChange={changeHandler}
              />
            </div>
            <div className={styles.FormGroup}>
              <div className={styles.FormField}>
                <label>Variedad</label>
                <Select
                  options={varietyList}
                  placeholder="Sin variedad"
                  value={formData.QualityVarietyId}
                  onChange={handleChangeVariety}
                />
              </div>
              <div className={styles.FormField}>
                <label>Origen</label>
                <Select
                  options={origins}
                  placeholder="Sin origen"
                  value={formData.OriginId}
                  onChange={handleChangeOrigin}
                />
              </div>
              <Input
                id="tareWeight"
                label="Tara (kg)"
                name="tareWeight"
                placeholder="0"
                type="number"
                min="0"
                step="0.1"
                className={styles.InputTara}
                onChange={changeHandler}
              />
              <div className={styles.FormField}>
                <label>Tipo de envase</label>
                <Select
                  options={QUALITY_PACKS}
                  placeholder="Sin envase seleccionado"
                  value={formData.packType}
                  onChange={handleChangePack}
                />
              </div>
            </div>
            <div className={styles.FormGroupCheckboxes}>
              {QUALITY_TYPES.map(({ id, label }) => (
                <Checkbox
                  key={`create_quality_${id}`}
                  id={id}
                  onChange={(value) => changeTypeHandler(id, value)}
                >
                  {label}
                </Checkbox>
              ))}
            </div>
            <div className={styles.FormGroup}>
              <div className={styles.FormField}>
                <label>Proveedor</label>
                <Select
                  options={providers}
                  placeholder="Sin proveedor"
                  value={formData.SupplierId}
                  onChange={handleChangeSupplier}
                />
              </div>
            </div>
            <div className={styles.FormButtonWrapper}>
              <Button
                cancel
                text="Cancelar"
                type="button"
                click={closeModalHandler}
              />
              <Button
                submit
                loading={submitting}
                text="Añadir"
                disabled={isDisabledSendButton}
              />
            </div>
          </form>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default NewQualityForm;
