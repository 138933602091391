import axiosWrapper, { baseUrl } from './core';

const BASE_URL = '/api/suppliers';

export const createProvider = (params) =>
  axiosWrapper.put(`${baseUrl}${BASE_URL}/create-supplier`, params);

export const getAllProviders = () =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-all-suppliers`);

export const getProvider = (id) =>
  axiosWrapper.get(`${baseUrl}${BASE_URL}/get-supplier-by-id/${id}`);

export const updateProvider = (params) =>
  axiosWrapper.patch(`${baseUrl}${BASE_URL}/update-supplier`, params);

export const deleteProvider = (params) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete-supplier`, {
    data: { id: params },
  });

export const createProviderContactData = (params) =>
  axiosWrapper.put(
    `${baseUrl}${BASE_URL}/create-supplier-contact-data`,
    params
  );

export const updateProviderContactData = (params) =>
  axiosWrapper.patch(
    `${baseUrl}${BASE_URL}/update-supplier-contact-data`,
    params
  );

export const deleteProviderContactData = (params) =>
  axiosWrapper.delete(`${baseUrl}${BASE_URL}/delete-supplier-contact-data`, {
    data: { id: params },
  });

export const createWarehouseData = (params) =>
  axiosWrapper.put(
    `${baseUrl}${BASE_URL}/create-supplier-warehouse-address`,
    params
  );

export const updateWarehouseData = (params) =>
  axiosWrapper.patch(
    `${baseUrl}${BASE_URL}/update-supplier-warehouse-address`,
    params
  );

export const deleteWarehouse = (params) =>
  axiosWrapper.delete(
    `${baseUrl}${BASE_URL}/delete-supplier-warehouse-address`,
    {
      data: { id: params },
    }
  );
