/** Components */
import XLSXLink from 'components/XLSXLink';
/** Constants */
import { DOWNLOAD_METADATA } from 'constants/download';

export const NEW_CLIENT_DATA = {
  name: '',
  managementId: '',
  NIF: '',
  VAT: false,
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
  email: '',
};

export const CREATE_CLIENT_FORM = [
  {
    id: 'name',
    label: 'Nombre',
    type: 'text',
    placeholder: 'Nombre',
  },
  {
    id: 'managementId',
    label: 'Código de gestión',
    type: 'text',
    placeholder: 'Código de gestión',
  },
  {
    id: 'NIF',
    label: 'NIF',
    type: 'text',
    placeholder: 'NIF',
  },
  {
    id: 'country',
    label: 'Pais',
    type: 'text',
    placeholder: 'Pais',
  },
  {
    id: 'city',
    label: 'Ciudad',
    type: 'text',
    placeholder: 'Ciudad',
  },
  {
    id: 'zipCode',
    label: 'Código postal',
    type: 'text',
    placeholder: 'Código portal',
  },
  {
    id: 'addressFirstLine',
    label: 'Primera línea dirección',
    type: 'text',
    placeholder: 'Primera línea dirección',
  },
  {
    id: 'addressSecondLine',
    label: 'Segunda línea dirección',
    type: 'text',
    placeholder: 'Segunda línea dirección',
  },
];

export const CLIENTS_HEAD_CELLS = [
  {
    id: 'clientId',
    numeric: false,
    disablePadding: false,
    label: 'ID.',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nombre',
  },
  {
    id: 'NIF',
    numeric: false,
    disablePadding: false,
    label: 'NIF',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Pais',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'Ciudad',
  },
  {
    id: 'mainContactName',
    numeric: false,
    disablePadding: false,
    label: 'Contacto principal',
  },
  {
    id: 'mainContactPhone',
    numeric: false,
    disablePadding: false,
    label: 'Teléfono contacto principal',
  },
];

export const MODIFIY_CLIENT_FORM_DATA = {
  id: '',
  name: '',
  NIF: '',
  VAT: false,
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
};

export const CLIENT_CONTACT_FORM_DATA = {
  clientId: '',
  identifyingName: '',
  mainContactName: '',
  secondaryContactName: '',
  mainContactPhone: '',
  secondaryContactPhone: '',
  mainContactEmail: '',
  secondaryContactEmail: '',
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
};

export const CREATE_CONTACT_CLIENT_FORM = [
  {
    id: 'identifyingName',
    label: 'Nombre ficha de contacto',
    type: 'text',
    placeholder: 'Contacto del almacén',
  },
  {
    id: 'mainContactName',
    label: 'Contacto principal',
    type: 'text',
    placeholder: 'Eduardo',
  },
  {
    id: 'mainContactPhone',
    label: 'Teléfono principal',
    type: 'text',
    placeholder: '666123123',
  },
  {
    id: 'mainContactEmail',
    label: 'Correo electrónico principal',
    type: 'text',
    placeholder: 'principal@gmail.com',
  },
  {
    id: 'secondaryContactName',
    label: 'Contacto secundario',
    type: 'text',
    placeholder: 'Rafael',
  },
  {
    id: 'secondaryContactPhone',
    label: 'Teléfono secundario',
    type: 'text',
    placeholder: '666123123',
  },
  {
    id: 'secondaryContactEmail',
    label: 'Correo electrónico secundario',
    type: 'text',
    placeholder: 'secundario@gmail.com',
  },
  {
    id: 'country',
    label: 'País',
    type: 'text',
    placeholder: 'Francia',
  },
  {
    id: 'city',
    label: 'Ciudad',
    type: 'text',
    placeholder: 'Paris',
  },
  {
    id: 'zipCode',
    label: 'Código postal',
    type: 'text',
    placeholder: '75000',
  },
  {
    id: 'addressFirstLine',
    label: 'Primera línea de dirección',
    type: 'text',
    placeholder: 'Paris 10e Arrondissement',
  },
  {
    id: 'addressSecondLine',
    label: 'Segunda línea de dirección',
    type: 'text',
    placeholder: 'Bajo',
  },
];

export const CLIENT_SEND_FORM_DATA = {
  id: '',
  name: '',
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
};

export const CREATE_SEND_CLIENT_FORM = [
  {
    id: 'name',
    label: 'Nombre de la dirección',
    type: 'text',
    placeholder: 'Almacén de valencia',
  },
  {
    id: 'country',
    label: 'País',
    type: 'text',
    placeholder: 'Francia',
  },
  {
    id: 'city',
    label: 'Ciudad',
    type: 'text',
    placeholder: 'Paris',
  },
  {
    id: 'zipCode',
    label: 'Código postal',
    type: 'text',
    placeholder: '75000',
  },
  {
    id: 'addressFirstLine',
    label: 'Primera línea de dirección',
    type: 'text',
    placeholder: 'Paris 10e Arrondissement',
  },
  {
    id: 'addressSecondLine',
    label: 'Segunda línea de dirección',
    type: 'text',
    placeholder: 'Bajo',
  },
];

export const NEW_CONTRACT_DATA = {
  contractDate: new Date(),
  contractFooter: '',
  contractDetails: [],
  palletIsIncluded: false,
  transportIsIncluded: false,
  ClientId: '',
};

export const CONTRACT_HEAD_CELLS = [
  {
    id: 'contractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº de contrato',
  },
  {
    id: 'ClientId',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'contractDate',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de contrato',
    isDate: true,
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    label: 'Descargar',
    render: ({ metadata: { fileId, key } }) => {
      const { url, filename } = DOWNLOAD_METADATA(fileId)[key];
      return {
        component: XLSXLink,
        props: {
          url,
          filename,
        },
      };
    },
  },
];

export const ORDERS_HEAD_CELLS = [
  {
    id: 'clientName',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de pedido',
    isDate: true,
  },
  {
    id: 'paymentTypeName',
    numeric: false,
    disablePadding: false,
    label: 'Método de pago',
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    label: 'Descargar',
    render: ({ metadata: { fileId, key } }) => {
      const { url, filename } = DOWNLOAD_METADATA(fileId)[key];
      return {
        component: XLSXLink,
        props: {
          url,
          filename,
        },
      };
    },
  },
];

export const INVOICES_HEAD_CELLS = [
  {
    id: 'invoiceNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº de factura',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
    isDate: true,
  },
  // {
  //   id: 'supplierId',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Proveedor',
  // },
  {
    id: 'basePrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio base',
    suffix: '€',
  },
  {
    id: 'totalPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio total',
    suffix: '€',
  },
  {
    id: 'vat',
    numeric: false,
    disablePadding: false,
    label: 'IVA',
    suffix: '%',
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    label: 'Descargar',
    render: ({ metadata: { fileId, key } }) => {
      const { url, filename } = DOWNLOAD_METADATA(fileId)[key];
      return {
        component: XLSXLink,
        props: {
          url,
          filename,
        },
      };
    },
  },
];

export const PROFORMAS_HEAD_CELLS = [
  {
    id: 'invoiceNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº de factura',
  },
  // {
  //   id: 'order',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Número de pedido',
  // },
  {
    id: 'client',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'invoiceDate',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
    isDate: true,
  },
  {
    id: 'basePrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio base',
    suffix: '€',
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    label: 'Descargar',
    render: ({ metadata: { fileId, key } }) => {
      const { url, filename } = DOWNLOAD_METADATA(fileId)[key];
      return {
        component: XLSXLink,
        props: {
          url,
          filename,
        },
      };
    },
  },
];

export const MODIFIY_CONTRACT_FORM_DATA = {
  id: '',
  contractNumber: '',
  ...NEW_CONTRACT_DATA,
};

export const LINE_ITEM_DATA = {
  QualityId: '',
  units: 0,
  price: 0,
  term: null,
  stockOrigin: '',
  supplierContractId: '',
};

export const LINE_ITEM_DATA_FORM = [
  {
    id: 'units',
    label: 'Unidades',
    type: 'number',
    placeholder: 'Escríbe las unidades',
    errorMessage: 'Has superado el stock disponible',
  },
  {
    id: 'price',
    label: 'Precio (€)',
    type: 'number',
    placeholder: 'Escríbe el precio',
    errorMessage: 'El campo es obligatorio',
  },
  {
    id: 'term',
    label: 'Mes de retiro',
    type: 'date',
    placeholder: 'Plazo/Mes de retiro',
  },
];
