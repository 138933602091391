import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Components */
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Switch from 'components/Switch';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Button from 'components/Button';
import Back from 'components/Back';
import LineItems from '../Contracts/LineItems';
/** Services */
import { getAllClients } from 'services/clients';
import {
  getClientContract,
  updateClientContract,
  removeClientContracts,
  getSuppliersContracts,
} from 'services/contracts';
/** Utils */
import { createToast } from 'utils/toast';
/** Styles */
import './Contract.scss';

const normalizeContract = ({ Client = { id: '' }, ...contract }) => ({
  ...contract,
  ClientId: Client.id,
});

const normalizeLineItems = (lineItems) =>
  lineItems.map(
    ({
      id,
      units,
      price,
      term,
      QualityId,
      stockOrigin,
      supplierContractId,
    }) => ({
      id,
      units,
      price,
      term,
      QualityId,
      stockOrigin,
      supplierContractId,
    })
  );

const Contract = () => {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [providerContracts, setProviderContracs] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [safeDelete, setSafeDelete] = useState(false);
  const [errorDetails, setErrorDetails] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: { contractDate: new Date() },
  });

  const contractId = watch('id');

  const inputDateClassNames = cx('new-contract__basic-date', {
    'new-contract__basic-date--error': !!errors.contractDate,
  });

  useEffect(() => {
    const fetchContract = async () => {
      try {
        const { data } = (await getClientContract(id)) ?? {};
        const { contract } = data;
        if (contract) {
          // Set client and line items
          const { ContDetClis, ...rest } = contract;
          setLineItems(ContDetClis);
          // Set contract
          const normalizedContract = normalizeContract(rest);
          reset(normalizedContract);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    const fetchClients = async () => {
      try {
        const { data } = (await getAllClients()) ?? {};
        const { fetchedClients } = data;
        fetchedClients && setClients(fetchedClients);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchSuppliersContracts = async () => {
      try {
        const { data } = (await getSuppliersContracts()) ?? {};
        const { suppliersContracts } = data;
        const normalizedContracts = suppliersContracts.map((contract) => {
          return { ...contract, name: contract.contractNumber };
        });
        setProviderContracs(normalizedContracts);
      } catch (err) {
        console.error(err);
      }
    };
    fetchSuppliersContracts();
    fetchClients();
    fetchContract();
  }, [id]);

  const updateContract = (data) => {
    setLoading(true);

    const payload = {
      ...data,
      contractDetails: normalizeLineItems(lineItems),
    };

    updateClientContract(payload)
      .then(({ status }) => {
        if (status === 200) {
          createToast({
            text: 'Contrato modificado con éxito',
            type: 'success',
            duration: 2500,
          });
          setTimeout(() => {
            navigate('/clientes/contratos');
          }, 500);
        }
      })
      .catch(() => {
        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  const removeHandler = () => {
    if (safeDelete) {
      removeClientContracts(contractId)
        .then(({ status }) => {
          if (status === 204) {
            createToast({
              text: 'Contrato eliminado con éxito',
              type: 'success',
              duration: 2500,
            });
          }
          setTimeout(() => {
            navigate('/clientes/contratos');
          }, 500);
        })
        .catch(() => {
          createToast({
            text: 'No se ha podido eliminar el contrato, inténtalo de nuevo más tarde',
            type: 'error',
          });
        });
      return;
    }

    setSafeDelete(true);
    setTimeout(() => {
      setSafeDelete(false);
    }, 3000);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    if (!lineItems.length) {
      setErrorDetails(true);
      return;
    }

    if (errors && Object.keys(errors).length) return;

    // updateContract(data);
  };

  const updateStockHandler = (lineItemData, isUpdate = false) => {
    const { QualityId, units, supplierContractId } = lineItemData;
    const normalizedUnits = Math.floor(units);
    const contractlineItems =
      providerContracts.find(({ id }) => id === supplierContractId)?.details ??
      [];
    const lineItem =
      contractlineItems.find(
        ({ QualityId: lineItemQualityId }) => lineItemQualityId === QualityId
      ) ?? {};
    if (isUpdate) {
      lineItem.assignedStock = lineItemData.assignedStock + normalizedUnits;
    }
    const stock =
      lineItem.assignedStock >= lineItem.units
        ? lineItem.units
        : isUpdate
        ? lineItem.assignedStock
        : lineItem.assignedStock + normalizedUnits;

    updateContracts({
      contractlineItems,
      lineItemData,
      lineItem,
      stock,
    });
  };

  const resetStockHandler = (lineItemData) => {
    const { QualityId, units, supplierContractId } = lineItemData;
    const normalizedUnits = Math.floor(units);
    const contractlineItems =
      providerContracts.find(({ id }) => id === supplierContractId)?.details ??
      [];
    const lineItem =
      contractlineItems.find(
        ({ QualityId: lineItemQualityId }) => lineItemQualityId === QualityId
      ) ?? {};

    updateContracts({
      contractlineItems,
      lineItemData,
      lineItem,
      stock: lineItem.assignedStock - normalizedUnits,
    });
  };

  const updateContracts = ({
    contractlineItems,
    lineItemData,
    lineItem,
    stock,
  }) => {
    const { QualityId, supplierContractId } = lineItemData;
    const lineItemIndex = contractlineItems.findIndex(
      ({ QualityId: lineItemQualityId }) => lineItemQualityId === QualityId
    );
    if (lineItemIndex === -1) return;
    contractlineItems[lineItemIndex] = {
      ...lineItem,
      assignedStock: stock,
    };
    const contractIndex = providerContracts.findIndex(
      ({ id }) => id === supplierContractId
    );
    if (contractIndex === -1) return;
    const newProviderContracts = [...providerContracts];
    newProviderContracts[contractIndex].details = [...contractlineItems];
    setProviderContracs(newProviderContracts);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back text="Volver a Contratos de Clientes" path="/clientes/contratos/" />

      <form className="new-contract" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="new-contract__header">Visualización de contrato</h2>
        <div className="new-contract__basic-data">
          <div className="new-contract__basic-data-input">
            <Controller
              control={control}
              name="contractNumber"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  id="contractNumber"
                  label="Número de contrato"
                  name="contractNumber"
                  placeholder="ES902022"
                  type="text"
                  autoComplete="off"
                  disabled={true}
                  className="InputName"
                  error={errors.contractNumber}
                  defaultValue={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className={inputDateClassNames}>
            <label>Fecha:</label>
            <Controller
              control={control}
              name="contractDate"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    disabled={true}
                    error={errors.contractDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.contractDate && (
              <span className="new-contract__error-message">
                {errors.contractDate.message}
              </span>
            )}
          </div>
          <div className="new-contract__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Selecciona un cliente:
            </label>
            <Controller
              control={control}
              name="ClientId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clients}
                  placeholder="Cliente"
                  value={value}
                  error={errors.ClientId}
                  disabled={true}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="new-contract__switches">
          <Controller
            control={control}
            name="palletIsIncluded"
            render={({ field: { value, onChange } }) => (
              <Switch
                id="palletIsIncluded"
                value={value}
                onChange={onChange}
                disabled={true}
              >
                Paletizado
              </Switch>
            )}
          />
          <Controller
            control={control}
            name="transportIsIncluded"
            render={({ field: { value, onChange } }) => (
              <Switch
                id="transportIsIncluded"
                value={value}
                disabled={true}
                onChange={onChange}
              >
                Transporte incluido
              </Switch>
            )}
          />
        </div>
        <LineItems
          lineItems={lineItems}
          providerContracts={providerContracts}
          setLineItems={setLineItems}
          onUpdateStock={updateStockHandler}
          onResetStock={resetStockHandler}
          error={errorDetails}
        />
      </form>
    </>
  );
};

export default Contract;
