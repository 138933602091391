import { useEffect, useRef, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePopper } from 'react-popper';
import cx from 'classnames';
/** Components */
import SearchBar from '../SearchBar';
/** Styles */
import styles from './Select.module.scss';
import { createPortal } from 'react-dom';

const SelectEmpty = ({ text }) => (
  <li className={styles.selectOption}>{text}</li>
);

const Select = ({
  options = [],
  textProperty = 'name',
  valueProperty = 'id',
  value = '',
  onChange,
  onExtraChange,
  disabled = false,
  placeholder = 'Select an option',
  className = '',
  canSearch = true,
  error,
}) => {
  const triggerRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectWidth, setSelectWidth] = useState(0);
  const [query, setQuery] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
    }
  );

  const optionsStyles = {
    ...popperStyles.popper,
    width: selectWidth ? `${selectWidth}px` : undefined,
  };

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        (option[textProperty] ?? '').toLowerCase().includes(query)
      ),
    [query, textProperty, options]
  );

  useEffect(() => {
    const onReferenceClick = (e) => {
      // If the active element exists and is clicked outside of
      if (referenceElement && !referenceElement?.contains(e.target)) {
        setIsActive(e.target.classList.contains('MuiInputBase-input'));
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener('click', onReferenceClick);
    }

    return () => {
      window.removeEventListener('click', onReferenceClick);
    };
  }, [isActive, referenceElement]);

  useEffect(() => {
    if (triggerRef.current) {
      const triggerElementWidth = triggerRef.current.offsetWidth;
      setSelectWidth(triggerElementWidth);
    }
  }, [triggerRef]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleTriggerClick = () => {
    if (disabled) return;
    if (triggerRef.current) {
      const triggerElementWidth = triggerRef.current.offsetWidth;
      setSelectWidth(triggerElementWidth);
    }
    setIsActive((isActiveState) => !isActiveState);
  };

  const handleOptionChange = (option) => {
    setIsActive((isActiveState) => !isActiveState);

    if (value === option[valueProperty]) return;

    setSelectedValue(option[valueProperty]);
    onChange && onChange(option[valueProperty]);
    onExtraChange && onExtraChange(option[valueProperty]);
  };

  const handleSearch = (value) => {
    setQuery(value);
  };

  const renderSelectedOption = () => {
    // If there are no options or there isn't a selected value yet, display placeholder
    if (!options.length || !selectedValue)
      return <div className={styles.selectPlaceholder}>{placeholder}</div>;

    const selectedOption = options.find(
      (option) => option[valueProperty] === selectedValue
    );

    if (!selectedOption) return <div />;

    return (
      <div className={styles.selectValue}>{selectedOption[textProperty]}</div>
    );
  };

  const selectWrapperClasses = cx(styles.selectWrapper, {
    [className]: !!className,
  });

  const selectTriggerClasses = cx(styles.selectTrigger, {
    [styles.selectTriggerDisabled]: disabled,
    [styles.selectTriggerError]: !!error,
  });

  const selectCaretClasses = cx(styles.selectCaret, {
    [styles.selectCaretVisible]: isActive,
  });

  const selectOptionsClasses = cx(styles.selectOptionsWrapper, {
    [styles.selectOptionsWrapperActive]: isActive,
  });

  return (
    <div className={styles.select}>
      <div ref={setReferenceElement} className={selectWrapperClasses}>
        <div
          ref={triggerRef}
          onClick={handleTriggerClick}
          className={selectTriggerClasses}
        >
          {renderSelectedOption()}
          <FontAwesomeIcon icon="angle-down" className={selectCaretClasses} />
        </div>
        {createPortal(
          <div
            ref={setPopperElement}
            className={selectOptionsClasses}
            style={optionsStyles}
            {...attributes.popper}
          >
            {canSearch && (
              <SearchBar
                debounce={100}
                onChange={handleSearch}
                className={styles.selectSearchbar}
              />
            )}
            <ul className={styles.selectOptions}>
              {options?.length ? (
                filteredOptions.length ? (
                  filteredOptions.map((option) => {
                    const selectOptionClasses = cx(styles.selectOption, {
                      [styles.selectOptionActive]:
                        option[valueProperty] === selectedValue,
                    });
                    return (
                      <li
                        key={option[valueProperty]}
                        className={selectOptionClasses}
                        onClick={() => handleOptionChange(option)}
                      >
                        {option[textProperty]}
                      </li>
                    );
                  })
                ) : (
                  <SelectEmpty text="No hay resultados." />
                )
              ) : (
                <SelectEmpty text="No hay datos." />
              )}
            </ul>
          </div>,
          document.body
        )}
      </div>
      {error && error.message && (
        <span className={styles.selectErrorMessage}>{error.message}</span>
      )}
    </div>
  );
};

export default Select;
