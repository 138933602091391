import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import TransfersList from './TransfersList';
/** Services */
import { getAllAssignments } from 'services/contracts';
/** Utils */
import { createToast } from 'utils/toast';
/** Styles */
import './Transfers.scss';

const Transfers = () => {
  const [transferList, setTransferList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllAssignments()
      .then(({ status, data: { contracts } }) => {
        if (status === 200) {
          setTransferList(contracts);
        }
        setLoading(false);
      })
      .catch((err) => {
        const feedback = 'Algo ha salido mal';
        setLoading(false);
        createToast({
          text: feedback,
          type: 'error',
        });
      }, []);
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="transfers">
      <div className="transfers__header">
        <h1 className="transfers__header-title">Cesiones de stock</h1>
        <NavLink to="/clientes/cesion/nuevo">
          <Button update text="Crear nueva cesión de stock" />
        </NavLink>
      </div>
      <div className="transfers__list">
        <TransfersList transfers={transferList} />
      </div>
    </div>
  );
};

export default Transfers;
