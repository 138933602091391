import { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Back from 'components/Back';
import Select from 'components/Select';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Table from 'components/Table';
/** Services */
import { getSupplierInvoice, updateSupplierInvoice } from 'services/invoices';
import { getAllProviders } from 'services/providers';
import { getSuppliersContracts } from 'services/contracts';
import { getSupplierOrders } from 'services/orders';
/** Constants */
import {
  INVOICES_LINE_ITEMS_HEAD_CELLS,
  IVA_OPTIONS,
} from 'constants/providers';
/** Styles */
import './Invoice.scss';

const Invoice = () => {
  const [providersList, setProvidersList] = useState([]);
  const [supplierContractList, setSupplierContractList] = useState([]);
  const [orders, setOrders] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm({ defaultValues: { vat: 10, date: new Date() } });

  const mappedLineItems = useMemo(() => {
    const activeLineItems = lineItems.filter((detail) =>
      Boolean(detail?.units)
    );
    return activeLineItems.map(
      ({
        boughtStockId: id,
        qualityName = '',
        qualityId,
        units = 0,
        packCapacity = 0,
        pricePerKilogram: price = 0,
        // batches: batch,
        batch,
        // batchId,
        // batchName,
      }) => {
        return {
          ...(qualityName ? { qualityName } : { qualityName: 'Sin calidad' }),
          ...(units ? { units } : { units: 0 }),
          ...(packCapacity ? { packCapacity } : { packCapacity: 0 }),
          ...(price ? { price } : { price: 0 }),
          ...(batch ? { batch } : { batch: undefined }),
          // ...(batchId
          //   ? { batch: [{ id: batchId, batch: batchName }] }
          //   : { batch: undefined }),
          ...(qualityId ? { qualityId } : { qualityId: 0 }),
          ...(id && { id }),
        };
      }
    );
  }, [lineItems]);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const { data } = (await getSupplierInvoice(id)) ?? {};
        console.log(111, data);
        const {
          date,
          boughtStockId,
          details,
          invoiceId,
          invoiceNumber,
          orderId,
          orderName,
          supplierContractId,
          supplierContractNumber,
          supplierId,
          supplierName,
        } = data;

        // const additionalConcepts = details.find(({ qualityId }) => !qualityId);

        reset({
          invoiceId,
          supplierName,
          supplierContractNumber,
          invoiceNumber,
          orderName,
          orderId,
          date,
          // ...(additionalConcepts && {
          //   description: additionalConcepts.description,
          //   price: additionalConcepts.totalPrice,
          // }),
        });
        const activeDetails = details.filter((detail) =>
          Boolean(detail?.units)
        );
        console.log(2222, activeDetails);
        setLineItems(
          activeDetails.map(({ batchId, batchName, ...detail }) => ({
            ...detail,
            batch: [
              {
                id: boughtStockId,
                batch: batchName,
                quantity: detail.units,
              },
            ],
          }))
        );
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  // Handler to update an invoice
  const editInvoice = ({ invoiceId, description, price, ...data }) => {
    setSubmitting(true);

    // const additionalConcepts = { description, price: parseFloat(price) };

    const normalizedLineItems = lineItems.map(
      ({
        qualityId,
        batch,
        units,
        packCapacity,
        price: pricePerKg,
        boughtStockId,
        orderDetailId,
        pricePerKilogram,
      }) => ({
        ...(qualityId && { qualityId }),
        batches: batch
          ? batch.map(({ batch, quantity }) => ({ batch, quantity }))
          : [{}],
        units: Number(units),
        packCapacity,
        pricePerKg: pricePerKg ? Number(pricePerKg) : pricePerKilogram,
        boughtStockId,
        orderDetailId,
      })
    );

    const payload = {
      id: invoiceId,
      ...data,
      details: [...normalizedLineItems /* additionalConcepts */],
    };

    updateSupplierInvoice(payload)
      .then(() => {
        createToast({
          text: 'Factura de proveedor actualizada con éxito',
          type: 'success',
        });
        navigate('/proveedores/facturas');
      })
      .catch(({ status }) => {
        setSubmitting(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  };

  // Handler to update order item
  const onUpdate = (itemId, { name, value }) => {
    const targetItem = lineItems.find(
      ({ boughtStockId }) => boughtStockId === itemId
    );

    let parsedValue = value;
    if (name === 'batch' && value?.length === 1 && !value?.[0]?.quantity) {
      parsedValue = [
        {
          batch: value?.[0]?.batch,
          quantity: targetItem.units,
        },
      ];
    }

    if (name === 'batch' && !targetItem.units) {
      createToast({
        text: 'Guarda una cantidad antes de configurar sus lotes',
        type: 'error',
      });
      return;
    }

    const index = lineItems.findIndex(({ boughtStockId: id }) => id === itemId);
    const target = lineItems[index];
    if (name === 'units') {
      delete target?.batch;
    }
    const item = {
      ...target,
      [name]: parsedValue,
    };

    setLineItems([
      ...lineItems.slice(0, index),
      item,
      ...lineItems.slice(index + 1),
    ]);
  };

  // Handle submit
  const onSubmit = (data, event) => {
    event.preventDefault();

    if (errors && Object.keys(errors).length) return;

    editInvoice(data);
  };

  // Classes to make error for dates
  const inputDateClassNames = cx('invoice__basic-date', {
    'invoice__basic-date--error': !!errors.date,
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back
        text="Volver a las facturas de proveedor"
        path="/proveedores/facturas"
      />

      <form className="invoice" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="invoice__header">Editar factura de proveedor</h2>
        <div className="invoice__basic-data-custom">
          <div className="invoice__basic-data-input">
            <label>Proveedor:</label>
            <Controller
              control={control}
              name="supplierName"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder="Proveedor"
                  value={value}
                  error={errors.supplierId}
                  onChange={onChange}
                  disabled
                />
              )}
            />
            {errors?.date && (
              <span className="invoice__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          <div className="invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Contrato del proveedor:
            </label>
            <Controller
              control={control}
              name="supplierContractNumber"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder="Proveedor"
                  value={value}
                  error={errors.supplierContractId}
                  onChange={onChange}
                  disabled
                />
              )}
            />
          </div>
          <div className="invoice__basic-data-input">
            <Controller
              control={control}
              name="invoiceNumber"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  key="invoiceNumber"
                  name="invoiceNumber"
                  placeholder="Nº Factura"
                  label="Nº Factura"
                  defaultValue={value}
                  error={errors.invoiceNumber}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="invoice__basic-data-custom">
          <div className={inputDateClassNames}>
            <label>Selecciona una fecha:</label>
            <Controller
              control={control}
              name="date"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.date}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.date && (
              <span className="invoice__error-message">
                {errors.date.message}
              </span>
            )}
          </div>
          {/* <div className="invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              IVA:
            </label>
            <Controller
              control={control}
              name="vat"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={IVA_OPTIONS}
                  placeholder="IVA"
                  value={value}
                  canSearch={false}
                  error={errors.vat}
                  onChange={onChange}
                />
              )}
            />
          </div> */}
          <div className="invoice__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Pedido:
            </label>
            <Controller
              control={control}
              name="orderName"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder="Pedido"
                  value={value}
                  error={errors.orderId}
                  onChange={onChange}
                  disabled
                />
              )}
            />
          </div>
        </div>
        {!!lineItems.length && (
          <>
            <Table
              rowsData={mappedLineItems}
              headCells={INVOICES_LINE_ITEMS_HEAD_CELLS}
              ignoreColumn="id"
              targetItem="id"
              showDelete={false}
              showSearchBar={false}
              stickyColumns={['batches']}
              onCellValueChange={onUpdate}
            />
            {/* <div className="invoice__basic-data invoice__order-lines-extra">
              <div className="invoice__basic-data-input">
                <Controller
                  control={control}
                  name="description"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="description"
                      placeholder="Descripción"
                      label="Descripción:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="invoice__basic-data-input">
                <Controller
                  control={control}
                  name="price"
                  render={({ field: { value, onChange } }) => (
                    <Input
                      name="price"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="Precio"
                      label="Precio:"
                      defaultValue={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div> */}
          </>
        )}
        <div className="invoice__footer">
          <Button
            type="submit"
            update
            loading={submitting}
            text="Actualizar factura de proveedor"
            disabled={!lineItems?.some((item) => Boolean(item.batch))}
          />
        </div>
      </form>
    </>
  );
};

export default Invoice;
