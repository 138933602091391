import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
import Loader from 'components/Loader';
/** Services */
import { getOrders } from 'services/orders';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { ORDERS_HEAD_CELLS } from 'constants/clients';
/** Styles */
import './Pedidos.scss';

const Pedidos = ({ client }) => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrders()
      .then(({ data }) => {
        const filteredOrders = data.filter(
          ({ clientId }) => clientId === client
        );
        setOrders(filteredOrders);
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen pedidos del cliente',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  const mappedOrders = orders.map(
    ({ clientName = '', date = '', paymentTypeName = '', orderId: id }) => {
      return {
        ...(clientName ? { clientName } : { clientName: 'Sin cliente' }),
        ...(date ? { date } : { date: 'Sin fecha' }),
        ...(paymentTypeName
          ? { paymentTypeName }
          : { paymentTypeName: 'Sin método de pago' }),
        metadata: { fileId: id, key: 'clientOrder' },
        id,
      };
    }
  );

  const handleSelectContract = (orderId) => {
    navigate(`/pedidos/${orderId}`);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {orders.length !== 0 ? (
        <Table
          rowsData={mappedOrders}
          headCells={ORDERS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          setItem={handleSelectContract}
        />
      ) : (
        <div className="EmptyState">No hay pedidos.</div>
      )}
    </>
  );
};

export default Pedidos;
