import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Input from 'components/Input';
import Textarea from 'components/Textarea';
import Switch from 'components/Switch';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Button from 'components/Button';
import Back from 'components/Back';
import LineItems from '../LineItems';
/** Services */
import { getAllClients } from 'services/clients';
import {
  createClientContract,
  getSuppliersContracts,
  getClientContractDefaultFooter,
} from 'services/contracts';
/** Styles */
import './NewClientContract.scss';

const NewClientContract = () => {
  const [clientList, setClientList] = useState([]);
  const [providerContracts, setProviderContracs] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorDetails, setErrorDetails] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: { contractDate: new Date() },
  });

  const inputDateClassNames = cx('new-contract__basic-date', {
    'new-contract__basic-date--error': !!errors.contractDate,
  });

  useEffect(() => {
    getAllClients()
      .then(({ status, data: { fetchedClients } }) => {
        if (status === 200) {
          setClientList(fetchedClients);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    getSuppliersContracts()
      .then(({ status, data: { suppliersContracts } }) => {
        if (status === 200) {
          const normalizedContracts = suppliersContracts.map((contract) => {
            return { ...contract, name: contract.contractNumber };
          });
          setProviderContracs(normalizedContracts);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    getClientContractDefaultFooter()
      .then(({ status, data: { fetchedClientContractDefaultFooter } }) => {
        if (status === 200) {
          reset({
            contractDate: new Date(),
            contractFooter: fetchedClientContractDefaultFooter?.content,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Handler to create a contract
  const createContract = (data) => {
    setLoading(true);

    const payload = {
      ...data,
      contractDetails: lineItems,
    };

    createClientContract(payload)
      .then(({ status }) => {
        if (status === 201) {
          createToast({
            text: 'Contrato creado con éxito',
            type: 'success',
          });
          navigate('/clientes/contratos');
        }
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    if (!lineItems.length) {
      setErrorDetails(true);
      return;
    }

    if (errors && Object.keys(errors).length) return;

    createContract(data);
  };

  const updateStockHandler = (lineItemData, isUpdate = false) => {
    const { QualityId, units, supplierContractId } = lineItemData;
    const normalizedUnits = Math.floor(units);
    const contractlineItems =
      providerContracts.find(({ id }) => id === supplierContractId)?.details ??
      [];
    const lineItem =
      contractlineItems.find(
        ({ QualityId: lineItemQualityId }) => lineItemQualityId === QualityId
      ) ?? {};
    if (isUpdate) {
      lineItem.assignedStock = lineItemData.assignedStock + normalizedUnits;
    }
    const stock =
      lineItem.assignedStock >= lineItem.units
        ? lineItem.units
        : isUpdate
        ? lineItem.assignedStock
        : lineItem.assignedStock + normalizedUnits;

    updateContracts({
      contractlineItems,
      lineItemData,
      lineItem,
      stock,
    });
  };

  const resetStockHandler = (lineItemData) => {
    const { QualityId, units, supplierContractId } = lineItemData;
    const normalizedUnits = Math.floor(units);
    const contractlineItems =
      providerContracts.find(({ id }) => id === supplierContractId)?.details ??
      [];
    const lineItem =
      contractlineItems.find(
        ({ QualityId: lineItemQualityId }) => lineItemQualityId === QualityId
      ) ?? {};

    updateContracts({
      contractlineItems,
      lineItemData,
      lineItem,
      stock: lineItem.assignedStock - normalizedUnits,
    });
  };

  const updateContracts = ({
    contractlineItems,
    lineItemData,
    lineItem,
    stock,
  }) => {
    const { QualityId, supplierContractId } = lineItemData;
    const lineItemIndex = contractlineItems.findIndex(
      ({ QualityId: lineItemQualityId }) => lineItemQualityId === QualityId
    );
    if (lineItemIndex === -1) return;
    contractlineItems[lineItemIndex] = {
      ...lineItem,
      assignedStock: stock,
    };
    const contractIndex = providerContracts.findIndex(
      ({ id }) => id === supplierContractId
    );
    if (contractIndex === -1) return;
    const newProviderContracts = [...providerContracts];
    newProviderContracts[contractIndex].details = [...contractlineItems];
    setProviderContracs(newProviderContracts);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Back text="Volver a Contratos de clientes" path="/clientes/contratos/" />

      <form className="new-contract" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="new-contract__header">Nuevo contrato</h2>
        <div className="new-contract__basic-data">
          <div className="new-contract__basic-data-input">
            <Input
              id="contractNumber"
              label="Número de contrato"
              name="contractNumber"
              placeholder="Ejemplo: ES902022"
              type="text"
              autoComplete="off"
              className="InputName"
              disabled={true}
            />
          </div>
          <div className={inputDateClassNames}>
            <label>Selecciona una fecha:</label>
            <Controller
              control={control}
              name="contractDate"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    error={errors.contractDate}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {errors?.contractDate && (
              <span className="new-contract__error-message">
                {errors.contractDate.message}
              </span>
            )}
          </div>
          <div className="new-contract__basic-data-input">
            <label className="NewContractFormRightProviderSelectTitle">
              Selecciona un cliente:
            </label>
            <Controller
              control={control}
              name="ClientId"
              rules={{ required: 'El campo es obligatorio' }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={clientList}
                  placeholder="Cliente"
                  value={value}
                  error={errors.ClientId}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="new-contract__switches">
          <Controller
            control={control}
            name="palletIsIncluded"
            render={({ field: { value, onChange } }) => (
              <Switch id="palletIsIncluded" value={value} onChange={onChange}>
                Paletizado
              </Switch>
            )}
          />
          <Controller
            control={control}
            name="transportIsIncluded"
            render={({ field: { value, onChange } }) => (
              <Switch
                id="transportIsIncluded"
                value={value}
                onChange={onChange}
              >
                Transporte incluido
              </Switch>
            )}
          />
        </div>
        <LineItems
          lineItems={lineItems}
          providerContracts={providerContracts}
          setLineItems={setLineItems}
          error={errorDetails}
          onUpdateStock={updateStockHandler}
          onResetStock={resetStockHandler}
        />
        <div className="new-contract__bottom-fields">
          <Controller
            control={control}
            name="contractFooter"
            rules={{ required: 'El campo es obligatorio' }}
            render={({ field: { value, onChange } }) => (
              <Textarea
                id="contractFooter"
                label="Pie de página"
                name="contractFooter"
                placeholder="Escribe un pie de página para el contrato"
                type="text"
                rows="10"
                error={errors.contractFooter}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="new-contract__footer">
          <Button type="submit" update text="Crear contrato" />
        </div>
      </form>
    </>
  );
};

export default NewClientContract;
