/** Services */
import { setToken } from 'services/core';

const TOKEN_EXPIRATION_DATE = new Date(
  new Date().getTime() + 60 * 60 * 24 * 1000
);

export const getCurrentUser = async () => {
  try {
    const { token, currentUser, expiration } = JSON.parse(
      localStorage.getItem('userData')
    );
    if (token && currentUser && new Date(expiration) > new Date()) {
      setToken(token);
      return currentUser;
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const setCurrentUser = ({ name, email, role }, token) => {
  localStorage.setItem(
    'userData',
    JSON.stringify({
      token,
      currentUser: { name, email, role },
      expiration: TOKEN_EXPIRATION_DATE.toISOString(),
    })
  );
};

export const renameCurrentUser = (name) => {
  try {
    const { token, currentUser, expiration } = JSON.parse(
      localStorage.getItem('userData')
    );
    localStorage.setItem(
      'userData',
      JSON.stringify({
        token,
        expiration,
        currentUser: { name, email: currentUser.email },
      })
    );
  } catch (err) {
    console.error(err);
  }
};

export const removeTokens = () => {
  localStorage.removeItem('userData');
  setToken();
};
