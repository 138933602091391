import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import esLocale from 'date-fns/locale/es';
import { useForm, Controller } from 'react-hook-form';
import cx from 'classnames';
/** Constants */
import { LINE_ITEM_DATA_FORM, LINE_ITEM_DATA } from 'constants/clients';
/** Components */
import Button from 'components/Button';
import Select from 'components/Select';
import Input from 'components/Input';
/** Styles */
import './LineItemForm.scss';

const LineItemForm = ({
  lineItemData = {},
  cancelEdit,
  providerContracts,
  onSubmit,
}) => {
  const [lineItemDataForm, setLineItemDataForm] = useState(LINE_ITEM_DATA);
  const [providerLineItems, setProviderLineItems] = useState([]);
  const [lineItemStock, setLineItemStock] = useState({});
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
  } = useForm({
    defaultValues: { term: null },
  });

  const inputDateClassNames = cx('LineItemNewLineFormDate', {
    LineItemNewLineFormDateError: !!errors.term,
  });

  useEffect(() => {
    if (!!Object.entries(lineItemData).length) {
      const { supplierContractId, QualityId, units } = lineItemData;
      const normalizedUnits = Math.floor(units);
      const providerContract =
        providerContracts.find(({ id }) => id === supplierContractId) ?? {};
      const normalizedLineItems = (providerContract.details ?? []).map(
        (lineItem) => {
          return {
            ...lineItem,
            name: lineItem.quality.name,
          };
        }
      );
      const lineItem =
        normalizedLineItems.find(
          ({ QualityId: lineItemQualityId }) => QualityId === lineItemQualityId
        ) ?? {};
      const updatedStock = lineItem.assignedStock - normalizedUnits;
      setProviderLineItems(normalizedLineItems);
      const { providerContracts: contracts, ...rest } = lineItemData;
      setLineItemDataForm({
        ...rest,
        assignedStock: updatedStock,
      });
      reset({
        ...rest,
        assignedStock: updatedStock,
      });
      setLineItemStock({
        availableStock: lineItem.units - updatedStock,
        assignedStock: updatedStock,
        totalUnits: lineItem.units,
      });
    }
  }, [lineItemData]);

  // Handler to set the provider line item data
  const changeProviderLineItem = (value) => {
    const provider = providerContracts.find(({ id }) => id === value);
    const normalizedProvider = provider.details.map((lineItem) => {
      return {
        ...lineItem,
        name: lineItem.quality.name,
      };
    });
    setProviderLineItems(normalizedProvider);
    setLineItemDataForm({
      ...lineItemDataForm,
      supplierContractId: value,
      QualityId: '',
      stockOrigin: 0,
    });
    reset({
      ...getValues(),
      supplierContractId: value,
      QualityId: '',
      stockOrigin: 0,
    });
    setLineItemStock({});
  };

  // Handler to set date quality in to lineItem
  const changeQualityHandler = (value) => {
    const stockOrigin = providerLineItems.find(
      ({ QualityId }) => QualityId === value
    )?.id;
    const stock =
      providerLineItems.find(({ QualityId }) => QualityId === value) ?? {};
    const { assignedStock = 0, units = 0 } = stock;

    setLineItemStock({
      availableStock: units - assignedStock,
      assignedStock: assignedStock,
      totalUnits: units,
    });

    setLineItemDataForm({
      ...lineItemDataForm,
      QualityId: value,
      stockOrigin,
    });

    reset({
      ...getValues(),
      QualityId: value,
      stockOrigin,
    });
  };

  const onSubmitHandler = (data) => {
    if (errors && Object.keys(errors).length) return;

    onSubmit(data);
  };

  return (
    <div className="LineItemNewLine">
      {!!Object.keys(lineItemStock).length && (
        <span className="LineItemNewLineStock">
          <b>
            Stock disponible para esta línea basado en la calidad seleccionada:
          </b>{' '}
          {lineItemStock.availableStock}/{lineItemStock.totalUnits} Unidades
        </span>
      )}
      <div className="LineItemNewLineForm">
        <div className="LineItemNewLineFormSelect">
          <label className="LineItemNewLineFormLabel">
            Selecciona un contrato
          </label>
          <Controller
            control={control}
            name="supplierContractId"
            rules={{ required: 'El campo es obligatorio' }}
            render={({ field: { value, onChange } }) => (
              <Select
                options={providerContracts}
                placeholder="Selecciona un contrato"
                value={value}
                error={errors.supplierContractId}
                onChange={onChange}
                onExtraChange={changeProviderLineItem}
              />
            )}
          />
        </div>
        <div className="LineItemNewLineFormSelect">
          <label className="LineItemNewLineFormLabel">
            Selecciona una calidad
          </label>
          <Controller
            control={control}
            name="QualityId"
            rules={{ required: 'El campo es obligatorio' }}
            render={({ field: { value, onChange } }) => (
              <Select
                options={providerLineItems}
                placeholder="Selecciona una calidad"
                valueProperty="QualityId"
                value={value}
                error={errors.QualityId}
                onChange={onChange}
                onExtraChange={changeQualityHandler}
              />
            )}
          />
        </div>
        {LINE_ITEM_DATA_FORM.map(
          ({ id, label, type, placeholder, errorMessage }) =>
            type === 'date' ? (
              <div className={inputDateClassNames} key={id}>
                <label>{label}</label>
                <Controller
                  control={control}
                  name={id}
                  rules={{ required: 'El campo es obligatorio' }}
                  render={({ field: { value, onChange } }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={esLocale}
                    >
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        error={errors[id]}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  )}
                />
                {errors[id] && (
                  <span className="LineItemNewLineFormDateErrorMessage">
                    {errors[id].message}
                  </span>
                )}
              </div>
            ) : (
              <Controller
                key={id}
                control={control}
                name={id}
                rules={{
                  required: errorMessage,
                  validate: (value) => {
                    const normalizedValue = Math.floor(value);
                    if (id === 'units') {
                      const isValid =
                        normalizedValue &&
                        normalizedValue <= lineItemStock.availableStock;
                      if (isValid) return true;
                      return normalizedValue
                        ? errorMessage
                        : 'No puede ser cero';
                    }
                    return normalizedValue > 0 || errorMessage;
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    id={id}
                    label={label}
                    name={id}
                    placeholder={placeholder}
                    type={type}
                    autoComplete="off"
                    className="InputName"
                    error={errors[id]}
                    defaultValue={value}
                    max={
                      id === 'units' ? lineItemStock.availableStock : undefined
                    }
                    onChange={onChange}
                  />
                )}
              />
            )
        )}
      </div>
      <div className="LineItemFormAction">
        <Button cancel text="Cancelar" click={cancelEdit} />
        <Button update text="Guardar" click={handleSubmit(onSubmitHandler)} />
      </div>
    </div>
  );
};

export default LineItemForm;
