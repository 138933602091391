import { useState, useEffect } from 'react';
/** Components */
import Table from 'components/Table';
import Loader from 'components/Loader';
/** Services */
import { getAllProformas } from 'services/clients';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { PROFORMAS_HEAD_CELLS } from 'constants/clients';
/** Styles */
import './Proformas.scss';

const Proformas = ({ client }) => {
  const [proformas, setProformas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllProformas()
      .then(({ data }) => {
        const filteredProformas = data.filter(
          ({ ClientId }) => ClientId === client
        );
        setProformas(filteredProformas);
        setLoading(false);
      })
      .catch(({ status, response }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen proformas del cliente',
            type: 'error',
          });
          return;
        }

        createToast({
          text: response.data.message ?? 'Algo ha salido mal',
          type: 'error',
        });
      });
  }, []);

  const mappedProformas = proformas.map(
    ({
      invoiceNumber = '',
      invoiceDate,
      basePrice,
      Client,
      vat,
      id,
      Order,
    }) => {
      return {
        ...(invoiceNumber
          ? { invoiceNumber }
          : { invoiceNumber: 'Sin nº de factura' }),
        order: Order.id,
        client: Client.name,
        ...(invoiceDate ? { invoiceDate } : { invoiceDate: 'Sin fecha' }),
        ...(basePrice ? { basePrice } : { basePrice: 'Sin precio base' }),
        metadata: { fileId: id, key: 'clientProforma' },
        ...(id && { id }),
      };
    }
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      {proformas.length !== 0 ? (
        <Table
          rowsData={mappedProformas}
          headCells={PROFORMAS_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
        />
      ) : (
        <div className="EmptyState">No hay proformas.</div>
      )}
    </>
  );
};

export default Proformas;
