import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/** Components */
import Loader from 'components/Loader';
import Tabs from 'components/Tabs';
import Back from 'components/Back';
import Datos from './Datos';
import Contratos from './Contratos';
import Invoices from './Invoices';
import BillInvoices from './BillInvoices';
import Orders from './Orders';
/** Services */
import { getProvider } from 'services/providers';
/** Styles */
import styles from './Provider.module.scss';

const getProviderTabs = (provider, handleSubmit) => [
  {
    id: 'data',
    name: 'Datos',
    component: <Datos provider={provider} onSubmit={handleSubmit} />,
  },
  {
    id: 'contracts',
    name: 'Contratos',
    component: <Contratos provider={provider.id} />,
  },
  {
    id: 'invoices',
    name: 'Facturas',
    component: <Invoices provider={provider} />,
  },
  {
    id: 'abonos',
    name: 'Abonos',
    component: <BillInvoices provider={provider} />,
  },
  {
    id: 'pedidos',
    name: 'Pedidos',
    component: <Orders provider={provider} />,
  },
];

const Provider = () => {
  const [provider, setProvider] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const { data } = (await getProvider(id)) ?? {};
        const { fetchedSupplier } = data;
        fetchedSupplier && setProvider(fetchedSupplier);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchSupplierData();
  }, [id]);

  const handleSubmit = () => {
    const fetchClient = async () => {
      try {
        const { data } = (await getProvider(id)) ?? {};
        const { fetchedSupplier } = data;
        fetchedSupplier && setProvider(fetchedSupplier);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchClient();
  };

  if (loading) return <Loader />;

  return (
    <>
      <Back text="Volver a Proveedores" path="/proveedores" />
      <div className={styles.ProviderContainer}>
        {provider ? (
          <Tabs tabs={getProviderTabs(provider, handleSubmit)} />
        ) : (
          <div className={styles.ProviderEmpty}>
            No se ha encontrado el proveedor.
          </div>
        )}
      </div>
    </>
  );
};

export default Provider;
