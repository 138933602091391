import { useState } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
/** Services */
import { signUp } from 'services/users.js';
/** Constants */
import { NEW_USER_INITIAL_DATA, NEW_USER_FORM } from 'constants/users';
/** Styles */
import styles from './NewUserForm.module.scss';

const NewUser = ({ closeModalHandler, reloadUsers }) => {
  const [formData, setFormData] = useState(NEW_USER_INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  const changeHandler = ({ target: { name, value } }) => {
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    signUp({
      email: formData.email,
    })
      .then(({ status }) => {
        if (status === 201) {
          closeModalHandler();
          reloadUsers();
          setFormData(NEW_USER_INITIAL_DATA);
          createToast({
            text: 'Usuario creado con éxito',
            type: 'success',
            duration: 2500,
          });
        }
      })
      .catch(({ status, response }) => {
        setLoading(false);
        if (status === 400) {
          createToast({ text: response.data.feedback, type: 'error' });
          return;
        }
        createToast({
          text: 'Algo ha ido mal, intentalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <div className={styles.FormContainer}>
      <p className={styles.FormContainerText}>
        Introduce el correo electrónico del usuario al que deseas invitar
      </p>
      <form
        id="new-user-form"
        className={styles.FormWrapper}
        onSubmit={submitHandler}
      >
        <div className={styles.FormField} key={NEW_USER_FORM.id}>
          <Input
            id={NEW_USER_FORM.id}
            label={NEW_USER_FORM.label}
            name={NEW_USER_FORM.id}
            placeholder={NEW_USER_FORM.label}
            type={NEW_USER_FORM.type}
            onChange={changeHandler}
          />
        </div>
        <div className={styles.FormButtonWrapper}>
          <Button
            cancel
            text="Cancelar"
            type="button"
            click={closeModalHandler}
          />
          <Button
            submit
            disabled={!formData.email}
            loading={loading}
            text="Crear"
          />
        </div>
      </form>
    </div>
  );
};

export default NewUser;
