import cx from 'classnames';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Styles */
import './SideBar.scss';

const SideBar = () => {
  const [compact, setCompact] = useState(false);

  const compactToggler = () => {
    setCompact(!compact);
  };

  const SideBarClasses = cx('SideBar', {
    SideBarOpen: compact,
  });

  const SubmenuClasses = cx('SideBarItemSubmenu', {
    SideBarItemSubmenuOpen: compact,
  });

  return (
    <div className={SideBarClasses}>
      <div className="SideBarTop">
        <div className="SideBarLogo">
          <NavLink to="/dashboard">
            <img
              src={`${process.env.PUBLIC_URL}/img/logos/delicoffee-logo-simple.png`}
              alt="Logo"
            />
          </NavLink>
        </div>
        <div className="SideBarMenu">
          <div className="SideBarItem">
            <NavLink
              to="/pedidos"
              className={({ isActive }) => {
                return cx('SideBarItemLink', {
                  SideBarItemLinkActive: isActive,
                });
              }}
            >
              <FontAwesomeIcon
                icon="shopping-cart"
                className="SideBarItemIcon"
              />
              {compact && <span className="SideBarItemText">Pedidos</span>}
            </NavLink>
          </div>
          <div className="SideBarItem">
            <NavLink
              to="/clientes"
              className={({ isActive }) => {
                return cx('SideBarItemLink', {
                  SideBarItemLinkActive: isActive,
                });
              }}
            >
              <FontAwesomeIcon icon="users" className="SideBarItemIcon" />
              {compact && <span className="SideBarItemText">Clientes</span>}
            </NavLink>
            <div className={SubmenuClasses}>
              <div className="SideBarSubmenuContainer">
                <NavLink to="/clientes" className="SideBarItemSubmenuItem">
                  Ver todos
                </NavLink>
                <NavLink
                  to="/clientes/contratos"
                  className="SideBarItemSubmenuItem"
                >
                  Contratos
                </NavLink>
                <NavLink
                  to="/clientes/cesion"
                  className="SideBarItemSubmenuItem"
                >
                  Cesión
                </NavLink>
                <NavLink
                  to="/clientes/facturas"
                  className="SideBarItemSubmenuItem"
                >
                  Facturas
                </NavLink>
                <NavLink
                  to="/clientes/proformas"
                  className="SideBarItemSubmenuItem"
                >
                  Proformas
                </NavLink>
                <NavLink
                  to="/clientes/facturas-de-abonos"
                  className="SideBarItemSubmenuItem"
                >
                  Abonos
                </NavLink>
              </div>
            </div>
          </div>
          <div className="SideBarItem">
            <NavLink
              to="/proveedores"
              className={({ isActive }) => {
                return cx('SideBarItemLink', {
                  SideBarItemLinkActive: isActive,
                });
              }}
            >
              <FontAwesomeIcon icon="truck" className="SideBarItemIcon" />
              {compact && <span className="SideBarItemText">Proveedores</span>}
            </NavLink>
            <div className={SubmenuClasses}>
              <div className="SideBarSubmenuContainer">
                <NavLink to="/proveedores" className="SideBarItemSubmenuItem">
                  Ver todos
                </NavLink>
                <NavLink
                  to="/proveedores/contratos"
                  className="SideBarItemSubmenuItem"
                >
                  Contratos
                </NavLink>
                <NavLink
                  to="/proveedores/facturas"
                  className="SideBarItemSubmenuItem"
                >
                  Facturas
                </NavLink>
                <NavLink
                  to="/proveedores/abonos"
                  className="SideBarItemSubmenuItem"
                >
                  Abonos
                </NavLink>
                <NavLink
                  to="/proveedores/reposicion"
                  className="SideBarItemSubmenuItem"
                >
                  Reposición
                </NavLink>
              </div>
            </div>
          </div>
          <div className="SideBarItem">
            <NavLink
              to="/calidades"
              className={({ isActive }) => {
                return cx('SideBarItemLink', {
                  SideBarItemLinkActive: isActive,
                });
              }}
            >
              <FontAwesomeIcon icon="coffee" className="SideBarItemIcon" />
              {compact && <span className="SideBarItemText">Calidades</span>}
            </NavLink>
            <div className={SubmenuClasses}>
              <div className="SideBarSubmenuContainer">
                <NavLink to="/calidades" className="SideBarItemSubmenuItem">
                  Ver todas
                </NavLink>
                <NavLink to="/origenes" className="SideBarItemSubmenuItem">
                  Orígenes
                </NavLink>
              </div>
            </div>
          </div>
          <div className="SideBarItem">
            <NavLink
              to="/pagos"
              className={({ isActive }) => {
                return cx('SideBarItemLink', {
                  SideBarItemLinkActive: isActive,
                });
              }}
            >
              <FontAwesomeIcon icon="credit-card" className="SideBarItemIcon" />
              {compact && <span className="SideBarItemText">Pagos</span>}
            </NavLink>
          </div>
          <div className="SideBarItem">
            <NavLink
              to="/usuarios"
              className={({ isActive }) => {
                return cx('SideBarItemLink', {
                  SideBarItemLinkActive: isActive,
                });
              }}
            >
              <FontAwesomeIcon icon="user-edit" className="SideBarItemIcon" />
              {compact && <span className="SideBarItemText">Usuarios</span>}
            </NavLink>
          </div>
        </div>
      </div>
      <div className="SideBarBottom">
        <div className="SideBarItem">
          <div className="SideBarItemLink" onClick={compactToggler}>
            <FontAwesomeIcon icon="bars" className="SideBarItemIcon" />
            {compact && <span className="SideBarItemText">Menu</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
