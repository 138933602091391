import { useState } from 'react';
/** Context */
import useAuth from 'context/useAuth';
/** Utils */
import { validateEmail } from 'utils/email.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
/** Constants */
import {
  SIGNIN_INITIAL_DATA,
  RESET_PASSWORD_INITIAL_DATA,
  SIGN_IN_FORM,
  RESET_PASSWORD_FORM,
} from 'constants/auth';
/** Styles */
import styles from './SignInForm.module.scss';

const SignInForm = () => {
  const [formData, setFormData] = useState(SIGNIN_INITIAL_DATA);
  const [formPasswordData, setFormPasswordData] = useState(
    RESET_PASSWORD_INITIAL_DATA
  );
  const [resetPassword, setResetPassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const { login, loading, recoverPassword } = useAuth();

  const changeHandler = ({ currentTarget: { name, value } }) => {
    if (resetPassword) {
      setFormData(SIGNIN_INITIAL_DATA);
    }

    if (name === 'email') {
      setIsValidEmail(validateEmail(value));
    }

    if (!resetPassword) {
      setFormData({
        ...formData,
        [name]: value.trim(),
      });
      return;
    }

    setFormPasswordData({
      ...formPasswordData,
      [name]: value.trim(),
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    login(formData.email, formData.password);
  };

  const submitResetHandler = (e) => {
    e.preventDefault();
    recoverPassword(formPasswordData.email)
      .then((status) => {
        if (status === 200) {
          setResetPassword(false);
          setFormPasswordData(RESET_PASSWORD_INITIAL_DATA);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.LoginFormWrapper}>
          <img
            className={styles.LoginLogo}
            src={`${process.env.PUBLIC_URL}/img/logos/delicoffee-logo-simple.png`}
            alt="logo-simple"
          />
          {resetPassword ? (
            <form
              id="reset-password-form"
              className={styles.FormWrapper}
              onSubmit={submitResetHandler}
            >
              <h3 className={styles.ResetPasswordTitle}>
                Recuperar contraseña
              </h3>
              {RESET_PASSWORD_FORM.map(({ id, label, type }) => (
                <div className={styles.FormField} key={id}>
                  <Input
                    id={id}
                    label={label}
                    name={id}
                    placeholder={label}
                    type={type}
                    onChange={changeHandler}
                  />
                </div>
              ))}
              <Button text="Recuperar" disabled={!isValidEmail} />
              <div className={styles.FormActionWrapper}>
                <a
                  className={styles.FormForgotPassword}
                  onClick={() => setResetPassword(false)}
                >
                  Volver
                </a>
              </div>
            </form>
          ) : (
            <form
              id="login-form"
              className={styles.FormWrapper}
              onSubmit={submitHandler}
            >
              {SIGN_IN_FORM.map(({ id, label, type }) => (
                <div className={styles.FormField} key={id}>
                  <Input
                    id={id}
                    label={label}
                    name={id}
                    placeholder={label}
                    type={type}
                    onChange={changeHandler}
                  />
                </div>
              ))}
              <div className={styles.FormButtonWrapper}>
                <Button text="Iniciar sesión" disabled={!isValidEmail} />
              </div>
              <div className={styles.FormActionWrapper}>
                <a
                  className={styles.FormForgotPassword}
                  onClick={() => setResetPassword(true)}
                >
                  ¿Olvidaste la contraseña?
                </a>
              </div>
            </form>
          )}
        </div>
      )}
    </>
  );
};

export default SignInForm;
