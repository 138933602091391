/** Components */
import XLSXLink from 'components/XLSXLink';
import InvoiceBatches from 'pages/Orders/RegisterBatches/InvoiceBatches';
/** Constants */
import { DOWNLOAD_METADATA } from 'constants/download';

export const NEW_PROVIDER_DATA = {
  name: '',
  NIF: '',
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
  email: '',
};

export const CREATE_PROVIDER_FORM = [
  {
    id: 'name',
    label: 'Nombre',
    type: 'text',
    placeholder: 'Nombre',
  },
  {
    id: 'managementId',
    label: 'Código de gestión',
    type: 'text',
    placeholder: 'Código de gestión',
  },
  {
    id: 'NIF',
    label: 'NIF',
    type: 'text',
    placeholder: 'NIF',
  },
  {
    id: 'country',
    label: 'Pais',
    type: 'text',
    placeholder: 'Pais',
  },
  {
    id: 'city',
    label: 'Ciudad',
    type: 'text',
    placeholder: 'Ciudad',
  },
  {
    id: 'zipCode',
    label: 'Código postal',
    type: 'text',
    placeholder: 'Código portal',
  },
  {
    id: 'addressFirstLine',
    label: 'Primera línea dirección',
    type: 'text',
    placeholder: 'Primera línea dirección',
  },
  {
    id: 'addressSecondLine',
    label: 'Segunda línea dirección',
    type: 'text',
    placeholder: 'Segunda línea dirección',
  },
];

export const PROVIDERS_HEAD_CELLS = [
  {
    id: 'providerId',
    numeric: false,
    disablePadding: false,
    label: 'ID.',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nombre',
  },
  {
    id: 'NIF',
    numeric: false,
    disablePadding: false,
    label: 'NIF',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Pais',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'Ciudad',
  },
  {
    id: 'mainContactName',
    numeric: false,
    disablePadding: false,
    label: 'Contacto principal',
  },
  {
    id: 'mainContactPhone',
    numeric: false,
    disablePadding: false,
    label: 'Teléfono contacto principal',
  },
];

export const MODIFIY_PROVIDER_FORM_DATA = {
  id: '',
  name: '',
  NIF: '',
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
};

export const PROVIDER_CONTACT_FORM_DATA = {
  identifyingName: '',
  mainContactName: '',
  secondaryContactName: '',
  mainContactPhone: '',
  secondaryContactPhone: '',
  mainContactEmail: '',
  secondaryContactEmail: '',
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
};

export const CREATE_CONTACT_PROVIDER_FORM = [
  {
    id: 'identifyingName',
    label: 'Nombre ficha de contacto',
    type: 'text',
    placeholder: 'Contacto del almacén',
  },
  {
    id: 'mainContactName',
    label: 'Contacto principal',
    type: 'text',
    placeholder: 'Eduardo',
  },
  {
    id: 'mainContactPhone',
    label: 'Teléfono principal',
    type: 'text',
    placeholder: '666123123',
  },
  {
    id: 'mainContactEmail',
    label: 'Correo electrónico principal',
    type: 'text',
    placeholder: 'principal@gmail.com',
  },
  {
    id: 'secondaryContactName',
    label: 'Contacto secundario',
    type: 'text',
    placeholder: 'Rafael',
  },
  {
    id: 'secondaryContactPhone',
    label: 'Teléfono secundario',
    type: 'text',
    placeholder: '666123123',
  },
  {
    id: 'secondaryContactEmail',
    label: 'Correo electrónico secundario',
    type: 'text',
    placeholder: 'secundario@gmail.com',
  },
  {
    id: 'country',
    label: 'País',
    type: 'text',
    placeholder: 'Francia',
  },
  {
    id: 'city',
    label: 'Ciudad',
    type: 'text',
    placeholder: 'Paris',
  },
  {
    id: 'zipCode',
    label: 'Código postal',
    type: 'text',
    placeholder: '75000',
  },
  {
    id: 'addressFirstLine',
    label: 'Primera línea de dirección',
    type: 'text',
    placeholder: 'Paris 10e Arrondissement',
  },
  {
    id: 'addressSecondLine',
    label: 'Segunda línea de dirección',
    type: 'text',
    placeholder: 'Bajo',
  },
];

export const CLIENT_SEND_FORM_DATA = {
  id: '',
  name: '',
  country: '',
  city: '',
  zipCode: '',
  addressFirstLine: '',
  addressSecondLine: '',
};

export const CREATE_SEND_CLIENT_FORM = [
  {
    id: 'name',
    label: 'Nombre del almacen',
    type: 'text',
    placeholder: 'Almacén de valencia',
  },
  {
    id: 'country',
    label: 'País',
    type: 'text',
    placeholder: 'Francia',
  },
  {
    id: 'city',
    label: 'Ciudad',
    type: 'text',
    placeholder: 'Paris',
  },
  {
    id: 'zipCode',
    label: 'Código postal',
    type: 'text',
    placeholder: '75000',
  },
  {
    id: 'addressFirstLine',
    label: 'Primera línea de dirección',
    type: 'text',
    placeholder: 'Paris 10e Arrondissement',
  },
  {
    id: 'addressSecondLine',
    label: 'Segunda línea de dirección',
    type: 'text',
    placeholder: 'Bajo',
  },
];

export const NEW_CONTRACT_DATA = {
  contractNumber: '',
  contractDate: new Date(),
  contractFooter: '',
  contractDetails: [],
  palletized: false,
  supplierId: '',
  clients: [],
};

export const CONTRACT_HEAD_CELLS = [
  {
    id: 'contractNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº de contrato',
  },
  {
    id: 'SupplierId',
    numeric: false,
    disablePadding: false,
    label: 'Proveedor',
  },
  {
    id: 'contractDate',
    numeric: false,
    disablePadding: false,
    label: 'Fecha de contrato',
    isDate: true,
  },
  // {
  //   id: 'metadata',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Descargar',
  //   render: ({ metadata: { fileId, key } }) => {
  //     const { url, filename } = DOWNLOAD_METADATA(fileId)[key];
  //     return {
  //       component: XLSXLink,
  //       props: {
  //         url,
  //         filename,
  //       },
  //     };
  //   },
  // },
];

export const MODIFIY_CONTRACT_FORM_DATA = {
  id: '',
  ...NEW_CONTRACT_DATA,
};

export const LINE_ITEM_DATA = {
  boughtUnits: 0,
  buyPrice: 0,
  supplierTerm: null,
  clients: [],
};

export const LINE_ITEM_DATA_FORM = [
  {
    id: 'boughtUnits',
    label: 'Unidades',
    type: 'number',
    placeholder: 'Escríbe las unidades',
    errorMessage: 'Debe ser mayor que cero',
  },
  {
    id: 'buyPrice',
    label: 'Precio de compra(€)',
    type: 'number',
    placeholder: 'Escríbe el precio',
    errorMessage: 'El campo es obligatorio',
  },
  {
    id: 'supplierTerm',
    label: 'Mes de retiro del proveedor',
    type: 'date',
    placeholder: 'Plazo/Mes de retiro',
  },
];

export const LINE_ITEM_CLIENT_DATA = {
  clientId: '',
  soldUnits: 0,
  sellPrice: 0,
  clientTerm: '',
};

export const LINE_ITEM_CLIENT_DATA_FORM = [
  {
    id: 'soldUnits',
    label: 'Unidades',
    type: 'number',
    placeholder: 'Escríbe las unidades',
    errorMessage: 'Debe ser mayor que cero',
  },
  {
    id: 'sellPrice',
    label: 'Precio de venta(€)',
    type: 'number',
    placeholder: 'Escríbe el precio',
    errorMessage: 'El campo es obligatorio',
  },
  {
    id: 'clientTerm',
    label: 'Mes de retiro del cliente',
    type: 'date',
    placeholder: 'Plazo/Mes de retiro',
  },
];

export const LINE_CLIENT_DATA = {
  clientId: '',
  palletized: false,
  transportIsIncluded: false,
  paymentTypeId: '',
};

export const IVA_OPTIONS = [
  {
    id: 10,
    name: '10 %',
  },
  {
    id: 21,
    name: '21 %',
  },
];

export const INVOICES_HEAD_CELLS = [
  {
    id: 'invoiceNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº de factura',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
    isDate: true,
  },
  {
    id: 'supplierId',
    numeric: false,
    disablePadding: false,
    label: 'Proveedor',
  },
  {
    id: 'basePrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio base',
    suffix: '€',
  },
  {
    id: 'totalPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio total',
    suffix: '€',
  },
  {
    id: 'vat',
    numeric: false,
    disablePadding: false,
    label: 'IVA',
    suffix: '%',
  },
  // {
  //   id: 'metadata',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Descargar',
  //   render: ({ metadata: { fileId, key } }) => {
  //     const { url, filename } = DOWNLOAD_METADATA(fileId)[key];
  //     return {
  //       component: XLSXLink,
  //       props: {
  //         url,
  //         filename,
  //       },
  //     };
  //   },
  // },
];

export const BILL_INVOICES_HEAD_CELLS = [
  {
    id: 'invoiceNumber',
    numeric: false,
    disablePadding: false,
    label: 'Nº de factura',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Fecha',
    isDate: true,
  },
  {
    id: 'supplierId',
    numeric: false,
    disablePadding: false,
    label: 'Proveedor',
  },
  {
    id: 'basePrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio base',
    suffix: '€',
  },
  {
    id: 'totalPrice',
    numeric: false,
    disablePadding: false,
    label: 'Precio total',
    suffix: '€',
  },
];

export const INVOICES_LINE_ITEMS_HEAD_CELLS = [
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'units',
    numeric: false,
    type: 'text',
    disablePadding: false,
    label: 'Unidades',
    isEditable: true,
  },
  {
    id: 'packCapacity',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Capacidad del envase',
  },
  {
    id: 'price',
    numeric: false,
    type: 'text',
    disablePadding: false,
    label: 'Precio por kilo',
    isEditable: true,
  },
  {
    id: 'batch',
    numeric: false,
    disablePadding: false,
    label: 'Lotes',
    render: ({ id, batch }) => {
      return {
        component: InvoiceBatches,
        props: {
          id,
          batches: batch,
        },
      };
    },
  },
  {
    id: 'qualityId',
    numeric: false,
    type: 'hidden',
    step: 'any',
    disablePadding: false,
    label: '',
    isEditable: true,
  },
];

export const BILL_INVOICES_LINE_ITEMS_HEAD_CELLS = [
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'units',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Unidades',
    isEditable: true,
  },
  {
    id: 'packCapacity',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Capacidad del envase',
  },
  {
    id: 'price',
    numeric: false,
    type: 'number',
    step: 'any',
    disablePadding: false,
    label: 'Precio por kilo',
    isEditable: true,
  },
  {
    id: 'realWeight',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Peso real',
    isEditable: true,
  },
  {
    id: 'batch',
    numeric: false,
    disablePadding: false,
    label: 'Lotes',
    render: ({ id, batch }) => {
      return {
        component: InvoiceBatches,
        props: {
          id,
          batches: batch,
        },
      };
    },
  },
  {
    id: 'orderDetailId',
    numeric: false,
    type: 'hidden',
  },
];

export const BILL_INVOICES_EDIT_LINE_ITEMS_HEAD_CELLS = [
  {
    id: 'qualityName',
    numeric: false,
    disablePadding: false,
    label: 'Calidad',
  },
  {
    id: 'units',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Unidades',
    isEditable: true,
  },
  {
    id: 'packCapacity',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Capacidad del envase',
  },
  {
    id: 'pricePerKg',
    numeric: false,
    type: 'number',
    step: 'any',
    disablePadding: false,
    label: 'Precio por kilo',
    isEditable: true,
  },
  {
    id: 'expectedWeight',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Peso esperado',
    isEditable: true,
  },
  {
    id: 'realWeight',
    numeric: false,
    type: 'number',
    disablePadding: false,
    label: 'Peso real',
    isEditable: true,
  },
  {
    id: 'batch',
    numeric: false,
    disablePadding: false,
    label: 'Lotes',
    render: ({ id, batch }) => {
      return {
        component: InvoiceBatches,
        props: {
          id,
          batches: batch,
        },
      };
    },
  },
  {
    id: 'orderDetailId',
    numeric: false,
    type: 'hidden',
  },
];
