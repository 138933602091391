import { useState, useEffect } from 'react';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Constants */
import {
  CREATE_CONTACT_CLIENT_FORM,
  CLIENT_CONTACT_FORM_DATA,
} from 'constants/clients.js';
/** Components */
import Button from 'components/Button';
import Input from 'components/Input';
import ContactCard from './ContactCard';
/** Services */
import { createContactData, updateContactData } from 'services/clients.js';
/** Styles */
import styles from './ContactData.module.scss';

const ContactData = ({ clientId, contacts = [], onSubmit }) => {
  const [contactData, setContactData] = useState(CLIENT_CONTACT_FORM_DATA);
  const [contactAddresses, setContactAddresses] = useState([]);
  const [hasContactAddresses, setHasContactAddresses] = useState(false);
  const [contactId, setContactId] = useState(0);
  const [isUpdatingContact, setIsUpdatingContact] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const asyncFunction = async () => {
      const target = contacts.find(({ id }) => id === contactId);
      if (target === undefined) return;
      await setHasContactAddresses(false);
      populateContactForm(target);
    };
    asyncFunction();
  }, [contactId]);

  useEffect(() => {
    setContactAddresses(contacts.length ? contacts : []);
    setHasContactAddresses(!!contacts.length);
  }, [contacts]);

  const populateContactForm = (contact) => {
    setIsUpdatingContact(true);
    CREATE_CONTACT_CLIENT_FORM.forEach(({ id }) => {
      const input = document.querySelector(`#client-contact-form #${id}`);
      input.value = contact[id];
    });

    setContactData({
      ...contactData,
      clientId: contact.ClientId,
      id: contact.id,
      addressFirstLine: contact.addressFirstLine,
      addressSecondLine: contact.addressSecondLine,
      city: contact.city,
      country: contact.country,
      identifyingName: contact.identifyingName,
      mainContactEmail: contact.mainContactEmail,
      mainContactName: contact.mainContactName,
      mainContactPhone: contact.mainContactPhone,
      secondaryContactEmail: contact.secondaryContactEmail,
      secondaryContactName: contact.secondaryContactName,
      secondaryContactPhone: contact.secondaryContactPhone,
      zipCode: contact.zipCode,
    });
  };

  const resetFormHandler = () => {
    setContactData();
    setContactId();
    setHasContactAddresses(true);
    setIsUpdatingContact(false);
  };

  const addNewContact = () => {
    setHasContactAddresses(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isUpdatingContact) {
      createContactDataHandler();
      return;
    }

    updateContactDataHandler();
  };

  const createContactDataHandler = () => {
    setSubmitting(true);

    createContactData({ ...contactData, ClientId: clientId })
      .then(() => {
        createToast({
          text: 'Datos de contacto añadidos con éxito',
          type: 'success',
          duration: 2500,
        });
        resetFormHandler();
        onSubmit();
        setSubmitting(false);
      })
      .catch(({ status }) => {
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
        }

        if (status === 500) {
          createToast({ text: 'Algo ha salido mal', type: 'error' });
        }

        setSubmitting(false);
      });
  };

  const changeHandler = ({ target: { name, value } }) => {
    setContactData({
      ...contactData,
      [name]: value.trim(),
    });
  };

  const updateContactDataHandler = () => {
    setSubmitting(true);

    updateContactData({ ...contactData, id: contactData.id })
      .then(() => {
        createToast({
          text: 'Datos actualizados con éxito',
          type: 'success',
          duration: 2500,
        });
        resetFormHandler();
        onSubmit();
        setSubmitting(false);
      })
      .catch(({ status }) => {
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
        }

        if (status === 500) {
          createToast({ text: 'Algo ha salido mal', type: 'error' });
        }

        setSubmitting(false);
      });
  };

  return (
    <div className={styles.ClientDataWrapper}>
      <div className={styles.ClientDataTitleWrapper}>
        <h2 className={styles.ClientDataTitle}>Datos de contacto</h2>
        {hasContactAddresses && <p onClick={addNewContact}>Añadir nueva</p>}
      </div>
      {hasContactAddresses ? (
        <>
          <div className={styles.ContactWrapper}>
            {contactAddresses.map((values) => (
              <ContactCard
                {...values}
                setContact={(value) => setContactId(value)}
                key={values.id}
                onSubmit={onSubmit}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles.ContactData}>
          <form
            id="client-contact-form"
            onSubmit={submitHandler}
            className={styles.FormWrapper}
          >
            {CREATE_CONTACT_CLIENT_FORM.map(
              ({ id, label, type, placeholder }) => (
                <Input
                  key={`contact-${id}`}
                  name={id}
                  className={styles.InputContact}
                  placeholder={placeholder}
                  type={type}
                  label={label}
                  onChange={changeHandler}
                />
              )
            )}
            <div className={styles.ContactFormButtons}>
              {!!contacts.length && (
                <Button
                  cancel
                  text="Cancelar"
                  type="button"
                  click={resetFormHandler}
                />
              )}
              <Button
                update
                loading={submitting}
                text={isUpdatingContact ? 'Actualizar' : 'Añadir'}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ContactData;
