import { useNavigate } from 'react-router-dom';
/** Components */
import Table from 'components/Table';
/** Services */
import { removeSupplierInvoices } from 'services/invoices';
/** Utils */
import { createToast } from 'utils/toast';
/** Constants */
import { INVOICES_HEAD_CELLS } from 'constants/providers';
/** Styles */
import styles from './InvoiceList.module.scss';

const InvoiceList = ({ invoices = [], providers = [], onDeleteInvoices }) => {
  const navigate = useNavigate();

  const mappedInvoices = invoices.map(
    ({
      invoiceNumber = '',
      date,
      supplierId,
      basePrice,
      totalPrice,
      vat,
      id,
    }) => {
      return {
        ...(invoiceNumber
          ? { invoiceNumber }
          : { invoiceNumber: 'Sin nº de factura' }),
        ...(date ? { date } : { date: 'Sin fecha' }),
        ...(supplierId
          ? { supplierId: providers.find(({ id }) => id === supplierId).name }
          : { supplierId: 'Sin proveedor' }),
        ...(basePrice ? { basePrice } : { basePrice: '-' }),
        ...(totalPrice ? { totalPrice } : { totalPrice: '-' }),
        ...(vat ? { vat } : { vat: '-' }),
        // metadata: { fileId: id, key: 'supplierInvoice' },
        ...(id && { id }),
      };
    }
  );

  const handleSelectInvoice = (invoiceId) => {
    navigate(`/proveedores/facturas/${invoiceId}`);
  };

  const deleteHandler = (invoicesIds) => {
    removeSupplierInvoices(invoicesIds)
      .then(({ status }) => {
        if (status === 204) {
          createToast({
            text: 'Factura del proveedor eliminada con éxito',
            type: 'success',
            duration: 2500,
          });
          onDeleteInvoices(invoicesIds);
        }
      })
      .catch(() => {
        createToast({
          text: 'No se ha podido eliminar la factura del proveedor, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  return (
    <>
      {invoices.length !== 0 ? (
        <Table
          rowsData={mappedInvoices}
          headCells={INVOICES_HEAD_CELLS}
          ignoreColumn="id"
          targetItem="id"
          orderBy="id"
          setItem={handleSelectInvoice}
          deleteHandler={(value) => deleteHandler(value)}
        />
      ) : (
        <div className={styles.EmptyState}>No hay datos.</div>
      )}
    </>
  );
};

export default InvoiceList;
