import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
/** Components */
import Loader from 'components/Loader';
import Tabs from 'components/Tabs';
import Back from 'components/Back';
import UserForm from './UserForm';
import EditRoles from './EditRoles';
/** Services */
import { getUser } from 'services/users';
/** Context */
import useAuth from 'context/useAuth';
/** Styles */
import styles from './User.module.scss';

const getUserTabs = (user, currentUser) => {
  if (!currentUser || currentUser?.role !== 'admin') {
    return [
      {
        id: 'data',
        name: 'Datos',
        component: <UserForm user={user} />,
      },
    ];
  }

  return [
    {
      id: 'data',
      name: 'Datos',
      component: <UserForm user={user} />,
    },
    {
      id: 'permissions',
      name: 'Permisos',
      component: <EditRoles userId={user.id} permissions={user.permissions} />,
    },
  ];
};

const User = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { user: currentUser } = useAuth();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = (await getUser(id)) ?? {};
        const { fetchedUser } = data;
        fetchedUser && setUser(fetchedUser);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchUser();
  }, [id]);

  if (loading) return <Loader />;

  return (
    <>
      <Back text="Volver a Usuarios" path="/usuarios" />
      <div className={styles.UserContainer}>
        {user ? (
          <Tabs tabs={getUserTabs(user, currentUser)} />
        ) : (
          <div className={styles.UserEmpty}>
            No se ha encontrado el usuario.
          </div>
        )}
      </div>
    </>
  );
};

export default User;
