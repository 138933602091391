import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/** Styles */
import './XLSXLink.scss';

const XLSXLink = ({ url, filename = 'descargar', pdf = true }) => (
  <>
    <a href={`${url}`} download={filename} className="xlsx">
      <FontAwesomeIcon icon={['far', 'file-excel']} className="xlsx__icon" />
    </a>
    {pdf && (
      <a href={`${url}?pdf=true`} download={filename} className="xlsx">
        <FontAwesomeIcon
          icon={['far', 'file-pdf']}
          className="xlsx__icon pdf"
        />
      </a>
    )}
  </>
);

export default XLSXLink;
