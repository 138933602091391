import cx from 'classnames';
/** Components */
import Loader from 'components/Loader';
/** Styles */
import styles from './Button.module.scss';

const Button = ({
  text,
  type = 'submit',
  className,
  error,
  click,
  disabled = false,
  secundary = false,
  submit = false,
  cancel = false,
  update = false,
  remove = false,
  loading = false,
  children,
  ...props
}) => {
  const buttonElementClassnames = cx(styles.ButtonElement, {
    [styles.ButtonSecundary]: !!secundary,
    [styles.ButtonElementError]: !!error,
    [styles.Buttondisabled]: !!disabled,
    [styles.ButtonSubmit]: !!submit,
    [styles.ButtonCancel]: !!cancel,
    [styles.ButtonUpdate]: !!update,
    [styles.ButtonDelete]: !!remove,
  });

  return (
    <button
      {...props}
      type={type}
      className={buttonElementClassnames}
      disabled={disabled || loading}
      onClick={click}
    >
      {loading ? (
        <Loader isInverse sizingClass="micro" />
      ) : (
        <>
          {children}
          {text}
        </>
      )}
    </button>
  );
};

export default Button;
