import { useState, useEffect } from 'react';
/** Components */
import Button from 'components/Button';
import Loader from 'components/Loader';
import ContractList from './ContractList';
/** Services */
import { getSuppliersContracts } from 'services/contracts';
/** Utils */
import { createToast } from 'utils/toast';
/** Styles */
import styles from './Contracts.module.scss';
import { NavLink } from 'react-router-dom';

const Contracts = () => {
  const [contractList, setContractList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSuppliersContracts()
      .then(({ status, data: { suppliersContracts } }) => {
        if (status === 200) {
          setContractList(suppliersContracts);
        }
        setLoading(false);
      })
      .catch(({ status }) => {
        setLoading(false);
        if (status === 401) {
          createToast({
            text: 'No tienes suficientes permisos',
            type: 'error',
          });
          return;
        }
        if (status === 404) {
          createToast({
            text: 'No existen contratos de proveedores',
            type: 'error',
          });
          return;
        }

        createToast({ text: 'Algo ha salido mal', type: 'error' });
      });
  }, []);

  const handleDeleteContract = (ids) => {
    setContractList(
      contractList.filter((contract) => !ids.includes(contract.id))
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <div className={styles.Contracts}>
      <div className={styles.ContractsHeader}>
        <h1 className="title">Contratos de proveedores</h1>
        <NavLink to="/proveedores/contratos/nuevo">
          <Button update text="Crear nuevo" />
        </NavLink>
      </div>
      <div className={styles.ContractsList}>
        <ContractList
          contracts={contractList}
          reloadContracts={handleDeleteContract}
        />
      </div>
    </div>
  );
};

export default Contracts;
