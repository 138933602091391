import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
/** Components */
import Loader from 'components/Loader';
import Back from 'components/Back';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import Checkbox from 'components/Checkbox';
/** Services */
import {
  getAllOrigins,
  getAllVarieties,
  getQuality,
  updateQuality,
  deleteQuality,
} from 'services/qualities';
import { getAllProviders } from 'services/providers';
/** Constants */
import { QUALITY_TYPES, QUALITY_PACKS } from 'constants/qualities.js';
/** Utils */
import { createToast } from 'utils/toast.js';
/** Styles */
import styles from './Quality.module.scss';

const Quality = () => {
  const [quality, setQuality] = useState(null);
  const [varieties, setVarieties] = useState([]);
  const [origins, setOrigins] = useState([]);
  const [providers, setProviders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [safeDelete, setSafeDelete] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchQuality = async () => {
      try {
        const { data } = (await getQuality(id)) ?? {};
        const { parsedQuality } = data;
        parsedQuality && setQuality(parsedQuality);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    const fetchVarieties = async () => {
      try {
        const { data } = (await getAllVarieties(id)) ?? {};
        const { fetchedQualityVarieties } = data;
        fetchedQualityVarieties && setVarieties(fetchedQualityVarieties);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchOrigins = async () => {
      try {
        const { data } = (await getAllOrigins()) ?? {};
        const { fetchedOrigins } = data;
        fetchedOrigins && setOrigins(fetchedOrigins);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchSuppliers = async () => {
      try {
        const { data } = (await getAllProviders()) ?? {};
        const { fetchedSuppliers } = data;
        fetchedSuppliers && setProviders(fetchedSuppliers);
      } catch (err) {
        console.error(err);
      }
    };
    fetchOrigins();
    fetchSuppliers();
    fetchVarieties();
    fetchQuality();
  }, [id]);

  const changeHandler = ({ target: { name, value, type } }) => {
    // const normalizedValue = type === 'number' ? Math.floor(value) : value;
    setQuality({
      ...quality,
      [name]: value,
    });
  };

  const handleChangeVariety = (value) => {
    setQuality({
      ...quality,
      QualityVarietyId: value,
    });
  };

  const handleChangeOrigin = (value) => {
    setQuality({
      ...quality,
      OriginId: value,
    });
  };

  const handleChangeSupplier = (value) => {
    setQuality({
      ...quality,
      SupplierId: value,
    });
  };

  const changeTypeHandler = (id, value) => {
    const type = { ...quality.type };
    type[id] = value;
    setQuality((prev) => {
      return { ...prev, type };
    });
  };

  const handleChangePack = (value) => {
    setQuality({
      ...quality,
      packType: value,
    });
  };

  const removeHandler = () => {
    if (safeDelete) {
      deleteQuality(quality.id)
        .then(({ status }) => {
          if (status === 204) {
            createToast({
              text: 'Calidad eliminada con éxito',
              type: 'success',
              duration: 2500,
            });
          }
          setTimeout(() => {
            navigate('/calidades');
          }, 500);
        })
        .catch(() => {
          createToast({
            text: 'No se ha podido eliminar la calidad, inténtalo de nuevo más tarde',
            type: 'error',
          });
        });
      return;
    }

    setSafeDelete(true);
    setTimeout(() => {
      setSafeDelete(false);
    }, 3000);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    updateQuality(quality)
      .then(({ status }) => {
        if (status === 200) {
          createToast({
            text: 'Calidad modificada con éxito',
            type: 'success',
            duration: 2500,
          });
          setTimeout(() => {
            navigate('/calidades');
          }, 500);
        }
      })
      .catch(() => {
        createToast({
          text: 'Algo ha ido mal, inténtalo de nuevo más tarde',
          type: 'error',
        });
      });
  };

  if (loading) return <Loader />;

  return (
    <>
      <Back text="Volver a Calidades" path="/calidades" />
      <div className={styles.QualityContainer}>
        {quality ? (
          <form onSubmit={submitHandler} className={styles.FormWrapper}>
            <div className={styles.FormGroup}>
              <Input
                id="name"
                label="Nombre café"
                name="name"
                placeholder="Sin nombre"
                type="text"
                autoComplete="off"
                value={quality.name}
                className={styles.InputName}
                onChange={changeHandler}
              />
              <Input
                id="quantity"
                label="Envase (kg)"
                name="quantity"
                placeholder="0"
                type="number"
                className={styles.InputQuantity}
                value={quality.quantity}
                onChange={changeHandler}
              />
            </div>
            <div className={styles.FormGroup}>
              <div className={styles.FormField}>
                <label>Variedad</label>
                <Select
                  options={varieties}
                  placeholder="Sin variedad"
                  value={quality.QualityVarietyId}
                  onChange={handleChangeVariety}
                />
              </div>
              <div className={styles.FormField}>
                <label>Origen</label>
                <Select
                  options={origins}
                  placeholder="Sin origen"
                  value={quality.OriginId}
                  onChange={handleChangeOrigin}
                />
              </div>
              <Input
                id="tareWeight"
                label="Tara (kg)"
                name="tareWeight"
                placeholder="0"
                type="number"
                min="0"
                step="0.01"
                className={styles.InputTara}
                value={quality.tareWeight}
                onChange={changeHandler}
              />
              <div className={styles.FormField}>
                <label>Tipo de envase</label>
                <Select
                  options={QUALITY_PACKS}
                  placeholder="Sin envase seleccionado"
                  value={quality.packType}
                  onChange={handleChangePack}
                />
              </div>
            </div>
            {quality.type && (
              <div className={styles.FormGroupCheckboxes}>
                {QUALITY_TYPES.map(({ id, label }) => (
                  <Checkbox
                    key={`edit_quality_${id}`}
                    id={id}
                    value={quality.type[id]}
                    onChange={(value) => changeTypeHandler(id, value)}
                  >
                    {label}
                  </Checkbox>
                ))}
              </div>
            )}
            <div className={styles.FormGroup}>
              <div className={styles.FormField}>
                <label>Proveedor</label>
                <Select
                  options={providers}
                  placeholder="Sin proveedor"
                  value={quality.SupplierId}
                  onChange={handleChangeSupplier}
                />
              </div>
            </div>

            <div className={styles.FormButtonWrapper}>
              <Button
                update
                text="Actualizar"
                disabled={!quality.QualityVarietyId}
              />
              <Button
                remove
                text={safeDelete ? 'Confirmar' : 'Eliminar'}
                type="button"
                click={removeHandler}
              />
            </div>
          </form>
        ) : (
          <div className={styles.QualityEmpty}>
            No se ha encontrado la calidad.
          </div>
        )}
      </div>
    </>
  );
};

export default Quality;
