import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
/** Components */
import Button from 'components/Button';
import LineItem from '../LineItem';
import LineItemForm from '../LineItemForm';
/** Styles */
import './LineItems.scss';

const LineItems = ({ lineItems, setLineItems, error }) => {
  const [addingLineItems, setAddingLineItems] = useState(false);

  // Handler to save line item
  const saveLineItemHandler = (lineItem) => {
    setLineItems([...lineItems, lineItem]);
    setAddingLineItems();
  };

  // Handler to update line item
  const updateLineItemHandler = (lineItem) => {
    const index = lineItems.findIndex(({ id }) => id === lineItem.id);
    if (index !== -1) {
      lineItems[index] = { ...lineItem };
    }
    setLineItems([...lineItems]);
  };

  // Handler to remove line item
  const removeLineItemHandler = (index) => {
    setLineItems([...lineItems.slice(0, index), ...lineItems.slice(index + 1)]);
  };

  const lineItemsClassNames = cx('line-items__empty', {
    'line-items__empty--error': !!error,
  });

  return (
    <div className="line-items">
      {lineItems.length ? (
        <div className="line-items__items">
          {lineItems.map((lineItem, index) => (
            <LineItem
              key={`line-item-${index}`}
              addingLineItems={addingLineItems}
              onUpdate={updateLineItemHandler}
              onRemove={() => removeLineItemHandler(index)}
              index={index}
              {...lineItem}
            />
          ))}
        </div>
      ) : (
        !addingLineItems && (
          <>
            <div className={lineItemsClassNames}>
              <Button
                update
                text="Añadir linea de contrato"
                click={() => setAddingLineItems(true)}
              >
                <FontAwesomeIcon icon="plus" className="line-items__add-btn" />
              </Button>
            </div>
            {error && (
              <span className="line-items__error-message">
                Necesitas añadir al menos una línea de contrato
              </span>
            )}
          </>
        )
      )}
      {addingLineItems && (
        <LineItemForm
          onSubmit={saveLineItemHandler}
          cancelEdit={() => setAddingLineItems(false)}
        />
      )}
      {!!lineItems.length && !addingLineItems && (
        <div className="line-items__action">
          <Button
            update
            text="Añadir más lineas"
            click={() => setAddingLineItems(true)}
          >
            <FontAwesomeIcon icon="plus" className="line-items__add-btn" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default LineItems;
