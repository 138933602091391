import { useState, useEffect, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import cx from 'classnames';
/** Hooks */
import useDebounce from 'hooks/useDebounce';
/** Styles */
import styles from './SearchBar.module.scss';

const SearchBar = ({
  defaultValue = '',
  placeholder = 'Buscar',
  debounce = 500,
  autoComplete = 'off',
  className = '',
  onChange,
}) => {
  const [query, setQuery] = useState(defaultValue);
  const debouncedQuery = useDebounce(query, debounce);
  const mountedRef = useRef(false);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    mountedRef.current && onChange && onChange(debouncedQuery);
    if (!mountedRef.current) {
      mountedRef.current = true;
    }
  }, [debouncedQuery, onChange]);

  const handleChangeQuery = ({ target: { value = '' } }) => {
    setQuery(value.toLowerCase());
  };

  const searchbarClasses = cx(styles.searchbar, {
    [className]: !!className,
  });

  return (
    <div className={searchbarClasses}>
      <div className={styles.searchbarWrapper}>
        <TextField
          value={query}
          onInput={handleChangeQuery}
          placeholder={placeholder}
          autoComplete={autoComplete}
          size="small"
        />
        <SearchIcon
          className={styles.searchbarIcon}
          style={{
            position: 'absolute',
            left: '12px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#809097',
            fontSize: '20px',
          }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
